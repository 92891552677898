import cn from 'classnames';
import {
  Tab as RTab,
  TabList as RTabList,
  TabPanel as RTabPanel,
  Tabs as RTabs,
  TabsProps as RTabsProps,
} from 'react-tabs';

import styles from './style.module.scss';

export const Tab = ({
  children,
  className,
  ...props
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <RTab {...props} className={cn(styles.tab, className)}>
      {children}
    </RTab>
  );
};

Tab.tabsRole = 'Tab';

export const TabList = ({
  children,
  className,
  ...props
}: {
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
}) => {
  return (
    <RTabList {...props} className={cn(styles.tabList, className)}>
      {children}
    </RTabList>
  );
};

TabList.tabsRole = 'TabList';

export const TabPanel = ({
  children,
  ...props
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return <RTabPanel {...props}>{children}</RTabPanel>;
};

TabPanel.tabsRole = 'TabPanel';

interface TabsProps extends RTabsProps {
  children: React.ReactNode;
}

export const Tabs = ({ children, ...props }: TabsProps) => {
  return (
    <RTabs selectedTabClassName={styles.tab_selected} {...props}>
      {children}
    </RTabs>
  );
};

Tabs.tabsRole = 'Tabs';
