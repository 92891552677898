import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { ISponsor, ISponsorUser } from 'src/@types/leaderboard';
import TopLeaderboardSlider from 'src/components/leaderboard/TopLeaderboardSlider';
import TopUserCard from 'src/components/leaderboard/TopUserCard';
import publicConroller from 'src/services/leaderboard/publicController';
import publicController from 'src/services/leaderboard/publicController';
import Button from 'src/ui/Button';

import s from './style.module.scss';

interface IPedestalMobileProps {
  data: ISponsorUser[];
}

{
  /* <div className='Leaderboard-Pedestal-container Leaderboard-Pedestal-container-mobile'> */
}

const PedestalMobile = ({ data }: IPedestalMobileProps) => {
  return (
    <div className={s.pedestalMobile}>
      <TopLeaderboardSlider data={data} />

      <div className="Leaderboard-btn">
        <a href="/leaderboard">
          <Button customStyle={{ height: '45px', borderRadius: '6px' }}>
            <img
              width={25}
              src="https://btcs.gg/challenges-img/leaderboard-update-white.svg"
            />
            Full Leaderboard
          </Button>
        </a>
      </div>
    </div>
  );
};

const LeaderboardBlock = () => {
  const [currentSponsor, setCurrentSponsor] = useState<ISponsor | null>(null);
  const [leaderboardTopData, setLeaderboardTopData] = useState<ISponsorUser[]>(
    [],
  );

  const { data: sponsorsData = [] } = useQuery<ISponsor[]>({
    queryKey: ['sponsors'],
    queryFn: () => publicController.getSponsors(),
  });

  const { data: sponsorUsersData = [] } = useQuery<ISponsorUser[]>({
    queryKey: ['sponsors-users', currentSponsor?.id],
    queryFn: () =>
      publicConroller.getSponsorVipUsers({ id: currentSponsor?.id }),
    enabled: !!currentSponsor,
  });

  //TODO: In future, we need to resolve how to pick the sponsor to show in the leaderboard
  useEffect(() => {
    if (sponsorsData.length > 0) {
      const sponsor = sponsorsData.find(
        (sponsor) => sponsor.name === 'Shuffle',
      );

      setCurrentSponsor(sponsor ?? sponsorsData[0]);
    }
  }, [sponsorsData]);

  useEffect(() => {
    if (sponsorUsersData) {
      const top3 = sponsorUsersData.slice(0, 3);

      setLeaderboardTopData(top3);
    }
  }, [sponsorUsersData]);

  if (sponsorsData.length === 0) return null;

  return (
    <div className={s.page}>
      <div className="Landing-Coins">
        <div className="Gold">
          <img src="/coin/goldcoin.png" />
        </div>
        <div className="Silver">
          <img src="/coin/silvercoin2.png" />
        </div>
      </div>

      <div className={s.page__heading}>
        <h2 className={s.page__title}>Leaderboard</h2>
        <div className="Heading-divider" />
        <p className={s.page__subtitle}>
          Step into the spotlight with our leaderboard section! See who's
          crushing it and join the fun as you aim for the top spot among fellow
          contenders.
        </p>
      </div>

      <div className={s.page__desktop}>
        <div className={s.pedestal}>
          <div className={s.pedestal__content}>
            <div className={s.pedestal__rankings}>
              <TopUserCard type="second" user={leaderboardTopData[1]} />
              <TopUserCard type="first" user={leaderboardTopData[0]} />
              <TopUserCard type="third" user={leaderboardTopData[2]} />
            </div>

            <div className="Leaderboard-btn">
              <a href="/leaderboard">
                <Button customStyle={{ height: '45px', borderRadius: '6px' }}>
                  <img
                    width={25}
                    src="https://btcs.gg/challenges-img/leaderboard-update-white.svg"
                  />
                  Full Leaderboard
                </Button>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className={s.page__mobile}>
        <PedestalMobile data={leaderboardTopData} />
      </div>
    </div>
  );
};

export default LeaderboardBlock;
