import React, { useState } from 'react';
import { Link } from 'react-router';

import Button from 'src/ui/Button';
import Checkbox from 'src/ui/Checkbox';
import Divider from 'src/ui/Divider';

import { Login } from '../Header';

import s from './style.module.scss';

import { ReactComponent as TwichIcon } from 'src/assets/icons/twich.svg';
import LogoImage from 'src/assets/images/btcs-logo.png';

const LoginWarning = () => {
  const [confirm, setConfirm] = useState(false);
  const { url } = Login();

  return (
    <div className={s.block}>
      <div className={s.block__logo}>
        <img src={LogoImage} alt="logo" />
      </div>

      <div className={s.block__info}>
        <div>
          <h2 className={s.block__title}>Welcome!</h2>
          <p className={s.block__desc}>
            Make sure you have a Twitch account so you can login, join our
            community and discover all sorts of treats and perks!
          </p>
        </div>

        <div>
          <Divider />
        </div>

        <Checkbox
          name={'confirm'}
          label="I confirm I‘m over 18 years old and that I have read and agree to the Terms and Conditions."
          checked={confirm}
          onChange={() => setConfirm(!confirm)}
        />

        <div className={s.block__btnContainer}>
          <Link to={url}>
            <Button disabled={!confirm}>
              <div className={s.block__btnContent}>
                <TwichIcon /> Join with Twitch
              </div>
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LoginWarning;
