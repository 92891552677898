import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
// import {makeStyles} from "@material-ui/core/styles";
import { makeStyles } from '@mui/styles';

import { BonusHunt } from '../types';
import { numberWithCommas } from '../utils/utils';
import { formatDateYearNoTime } from './Crates';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      // margin: theme.spacing(1),
      width: '25ch',
      background: 'transparent !important',
      borderRadius: '10px',
    },
  },
  table: {
    border: 'none !important',
    borderRadius: '10px',
  },
  tableHeader: {
    background: '#121418 !important',
    // border: '1px solid #2a2e38 !important',
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      background: '#222635 !important',
    },
    '&:nth-of-type(even)': {
      background: '#222635 !important',
    },
    border: '1px solid #2a2e38 !important',
  },
  headerCell: {
    background: '#101115 !important',
    color: 'rgb(223, 218, 228) !important',
    borderBottom: '1px solid #2a2e38 !important',
    '&:first-child': {
      borderTopLeftRadius: '10px',
    },
  },
  tableCell: {
    color: 'rgb(223, 218, 228) !important',
    borderBottom: '1px solid #2a2e38 !important',
    cursor: 'pointer',
    maxWidth: '100px !important',
  },
}));

interface Column {
  id:
    | 'streamer'
    | 'creationDate'
    | 'start'
    | 'totalBonuses'
    | 'highestWin'
    | 'highestMultiplier'
    | 'profit';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: any) => string;
}

const columns: readonly Column[] = [
  {
    id: 'streamer',
    label: 'Streamer',
    minWidth: 170,
    format: (v: any) => v ?? 'N/A',
  },
  {
    id: 'creationDate',
    label: 'Date',
    minWidth: 170,
    align: 'right',
    format: (value: any) => (value ? formatDateYearNoTime(value) : 'N/A'),
  },
  {
    id: 'start',
    label: 'Start Cost',
    minWidth: 170,
    align: 'right',
    format: (value: number) => (value ? '$' + numberWithCommas(value) : 'N/A'),
  },
  {
    id: 'totalBonuses',
    label: 'Bonuses',
    minWidth: 170,
    align: 'right',
    format: (value: number) => (value ? value.toLocaleString('en-US') : 'N/A'),
  },
  {
    id: 'highestWin',
    label: 'Biggest Win',
    minWidth: 170,
    align: 'right',
    format: (value: number) => (value ? '$' + numberWithCommas(value) : 'N/A'),
  },
  {
    id: 'highestMultiplier',
    label: 'Highest x',
    minWidth: 170,
    align: 'right',
    format: (value: number) =>
      value ? 'x' + value.toLocaleString('en-US') : 'N/A',
  },
  {
    id: 'profit',
    label: 'Profit',
    minWidth: 170,
    align: 'right',
    format: (value: number) => (value ? '$' + numberWithCommas(value) : 'N/A'),
  },
];

export type BonusHuntTableProps = {
  rows: Array<BonusHunt>;
  setBonusHunt: (hunt: BonusHunt | undefined) => any;
};

export const BonusHuntTable: React.FC<BonusHuntTableProps> = ({
  rows,
  setBonusHunt,
}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const classes = useStyles();
  return (
    <Paper
      sx={{
        width: '100%',
        overflow: 'hidden',
        background: 'transparent',
        border: '3px solid #7a87b4 !important',
      }}
    >
      <TableContainer sx={{ maxHeight: 840 }}>
        <Table className={classes.table} stickyHeader aria-label="sticky table">
          <TableHead className={classes.tableHeader}>
            <TableRow className={classes.tableRow}>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  className={classes.headerCell}
                  style={{ minWidth: column.minWidth }}
                >
                  <span>{column.label}</span>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id}
                    onClick={() => setBonusHunt(row)}
                  >
                    {columns.map((column) => {
                      const value = row[column.id];

                      return (
                        <TableCell
                          className={classes.tableCell}
                          key={column.id}
                          align={column.align}
                        >
                          <span>
                            {
                              // @ts-ignore
                              column.format(value)
                            }
                          </span>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        className={'HuntsPagination'}
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};
