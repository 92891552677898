import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useSearchParams } from 'react-router';
import { useDebounce } from 'use-debounce';

import RowItem from 'src/components/Admin/leaderboard/table/RowItem';
import TableHeader from 'src/components/Admin/leaderboard/table/TableHeader';
import AddChallengeModal from 'src/components/Admin/modals/AddChallengeModal';
import ConcludeChallengeModal from 'src/components/Admin/modals/ConcludeChallengeModal';
import EditBlacklistModal from 'src/components/Admin/modals/EditBlacklistModal';
import { formatDateYearTime } from 'src/components/Crates';
import SpinnerLoading from 'src/components/loadings/SpinnerLoading';
import Tabs from 'src/components/Tabs/Tabs';
import moderateService from 'src/services/challenges/moderateService';
import { Challenge, Player, StaticList } from 'src/types';
import Button from 'src/ui/Button';
import Input from 'src/ui/Input';
import Pagination from 'src/ui/Pagination';
import { axiosGet, axiosPut } from 'src/utils/httpClient';

import 'src/assets/CSS/Admin.scss';

import { ReactComponent as EditIcon } from 'src/assets/icons/edit.svg';
import { ReactComponent as TrashIcon } from 'src/assets/icons/trash.svg';

const deleteChallenge = async (id: string): Promise<Array<Challenge>> =>
  axiosPut(`/admin/delete-challenge`, { uuid: id });
const getPlayers = async (): Promise<Array<Player>> =>
  axiosGet(`/admin/players`);
const getChallengerPrizes = async (): Promise<StaticList> =>
  axiosGet(`/admin/challenger-prizes`);

const HEADER_LIST = [
  {
    id: 0,
    title: 'Name',
    column: 'name',
    isSortable: true,
  },

  {
    id: 1,
    title: 'Hit',
    column: 'targetMultiplier',
    isSortable: true,
  },
  {
    id: 2,
    title: 'Bet',
    column: 'minimumWagerRequired',
    isSortable: true,
  },
  {
    id: 3,
    title: 'Reward',
    column: 'reward',
    isSortable: true,
  },
  {
    id: 4,
    title: 'Date',
    column: 'creationDate',
    isSortable: true,
  },
  {
    id: 5,
    title: 'Actions',
  },
];

const ChallengesAdmin = () => {
  // const [challenges, setChallenges] = useState<Array<Challenge>>([]);
  const [editing, setEditing] = useState<Challenge | undefined>(undefined);
  const [open, setOpen] = React.useState(false);
  const [openWinner, setOpenWinner] = React.useState(false);
  const [players, setPlayers] = useState<Array<Player>>([]);
  const [prizes, setPrizes] = useState<StaticList>({ strings: '' });
  // const [prizes, setPrizes] = useState<any>({ strings: [] });
  const [openPrizes, setOpenPrizes] = React.useState(false);

  const [search, setSearch] = useState('');
  const [searchUserName] = useDebounce(search, 1000);

  const [searchParams, setSearchParams] = useSearchParams();

  const page = Number(searchParams.get('page'));
  const sortColumn = searchParams.get('sortColumn');
  const sortOrder = searchParams.get('sortOrder') || 'asc';
  const itemsPerPage = 10;

  const {
    data: challenges = { data: [], total: 0 },
    isLoading: isLoadingChallenges,
    refetch: refetchChallenges,
  } = useQuery({
    queryKey: ['challenges', { page, sortColumn, sortOrder, searchUserName }],
    queryFn: () =>
      moderateService.getChallenges({
        name: searchUserName,
        page: page - 1,
        size: itemsPerPage,
        sortColumn,
        sortOrder,
      }),
    placeholderData: (previousData) => previousData,
  });

  useEffect(() => {
    getPlayers().then((response: Array<Player>) => {
      setPlayers(response);
    });
    getChallengerPrizes().then((response: StaticList) => {
      setPrizes(response);
    });
  }, []);

  const handleClose = () => {
    setOpen(false);
    setOpenWinner(false);
    setEditing(undefined);
  };

  useEffect(() => {
    if (searchUserName !== '') {
      setSearchParams((prev) => {
        prev.set('page', '1');
        return prev;
      });
    }

    if (searchUserName === '') {
      setSearchParams((prev) => {
        prev.set('page', '1');
        return prev;
      });
    }
  }, [searchUserName]);

  useEffect(() => {
    if (!searchParams.has('page')) {
      setSearchParams((prev) => {
        prev.set('page', '1');
        return prev;
      });
    }
  }, []);

  // const prizeNames = prizes.strings;
  const prizeNames = prizes.strings.split('\n');

  const handleChangePage = (value: number) => {
    setSearchParams((prev) => {
      prev.set('page', value.toString());
      return prev;
    });
  };

  return (
    <div>
      <div className="Section-heading">
        <h4>Challenges</h4>

        <Button
          onClick={() => setOpen(true)}
          customStyle={{
            padding: '5px',
            borderRadius: '6px',
            fontSize: '16px',
          }}
        >
          Add Challenge
        </Button>
      </div>

      {/* @ts-ignore */}
      <Tabs>
        {/* @ts-ignore */}
        <div label="Challenges">
          <div style={{ maxWidth: '350px' }}>
            <Input
              placeholder="Search"
              // className={s.raffle__search}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>

          {isLoadingChallenges ? (
            <SpinnerLoading />
          ) : (
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '40px' }}
            >
              <div>
                <TableHeader columns={HEADER_LIST} />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                  }}
                >
                  {challenges.data.map((challenge) => {
                    return (
                      <RowItem
                        item={challenge}
                        fields={[
                          {
                            key: 'name',
                            render: (value) => value,
                          },
                          {
                            key: 'targetMultiplier',
                            render: (value) => value,
                          },
                          {
                            key: 'minimumWagerRequired',
                            render: (value) => value,
                          },
                          {
                            key: 'reward',
                            render: (value) => value,
                          },
                          {
                            key: 'creationDate',
                            render: (value) => formatDateYearTime(value),
                          },
                        ]}
                        actions={[
                          {
                            label: 'Set Winner',
                            isIcon: true,
                            onClick: () => {
                              setEditing(challenge);
                              setOpenWinner(true);
                            },
                          },
                          {
                            label: <EditIcon />,
                            isIcon: true,
                            onClick: () => {
                              setEditing(challenge);
                              setOpen(true);
                            },
                          },
                          {
                            id: 'delete',
                            isIcon: true,
                            label: <TrashIcon />,
                            onClick: () =>
                              deleteChallenge(challenge.id).then((r) => {
                                refetchChallenges();
                              }),
                          },
                        ]}
                      />
                    );
                  })}
                </div>
              </div>

              <div>
                {challenges && challenges.total > itemsPerPage && (
                  <Pagination
                    count={Math.ceil(challenges.total / itemsPerPage)}
                    itemsPerPage={itemsPerPage}
                    page={page}
                    onChangePage={(e, value) => handleChangePage(value)}
                  />
                )}
              </div>
            </div>
          )}
        </div>
        {/* @ts-ignore */}
        <div label="Prizes">
          <div className="Section-heading">
            <button onClick={() => setOpenPrizes(true)}>Edit</button>
          </div>

          <div className="Blacklist-Challenge">
            <ul>
              {prizeNames.map((name) => (
                <li>{name}</li>
              ))}
            </ul>
          </div>
        </div>
      </Tabs>

      <EditBlacklistModal
        key={prizes.strings}
        open={openPrizes}
        onClose={handleClose}
        strings={prizes.strings}
        title={'Edit Challengers Prize List'}
        endpoint={`/admin/modify-challenger-prizes`}
      />

      <AddChallengeModal
        key={editing ? editing.id : 'no-id-challenge'}
        open={open}
        onClose={handleClose}
        editing={editing}
        onSuccess={refetchChallenges}
      />

      <ConcludeChallengeModal
        key={editing ? editing.id : 'no-id-challenge'}
        open={openWinner}
        onClose={handleClose}
        editing={editing}
        players={players}
      />
    </div>
  );
};

export default ChallengesAdmin;
