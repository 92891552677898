import { IChannel, IChannelCreate } from 'src/@types/channels';
import {
  axiosDelete,
  axiosGet,
  axiosPost,
  axiosPut,
} from 'src/utils/httpClient';

const channelsService = {
  async createChannel({ body }: { body: IChannelCreate }) {
    const response = await axiosPost('/moderate/youtube-channel', body);

    return response;
  },

  async editChannel({ body }: { body: IChannel }) {
    const response = await axiosPut(`/moderate/youtube-channel`, body);

    return response;
  },

  async getChannels(): Promise<IChannel[]> {
    const response = await axiosGet(`/moderate/youtube-channel`);

    return response;
  },

  async deleteChannel({ id }: { id: string }) {
    const response = await axiosDelete(`/moderate/youtube-channel/${id}`);

    return response;
  },
};

export default channelsService;
