import React from 'react';

import { ReactComponent as DiscordIcon } from 'src/assets/icons/discord.svg';
import { ReactComponent as KickIcon } from 'src/assets/icons/kick.svg';
import { ReactComponent as LinkIcon } from 'src/assets/icons/link.svg';
import { ReactComponent as TwichIcon } from 'src/assets/icons/twich.svg';
import { ReactComponent as XIcon } from 'src/assets/icons/x.svg';
import { ReactComponent as YoutubeIcon } from 'src/assets/icons/youtube.svg';

const PlatformIcon = ({ url }: { url: string }) => {
  const getPlatformIconByUrl = (url: string): JSX.Element => {
    if (url.includes('twitch.tv')) {
      return <TwichIcon />;
    } else if (url.includes('kick.com')) {
      return <KickIcon />;
    } else if (url.includes('discord.com')) {
      return <DiscordIcon />;
    } else if (url.includes('youtube.com') || url.includes('youtu.be')) {
      return <YoutubeIcon />;
    } else if (url.includes('x.com')) {
      return <XIcon />;
    }

    return <LinkIcon />;
  };

  return getPlatformIconByUrl(url);
};

export default PlatformIcon;
