import React from 'react';

import { IChannel } from 'src/@types/channels';
import Button from 'src/ui/Button';
import Divider from 'src/ui/Divider';

import s from './style.module.scss';

import LogoImage from 'src/assets/images/btcs-logo.png';

interface IDeleteChannelModalProps {
  item: IChannel | null;
  onDelete: (id: string) => void;
  onClose: () => void;
}

const DeleteChannelModal = ({
  item,
  onDelete,
  onClose,
}: IDeleteChannelModalProps) => {
  return (
    <div className={s.block}>
      <div className={s.block__logo}>
        <img src={LogoImage} alt="logo" />
      </div>

      <div className={s.block__info}>
        <div>
          <h2 className={s.block__title}>Confirm Channel Delete</h2>
          <p className={s.block__desc}>
            Are you sure you want to delete {item?.name} channel with ID{' '}
            {item?.channelId}?
          </p>
        </div>

        <div>
          <Divider />
        </div>

        <div className={s.block__btnContainer}>
          <Button onClick={onClose}>
            <div className={s.block__btnContent}>Cancel</div>
          </Button>
          <Button onClick={() => onDelete(item?.id!)} isOutlinedWhite>
            <div className={s.block__btnContent}>Delete</div>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DeleteChannelModal;
