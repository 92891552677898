import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router';

import PageLoading from 'src/components/loadings/PageLoading';
import { routes } from 'src/routes';
import publicConroller from 'src/services/leaderboard/publicController';

import 'src/assets/CSS/Admin.scss';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      color: '#acacac',
      background: '#121418',
    },
  },
};

export const dialogStyles = {
  backgroundColor: '#1a1d23',
  color: 'white',
  padding: '25px',
  boxShadow: 'none',
};

export interface ModalProps {
  open: boolean;
  onClose: () => void;
}

export default function LeaderboardLayout() {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const {
    data: sponsorsData = [],
    isLoading: isFetchingSponsors,
    isFetching,
  } = useQuery({
    queryKey: ['sponsors'],
    queryFn: () => publicConroller.getSponsors(),
  });

  //TODO: Need to show Shuffle sponsor first, in the future it should be via backend
  useEffect(() => {
    const isWithoutSponsor =
      location.pathname === routes.leaderboard && sponsorsData?.length > 0;
    const isWithoutLocationState =
      !location.state?.sponsor && sponsorsData?.length > 0;

    if (isWithoutSponsor) {
      redirectShuffleSponsor();
    } else if (!isWithoutSponsor && isWithoutLocationState) {
      redirectSponsorByName(params.id);
    }
  }, [sponsorsData, location.pathname]);

  useEffect(() => {
    if (sponsorsData?.length > 0 && location?.state?.sponsor?.id) {
      updateLocationStateSponsor();
    }
  }, [isFetching]);

  const updateLocationStateSponsor = () => {
    const findSponsor = sponsorsData.find(
      (sponsor) =>
        sponsor.name === location?.state.sponsor?.name ||
        sponsor.name === splitCamelCase(location?.state?.sponsor?.name),
    );

    if (!findSponsor) {
      redirectShuffleSponsor();
    }

    redirectToSponsor(findSponsor);
  };

  const redirectShuffleSponsor = () => {
    const findSponsor = sponsorsData.find(
      (sponsor) => sponsor.name === 'Shuffle',
    );

    redirectToSponsor(findSponsor);
  };

  const redirectSponsorByName = (sponsorName?: string) => {
    if (!sponsorName) {
      redirectShuffleSponsor();

      return;
    }

    const findSponsor = sponsorsData.find(
      (sponsor) =>
        sponsor.name === sponsorName ||
        sponsor.name === splitCamelCase(sponsorName),
    );

    redirectToSponsor(findSponsor);
  };

  const redirectToSponsor = (findSponsor: any) => {
    const currentSponsor = findSponsor ? findSponsor : sponsorsData[0];

    const urlSponsorName = currentSponsor.name.replace(/\s+/g, '');

    navigate(routes.leaderboardSponsor.replace(':id', urlSponsorName), {
      state: { sponsor: currentSponsor },
    });
  };

  const splitCamelCase = (sponsorName: string) => {
    return sponsorName.replace(/([a-z])([A-Z])/g, '$1 $2');
  };

  if (isFetchingSponsors) {
    return <PageLoading />;
  }

  return (
    <>
      <Outlet />
    </>
  );
}
