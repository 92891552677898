import React, { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import { IRaffle, RaffleStatus, RaffleType } from 'src/@types/raffles';
import { RAFFLE_STATUS } from 'src/constants/admin';
import moderateRafflesService from 'src/services/moderateRafflesService';
import Button from 'src/ui/Button';
import Select from 'src/ui/Select';
import { convertUtcToEst } from 'src/utils/date';

import s from './style.module.scss';

import { ReactComponent as EditIcon } from 'src/assets/icons/edit.svg';
import { ReactComponent as TrashIcon } from 'src/assets/icons/trash.svg';

const RaffleItem = ({
  item,
  getTypeOption,
  onEdit,
  onDelete,
}: {
  item: IRaffle;
  getTypeOption?: (item: IRaffle) => string;
  onEdit: () => void;
  onDelete: () => void;
}) => {
  const queryClient = useQueryClient();
  const [status, setStatus] = useState<{ label: string; value: string } | any>(
    RAFFLE_STATUS.find((s) => s.value === item.status) || RAFFLE_STATUS[0],
  );

  const { mutate } = useMutation({
    mutationKey: ['editRaffleStatus'],
    mutationFn: ({ id, status }: { id: string; status: RaffleStatus }) =>
      moderateRafflesService.editRaffleStatus({ id, status }),
    onSuccess: (data, variables) => {
      //FIX: refactorn, update only one item in the list
      queryClient.invalidateQueries({ queryKey: ['giveaways-admin'] });
      toast.success('Status updated successfully');
    },
    onError: (error: any) => {
      const errorMessage =
        error?.response?.data?.message || 'Error updating status';
      toast.error(errorMessage);
      console.error('Update status error:', error);
    },
  });

  const handleEdit = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    onEdit();
  };

  const handleDelete = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    onDelete();
  };

  const handleChangeStatus = (
    value: { label: string; value: RaffleStatus } | any,
  ) => {
    setStatus(value);
    mutate({ id: item.id, status: value.value });
  };

  return (
    <ul className={s.item}>
      <li className={s.item__name}>{item.name}</li>
      <li>{convertUtcToEst(item.startDate)}</li>
      {/* <li>{item.type}</li> */}
      <li>{getTypeOption ? getTypeOption(item) : (item.category ?? 'N/A')}</li>

      {item.type === RaffleType.GIVEAWAY ? (
        <li>
          <div className={s.item__select}>
            <Select
              options={RAFFLE_STATUS}
              value={status}
              onChange={handleChangeStatus}
            />
          </div>
        </li>
      ) : (
        <li>{item.status}</li>
      )}

      <li className={s.item__actions}>
        <Button borderRadius="6px" onClick={handleEdit}>
          <EditIcon /> Edit
        </Button>
        <Button
          color="#C60000"
          isIcon
          borderRadius="6px"
          onClick={handleDelete}
        >
          <TrashIcon />
        </Button>
      </li>
    </ul>
  );
};

export default RaffleItem;
