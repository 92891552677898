import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Form, FormikProvider, useFormik } from 'formik';
import { toast } from 'react-toastify';

import { ISponsor, SponsorStatus } from 'src/@types/leaderboard';
import { RaffleType } from 'src/@types/raffles';
import { ModalProps } from 'src/layouts/AdminLayout';
import sponsorConroller from 'src/services/leaderboard/sponsorConroller';
import moderateRafflesService from 'src/services/moderateRafflesService';
import Button from 'src/ui/Button';
import FileUploader from 'src/ui/FileUploader';
import Input from 'src/ui/Input';
import Modal from 'src/ui/Modal';
import Select from 'src/ui/Select';
import Textarea from 'src/ui/Textarea';
import { createImageUrl } from 'src/utils/url';

import { FORM_VALIDATION_SCHEMA, INITIAL_VALUES, STATUSES } from './constants';

import s from './style.module.scss';

import LogoImage from 'src/assets/images/btcs-logo.png';

export type GiveawayModalProps = {
  editingSponsor: ISponsor | null;
  onSuccessCreated?: (raffle: ISponsor) => void;
} & ModalProps;

interface IFormState {
  name: string;
  rules: string;
  syncUrl: string;
  syncUrlToken: string;
  commonPrize: string;
  numberOfVisibleUsers: number;
  currency: string;
  status: any;
}

interface IBody {
  id?: string;
  logo?: null | any;
  name: string;
  rules: string;
  syncUrl: string;
  token?: string;
  saveToken: boolean;
  commonPrize: string;
  numberOfVisibleUsers: number;
  currency: string;
  status: SponsorStatus;
}

const clearFormState = {
  name: '',
  rules: '',
  syncUrl: '',
  syncUrlToken: '',
  currency: '',
  commonPrize: '',
  numberOfVisibleUsers: 0,
  type: RaffleType.RAFFLE,
  status: STATUSES[0],
};

function SponsorModal({
  editingSponsor,
  onSuccessCreated,
  onClose,
  open,
}: GiveawayModalProps) {
  const queryClient = useQueryClient();
  const isEdit = !!editingSponsor;
  const [error, setError] = useState<string | null>(null);
  const [image, setImage] = useState<any>('');

  const [formState, setFormState] = useState<IFormState>(clearFormState);

  const formikProps = {
    initialValues: INITIAL_VALUES,
    validationSchema: FORM_VALIDATION_SCHEMA,
    onSubmit: (values) => {
      if (isLoadingCreate || isLoadingEdit) {
        return;
      }

      if (isEdit) {
        onEdit();
      } else {
        onCreate();
      }
    },
  };

  const formik = useFormik(formikProps);

  // Create sponsor
  const { mutate: create, isPending: isLoadingCreate } = useMutation({
    mutationFn: (body: any) => sponsorConroller.createSponsor(body),
    onSuccess: (data) => {
      if (onSuccessCreated) onSuccessCreated(data);
      queryClient.invalidateQueries({ queryKey: ['sponsors-admin'] });
      handleClose();
    },
    onError: (error: any) => {
      const errorMessage = 'Error creating sponsor, please try again';
      setError(errorMessage);
      toast.error(errorMessage);
      console.error('Create sponsor error:', error);
    },
  });

  const { mutate: edit, isPending: isLoadingEdit } = useMutation({
    mutationFn: (body: any) =>
      sponsorConroller.editSponsor({ id: editingSponsor?.id || '', body }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['sponsors-admin'],
      });
      toast.success('Sponsor edited successfully');
      handleClose();
    },
    onError: (error: any) => {
      const errorMessage = 'Error editing sponsor, please try again';
      setError(errorMessage);
      toast.error(errorMessage);
      console.error('Edit sponsor error:', error);
    },
  });

  // Create image
  const { mutate: createImage, isPending: isLoadingImage } = useMutation({
    mutationFn: (file: FormData) => moderateRafflesService.createImage(file),
    onSuccess: (response) => {
      handleChange({ target: { name: 'image', value: response } });
      setImage(response);
    },
    onError: (error: any) => {
      const errorMessage = 'Error upload image, please try again';
      setError(errorMessage);
      toast.error(errorMessage);
      console.error('Error upload image:', error);
    },
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };

  const handleClose = () => {
    setError(null);
    onClose();
  };

  const handleChangeImage = async (file: File | null) => {
    if (!file) return;
    const formData = new FormData();

    formData.append('file', file);

    await createImage(formData);
  };

  const formBody = () => {
    const {
      name,
      rules,
      syncUrl,
      syncUrlToken,
      currency,
      commonPrize,
      numberOfVisibleUsers,
      status,
    } = formState;

    const body: IBody = {
      // id: editingSponsor ? editingSponsor?.id : null,
      name: name,
      rules,
      syncUrl,
      saveToken: syncUrlToken ? true : false,
      currency,
      commonPrize,
      numberOfVisibleUsers: Number(numberOfVisibleUsers),
      status: status.value,
    };

    if (image) {
      body.logo = image;
    }

    if (syncUrlToken) {
      body.token = syncUrlToken;
    }

    return body;
  };

  const onCreate = () => {
    const body = formBody();

    create(body);
  };

  const onEdit = () => {
    const body = formBody();

    body.id = editingSponsor?.id;
    edit(body);
  };

  const onCancel = () => {
    onClose();
    setFormState(clearFormState);
  };

  const handleDeleteImage = () => {
    setImage('');
    handleChange({ target: { name: 'image', value: null } });
  };

  useEffect(() => {
    if (editingSponsor) {
      setImage(editingSponsor.logo || '');
      const status = STATUSES.find(
        (status) => status.value === editingSponsor.status,
      );

      setFormState({
        name: editingSponsor.name || '',
        rules: editingSponsor.rules || '',
        syncUrl: editingSponsor.syncUrl || '',
        syncUrlToken: editingSponsor.syncUrlToken || '',
        currency: editingSponsor.currency || '',
        commonPrize: editingSponsor.commonPrize || '',
        numberOfVisibleUsers: editingSponsor.numberOfVisibleUsers || 0,
        status: status || STATUSES[0],
      });

      formik.setValues({
        name: editingSponsor.name || '',
        rules: editingSponsor.rules || '',
        syncUrl: editingSponsor.syncUrl || '',
        syncUrlToken: editingSponsor.syncUrlToken || '',
        currency: editingSponsor.currency || '',
        commonPrize: editingSponsor.commonPrize || '',
        numberOfVisibleUsers: editingSponsor.numberOfVisibleUsers || 0,
        status: status?.value || STATUSES[0].value,
      });
    }
  }, [editingSponsor]);

  return (
    <Modal open={open} handleClose={handleClose} isCloseIcon>
      <FormikProvider value={formik}>
        <Form>
          <div className={s.modal__header}>
            <img src={LogoImage} alt="logo" />

            <h2>
              {isEdit ? `Edit ` : `Add `}
              Sponsor
            </h2>
          </div>

          <div className={s.modal__form}>
            <Input
              label="Name*"
              name="name"
              placeholder="Type here"
              value={formState.name}
              onChange={(e) => {
                formik.setFieldValue(e.target.name, e.target.value);
                handleChange(e);
              }}
              error={formik.errors.name}
            />

            <Textarea
              label="Rules*"
              name="rules"
              placeholder="Type here"
              value={formState.rules}
              onChange={(e) => {
                formik.setFieldValue(e.target.name, e.target.value);
                handleChange(e);
              }}
              error={formik.errors.rules}
            />

            <Input
              label="Sync Url*"
              name="syncUrl"
              placeholder="Type here"
              value={formState.syncUrl}
              onChange={(e) => {
                formik.setFieldValue(e.target.name, e.target.value);
                handleChange(e);
              }}
              error={formik.errors.syncUrl}
            />

            <Input
              label={`Sync Url Token ${
                editingSponsor?.token === 'true' ? '(Uploaded)' : ''
              }`}
              name="syncUrlToken"
              placeholder={
                editingSponsor?.token === 'true'
                  ? 'The URL token for synchronization is uploaded'
                  : 'Type here'
              }
              value={formState.syncUrlToken}
              onChange={(e) => {
                formik.setFieldValue(e.target.name, e.target.value);
                handleChange(e);
              }}
              error={formik.errors.syncUrlToken}
            />

            <Select
              label={'Status*'}
              name={'status'}
              value={formState.status}
              options={STATUSES}
              onChange={(newValue: any) => {
                formik.setFieldValue('status', newValue?.value);
                handleChange({
                  target: { name: 'status', value: newValue },
                });
              }}
              placeholder={'Type here'}
            />

            <Input
              label="Common Prize*"
              name="commonPrize"
              placeholder="Type here"
              value={formState.commonPrize}
              onChange={(e) => {
                formik.setFieldValue(e.target.name, e.target.value);
                handleChange(e);
              }}
              error={formik.errors.commonPrize}
            />

            <Input
              label="Number Of Visible Users*"
              type="number"
              name="numberOfVisibleUsers"
              placeholder="Type here"
              onChange={(e) => {
                formik.setFieldValue(e.target.name, e.target.value);
                handleChange(e);
              }}
              error={formik.errors.numberOfVisibleUsers}
              value={formState.numberOfVisibleUsers}
              min={0}
            />

            <Input
              label="Currency*"
              name="currency"
              placeholder="Type here"
              value={formState.currency}
              onChange={(e) => {
                formik.setFieldValue(e.target.name, e.target.value);
                handleChange(e);
              }}
              error={formik.errors.currency}
            />

            {isLoadingImage ? (
              <div>Loading image...</div>
            ) : (
              <FileUploader
                value={image}
                url={createImageUrl(image.url)}
                setValue={handleChangeImage}
                onDelete={handleDeleteImage}
                label="Icon upload"
                title="Attach sponsor's logo here*"
                name="icon"
                acceptedFileTypes={['image/jpeg', 'image/png']}
              />
            )}
          </div>

          <div className={s.modal__footer}>
            {error && <div className={s.modal__error}>{error}</div>}

            <div className={s.modal__row}>
              <Button
                onClick={onCancel}
                className={s.modal__btn}
                isOutlinedWhite
              >
                Cancel
              </Button>
              <Button className={s.modal__btn} type="submit">
                {isLoadingCreate || isLoadingEdit
                  ? 'Submitting...'
                  : isEdit
                    ? `Edit`
                    : `Add`}
              </Button>
            </div>
          </div>
        </Form>
      </FormikProvider>
    </Modal>
  );
}

export default SponsorModal;
