import React from 'react';

import { IRafflePlayer } from 'src/@types/raffles';
import Button from 'src/ui/Button';

import s from './style.module.scss';

import { ReactComponent as GiftIcon } from 'src/assets/icons/gift.svg';

interface IEntryItemProps {
  item: IRafflePlayer;
  isWinner?: boolean;
  isBanned?: boolean;
  isRewarded?: boolean;
  onReward?: ((id: string) => void) | null;
  onBan: () => void;
}

const EntryItem = ({
  item,
  isWinner,
  isBanned,
  isRewarded,
  onReward,
  onBan,
}: IEntryItemProps) => {
  return (
    <div className={s.entry}>
      <div className={s.entry__info}>
        <div className={s.entry__img}>
          <img src={item.avatarUrl} alt="avatar" />
        </div>

        <div className={s.entry__text}>
          <div className={s.entry__name}>
            {item.accountName}{' '}
            {isWinner ? (isRewarded ? '- rewarded' : '- winner') : ''}
          </div>
          <div className={s.entry__ticket}>Tickets: {item.joinedTimes}</div>
        </div>
      </div>

      <div className={s.entry__row}>
        {isWinner && !isRewarded && onReward && (
          <Button
            className="iconBtn"
            borderRadius="6px"
            onClick={() => onReward(item.id)}
          >
            <GiftIcon /> Reward
          </Button>
        )}

        <Button borderRadius="6px" isOutlinedWhite onClick={onBan}>
          {item.banned ? 'Unban' : 'Ban'}
        </Button>
      </div>
    </div>
  );
};

export default EntryItem;
