import * as Yup from 'yup';

import { SponsorStatus } from 'src/@types/leaderboard';

export const CATEGORIES = [
  {
    id: 0,
    value: 'NONE',
  },
  {
    id: 1,
    value: 'SHUFFLE',
  },
  {
    id: 2,
    value: 'RUNESCAPE',
  },
];

export const STATUSES = [
  {
    id: 1,
    label: 'Active',
    value: SponsorStatus.ACTIVE,
  },
  {
    id: 2,
    label: 'Inactive',
    value: SponsorStatus.INACTIVE,
  },
];

export const INITIAL_VALUES = {
  name: '',
  rules: '',
  syncUrl: '',
  syncUrlToken: '',
  currency: '',
  commonPrize: '',
  numberOfVisibleUsers: 30,
  status: SponsorStatus.ACTIVE,
};

export const FORM_VALIDATION_SCHEMA = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  rules: Yup.string().required('Rules is required'),
  syncUrl: Yup.string().required('Sync Url is required'),
  syncUrlToken: Yup.string(),
  currency: Yup.string().required('Currency is required'),
  commonPrize: Yup.string().required('Common prize is required'),
  numberOfVisibleUsers: Yup.number().required(
    'Number of visible users is required',
  ),
  status: Yup.string().required('Status is required'),
});
