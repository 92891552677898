export const GIVEAWAY_HEADER_LIST = [
  {
    id: 0,
    title: 'Name',
    column: 'name',
    isSortable: true,
  },
  {
    id: 1,
    title: 'Start Date',
    column: 'startDate',
    isSortable: true,
  },
  {
    id: 2,
    title: 'Sponsor',
    column: 'category',
    isSortable: true,
  },
  {
    id: 3,
    title: 'Status',
    column: 'status',
    isSortable: true,
  },
  {
    id: 4,
    title: 'Actions',
    isSortable: false,
  },
];

export const RAFFLE_HEADER_LIST = [
  {
    id: 0,
    title: 'Name',
    column: 'name',
    isSortable: true,
  },
  {
    id: 1,
    title: 'Start Date',
    column: 'startDate',
    isSortable: true,
  },
  {
    id: 2,
    title: 'Type',
    column: 'category',
    isSortable: true,
  },
  {
    id: 3,
    title: 'Status',
    column: 'status',
    isSortable: true,
  },
  {
    id: 4,
    title: 'Actions',
    isSortable: false,
  },
];
