import React from 'react';

import s from './style.module.scss';

interface ISpinnerLoadingProps {
  text?: string;
}

const SpinnerLoading = ({ text }: ISpinnerLoadingProps) => {
  return (
    <div className={s.wrapper}>
      <div className={s.text}>{text}</div>
      <div className={s.loading} />
    </div>
  );
};

export default SpinnerLoading;
