import { Pagination as MUIPagination } from '@mui/material';

interface IPaginationProps {
  page: number;
  onChangePage: (e: any, value: number) => void;
  count: number;
  itemsPerPage?: number;
  showLastButton?: boolean;
  showFirstButton?: boolean;
}

const Pagination = ({ page, onChangePage, count }: IPaginationProps) => {
  return (
    <MUIPagination
      sx={{
        display: 'flex',
        justifyContent: 'center',
        gap: '8px',
        '& .MuiPaginationItem-root': {
          height: '40px',
          width: '40px',
          minWidth: '40px',
          border: '1px solid #FFFFFF1A',
          borderRadius: '50%',
          color: '#fff',
          background: 'transparent',
          '&.Mui-selected': {
            backgroundColor: '#7637e0',
            border: '1px solid #7637e0',
            color: '#fff',

            '&:hover': {
              backgroundColor: 'rgb(133, 63, 254)',
            },
          },
          '&:hover': {
            backgroundColor: '#FFFFFF1A',
          },
        },
        '& .MuiPaginationItem-ellipsis': {
          border: 'none',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
      }}
      count={count}
      page={page}
      onChange={onChangePage}
      showFirstButton
      showLastButton
    />
  );
};

export default Pagination;
