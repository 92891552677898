import {
  IRaffle,
  IRafflePublicPlayer,
  RaffleStatus,
  RaffleType,
} from 'src/@types/raffles';
import { axiosGet, axiosGetWithHeaders, axiosPost } from 'src/utils/httpClient';

export interface IGetRafflesResponse {
  data: IRaffle[];
  total: number;
}

const playerService = {
  async getRaffles({
    type,
    status,
    page,
    size,
    category,
  }: {
    type: RaffleType;
    status: RaffleStatus;
    page?: number;
    size?: number;
    category?: string;
  }): Promise<IGetRafflesResponse> {
    let url = `/public/raffles/${type}?status=${status}`;

    if (page !== undefined) url += `&page=${page}`;
    if (size !== undefined) url += `&size=${size}`;
    if (category) url += `&category=${category}`;

    const response = await axiosGetWithHeaders(url);

    return {
      data: response.data,
      total: parseInt(response.headers['x-total-count'], 10) || 0,
    };
  },

  async getRaffle({ id }: { id: string }): Promise<IRaffle> {
    const response = await axiosGet(`/public/raffles/${id}/details`);

    return response;
  },

  async joinToRaffle({ id }: { id: string }): Promise<void> {
    const response = await axiosPost(`/player/raffle/${id}/join`);
    return response;
  },

  async getImage({ id }: { id: string }): Promise<void> {
    const response = await axiosGet(`/public/images/${id}`);
    return response;
  },

  async getPlayersForRaffle({
    id,
    page,
    size,
  }: {
    id: string;
    page: string | number;
    size: string | number;
  }): Promise<{ data: IRafflePublicPlayer[]; total: number }> {
    const response = await axiosGetWithHeaders(
      `/public/raffles/${id}/players?page=${page}&size=${size}`,
    );

    return {
      data: response.data,
      total: parseInt(response.headers['x-total-count'], 10),
    };
  },

  async getWinnersForRaffle({
    id,
  }: {
    id: string;
  }): Promise<{ data: any; total: number }> {
    const response = await axiosGetWithHeaders(`/public/raffles/${id}/winners`);

    return {
      data: response.data,
      total: parseInt(response.headers['x-total-count'], 10),
    };
  },

  async getTop3Leaderboard({ id }: { id: string }): Promise<{ id: string }> {
    const response = await axiosGet(
      `/general/top-3-leaderboard?sponsorId=${id}`,
    );

    return response;
  },

  async getKickVerify() {
    const response = await axiosGet(`/player/verify-kick`);

    return response;
  },
};

export default playerService;
