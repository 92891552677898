import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Form, FormikProvider, useFormik } from 'formik';
import { toast } from 'react-toastify';

import { IChannel } from 'src/@types/channels';
import { ModalProps } from 'src/layouts/AdminLayout';
import channelsService from 'src/services/channels/moderateService';
import Button from 'src/ui/Button';
import Input from 'src/ui/Input';
import Modal from 'src/ui/Modal';

import { FORM_VALIDATION_SCHEMA, INITIAL_VALUES } from './constants';

import s from './style.module.scss';

import LogoImage from 'src/assets/images/btcs-logo.png';

export type ChannelyModalProps = {
  editingItem: IChannel | null;
  onSuccessCreated?: (raffle: IChannel) => void;
} & ModalProps;

interface IFormState {
  name: string;
  channelId: string;
  numberOfItemsToFetch: number;
}

const clearFormState = {
  name: '',
  channelId: '',
  numberOfItemsToFetch: 0,
};

function ChannelModal({
  editingItem,
  onSuccessCreated,
  onClose,
  open,
}: ChannelyModalProps) {
  const queryClient = useQueryClient();
  const isEdit = !!editingItem;
  const [error, setError] = useState<string | null>(null);
  const [image, setImage] = useState<any>('');

  const [formState, setFormState] = useState<IFormState>(clearFormState);

  const formikProps = {
    initialValues: INITIAL_VALUES,
    validationSchema: FORM_VALIDATION_SCHEMA,
    onSubmit: (values) => {
      if (isLoadingCreate || isLoadingEdit) {
        return;
      }

      if (isEdit) {
        onEdit();
      } else {
        onCreate();
      }
    },
  };

  const formik = useFormik(formikProps);

  // Create channel
  const { mutate: create, isPending: isLoadingCreate } = useMutation({
    mutationFn: (body: any) => channelsService.createChannel({ body }),
    onSuccess: (data) => {
      if (onSuccessCreated) onSuccessCreated(data);
      queryClient.invalidateQueries({ queryKey: ['channels-admin'] });
      handleClose();
    },
    onError: (error: any) => {
      const errorMessage =
        error?.response?.data?.message || 'Error creating channel';
      setError(errorMessage);
      toast.error(errorMessage);
      console.error('Create channel error:', error);
    },
  });

  // Edit channel
  const { mutate: edit, isPending: isLoadingEdit } = useMutation({
    mutationFn: (body: any) => channelsService.editChannel({ body }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['channels-admin'] });
      toast.success('Channel edited successfully');
      handleClose();
    },
    onError: (error: any) => {
      const errorMessage =
        error?.response?.data?.message || 'Error edit channel';
      setError(errorMessage);
      toast.error(errorMessage);
      console.error('Edit channel error:', error);
    },
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };

  const handleClose = () => {
    setError(null);
    onClose();
  };

  const formBody = () => {
    const { name, channelId, numberOfItemsToFetch } = formState;

    const body = {
      name,
      channelId,
      numberOfItemsToFetch: Number(numberOfItemsToFetch),
    };

    return body;
  };

  const onCreate = () => {
    const body = formBody();

    create(body);
  };

  const onEdit = () => {
    const body = formBody();
    // @ts-ignore
    body.id = editingItem?.id;

    edit(body);
  };

  const onCancel = () => {
    onClose();
    setFormState(clearFormState);
  };

  useEffect(() => {
    if (editingItem) {
      setFormState({
        name: editingItem.name || '',
        channelId: editingItem.channelId || '',
        numberOfItemsToFetch: editingItem.numberOfItemsToFetch || 0,
      });

      formik.setValues({
        name: editingItem.name || '',
        channelId: editingItem.channelId || '',
        numberOfItemsToFetch: editingItem.numberOfItemsToFetch || 0,
      });
    }
  }, [editingItem]);

  return (
    <Modal maxWidth="xs" open={open} handleClose={handleClose} isCloseIcon>
      <FormikProvider value={formik}>
        <Form>
          <div className={s.modal__header}>
            <img src={LogoImage} alt="logo" />

            <h2>
              {isEdit ? `Edit ` : `Add `}
              Channel
            </h2>
          </div>

          <div className={s.modal__form}>
            <Input
              label="Name"
              name="name"
              placeholder="Type here"
              value={formState.name}
              onChange={(e) => {
                formik.setFieldValue(e.target.name, e.target.value);
                handleChange(e);
              }}
              error={formik.errors.name}
            />

            <Input
              label="Channel ID"
              name="channelId"
              placeholder="Type here"
              value={formState.channelId}
              onChange={(e) => {
                formik.setFieldValue(e.target.name, e.target.value);
                handleChange(e);
              }}
              error={formik.errors.channelId}
            />

            <Input
              label="Number of Videos"
              name="numberOfItemsToFetch"
              placeholder="Type here"
              type="number"
              value={formState.numberOfItemsToFetch}
              onChange={(e) => {
                formik.setFieldValue(e.target.name, e.target.value);
                handleChange(e);
              }}
              error={formik.errors.numberOfItemsToFetch}
            />
          </div>

          <div className={s.modal__footer}>
            {error && <div className={s.modal__error}>{error}</div>}

            <div className={s.modal__row}>
              <Button
                onClick={onCancel}
                className={s.modal__btn}
                isOutlinedWhite
              >
                Cancel
              </Button>
              <Button className={s.modal__btn} type="submit">
                {isLoadingCreate || isLoadingEdit
                  ? 'Submitting...'
                  : isEdit
                    ? `Edit`
                    : `Add`}
              </Button>
            </div>
          </div>
        </Form>
      </FormikProvider>
    </Modal>
  );
}

export default ChannelModal;
