import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useParams, useSearchParams } from 'react-router';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import { ISponsorDefinition } from 'src/@types/leaderboard';
import TablePanelBlacklist from 'src/components/Admin/leaderboard/table/TablePanelBlacklist';
import TablePanelPrize from 'src/components/Admin/leaderboard/table/TablePanelPrize';
import SpinnerLoading from 'src/components/loadings/SpinnerLoading';
import sponsorConroller from 'src/services/leaderboard/sponsorConroller';

import TablePanelDefinitions from '../TablePanelDefinitions';
import TablePanelUsers from '../TablePanelUsers';

import 'src/assets/CSS/Admin.scss';
import s from './style.module.scss';

interface ILeaderboardTableProps {
  isSyncUsers: boolean;
}

const LeaderboardTable = ({ isSyncUsers }: ILeaderboardTableProps) => {
  const params = useParams();
  const sponsorId = params.id!;
  const [searchParams, setSearchParams] = useSearchParams();
  const page = Number(searchParams.get('page'));
  const userSearch = searchParams.get('userSearch');

  // const {
  //   data: sponsorUsersData,
  //   isLoading: isFetchingSponsorUsers,
  //   refetch: refetchSponsorUsers,
  //   isFetched: isFetchedSponsorUsers,
  // } = useQuery({
  //   queryKey: ['sponsor-users', sponsorId, userSearch, page],
  //   queryFn: () =>
  //     sponsorConroller.getSponsorsUsers({
  //       id: sponsorId,
  //       page: page === 0 ? page : page - 1,
  //       size: 30,
  //       name: userSearch,
  //     }),
  //   refetchInterval: REFETCHING_TIME,
  //   placeholderData: (previousData) => previousData,
  //   enabled: Boolean(sponsorId),
  // });

  const {
    data: sponsorPrizeData = [],
    isLoading: isFetchingSponsorPrize,
    refetch: refetchSponsorPrize,
  } = useQuery({
    queryKey: ['sponsor-prize', sponsorId],
    queryFn: () =>
      sponsorConroller.getSponsorPrize({
        id: sponsorId || '',
      }),
    enabled: Boolean(sponsorId),
  });

  const {
    data: sponsorDefinitionsData = [],
    isLoading: isFetchingSponsorDefinitions,
    refetch: refetchSponsorDefinitions,
  } = useQuery<ISponsorDefinition[]>({
    queryKey: ['sponsor-definitions', sponsorId],
    queryFn: () =>
      sponsorConroller.getSponsorDefinitions({
        sponsorId: sponsorId!,
      }),
    enabled: Boolean(sponsorId),
  });

  const {
    data: sponsorBlacklistData = [],
    isLoading: isFetchingSponsorBlacklist,
    refetch: refetchSponsorBlacklist,
  } = useQuery({
    queryKey: ['sponsor-blacklist', sponsorId],
    queryFn: () =>
      sponsorConroller.getSponsorBlacklist({
        id: sponsorId || '',
      }),
    enabled: Boolean(sponsorId),
  });

  // useEffect(() => {
  //   if (sponsorId) {
  //     refetchSponsorUsers();
  //   }
  // }, [page]);

  return (
    <>
      <div>
        <Tabs selectedTabClassName={s.tabs__tab_active} className={s.tabs}>
          <TabList className={s.tabs__list}>
            <Tab className={s.tabs__tab}>Users</Tab>
            <Tab className={s.tabs__tab}>Prizes</Tab>
            <Tab className={s.tabs__tab}>Rewards</Tab>
            <Tab className={s.tabs__tab}>BlackList</Tab>
          </TabList>

          <TabPanel className={s.tabs__panel}>
            {isSyncUsers ? (
              <SpinnerLoading text="Data Synchronization" />
            ) : (
              <TablePanelUsers />
            )}
          </TabPanel>

          <TabPanel className={s.tabs__panel}>
            {isFetchingSponsorPrize ? (
              <div>Loading...</div>
            ) : (
              <TablePanelPrize data={sponsorPrizeData} />
            )}
          </TabPanel>

          <TabPanel className={s.tabs__panel}>
            {isFetchingSponsorPrize ? (
              <div>Loading...</div>
            ) : (
              <TablePanelDefinitions
                data={sponsorDefinitionsData.sort((a, b) => a.value - b.value)}
              />
            )}
          </TabPanel>

          <TabPanel className={s.tabs__panel}>
            <>
              {isFetchingSponsorBlacklist ? (
                <div>Loading...</div>
              ) : (
                <TablePanelBlacklist data={sponsorBlacklistData} />
              )}
            </>
          </TabPanel>
        </Tabs>
      </div>
    </>
  );
};

export default LeaderboardTable;
