import React from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';

import { ISponsorDefinition } from 'src/@types/leaderboard';
import useModal from 'src/hooks/useModal';
import sponsorConroller from 'src/services/leaderboard/sponsorConroller';
import Button from 'src/ui/Button';
import { numberWithCommas } from 'src/utils/utils';

import AddSponsorReward from '../../modals/AddSponsorReward';
import RowItem from '../RowItem';
import TableHeader from '../TableHeader';

import s from './style.module.scss';

const HEADER_LIST = [
  {
    id: 0,
    title: 'Name',
  },

  {
    id: 1,
    title: 'Value',
  },
  {
    id: 2,
    title: 'Actions',
  },
];

interface ITablePanelDefinitionsProps {
  data: ISponsorDefinition[];
}

const TablePanelDefinitions = ({ data }: ITablePanelDefinitionsProps) => {
  const { isOpen, openModal, closeModal } = useModal();
  const [editingReward, setEditingReward] = React.useState<any | null>(null);
  const queryClient = useQueryClient();
  const params = useParams();
  const sponsorId = params.id;

  const { mutate: deleteDefinition } = useMutation({
    mutationFn: ({ sponsorId, id }: { sponsorId: string; id: number }) =>
      sponsorConroller.deleteSponsorDefinition({ sponsorId, id }),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: ['sponsor-definitions', sponsorId],
      });
      toast.success('Reward deleted successfully');
    },
    onError: (error: any) => {
      const errorMessage =
        error?.response?.data?.message || 'Error deleting reward';
      toast.error(errorMessage);
    },
  });

  const onEditDefinition = (item: any) => {
    setEditingReward(item);
    openModal();
  };

  return (
    <>
      <div className={s.panel}>
        <div className={s.panel__action}>
          <Button borderRadius="6px" onClick={openModal}>
            Add Reward
          </Button>
        </div>

        {data.length > 0 ? (
          <>
            <div className={s.panel__header}>
              <h4>Rewards</h4>
            </div>

            <TableHeader columns={HEADER_LIST} />
            <ul className={s.panel__table}>
              {data.map((item, index) => (
                <li>
                  <RowItem
                    item={item}
                    fields={[
                      {
                        key: 'name',
                        render: (value) => (
                          <div className={s.panel__nameBLock}>
                            {index + 1}. <img src={item.image} /> {value}
                          </div>
                        ),
                      },
                      {
                        key: 'value',
                        render: (value) => (
                          <span>{numberWithCommas(value, 0)}</span>
                        ),
                      },
                    ]}
                    actions={[
                      {
                        label: 'Edit',
                        onClick: (item) => onEditDefinition(item),
                      },
                      {
                        id: 'delete',
                        label: 'Delete',
                        onClick: (item) =>
                          deleteDefinition({
                            sponsorId: sponsorId!,
                            id: item.id,
                          }),
                      },
                    ]}
                  />
                </li>
              ))}
            </ul>
          </>
        ) : (
          <h4>No rewards</h4>
        )}
      </div>

      <AddSponsorReward
        sponsorId={sponsorId!}
        key={editingReward ? editingReward.id : 'no-id-prize'}
        editingReward={editingReward}
        open={isOpen}
        onClose={() => {
          closeModal();
          setEditingReward(null);
        }}
      />
    </>
  );
};

export default TablePanelDefinitions;
