import React from 'react';
import cn from 'classnames';

import s from './style.module.scss';

interface ITextareaProps {
  label?: string;
  name?: string;
  placeholder?: string;
  value: string | number;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  className?: string;
  error?: string;
  disabled?: boolean;
}

const Textarea = ({
  label,
  name,
  placeholder,
  value,
  onChange,
  disabled,
  className,
  error,
}: ITextareaProps) => {
  const isError = !!error;

  return (
    <div className={cn(s.textarea, className)}>
      {label && <label className={s.textarea__label}>{label}</label>}
      <textarea
        disabled={disabled}
        className={cn(s.textarea__field, className)}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />

      {isError && <div className={s.textarea__error}>{error}</div>}
    </div>
  );
};

export default Textarea;
