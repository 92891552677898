import React from 'react';
import cn from 'classnames';

import { ISponsorUser } from 'src/@types/leaderboard';
import { calculateVIPRewards, numberWithCommas } from 'src/utils/utils';

import s from './style.module.scss';

interface ITopLeaderboardSliderProps {
  data: ISponsorUser[];
}

const TopLeaderboardSlider = ({ data }: ITopLeaderboardSliderProps) => {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const activeData = data[activeIndex];

  return (
    <div className={s.top}>
      <div className={s.top__rankings}>
        <div className={s.ranking}>
          <div
            className={cn(
              s.ranking__content,
              activeIndex === 0 && s.ranking_gold,
              activeIndex === 1 && s.ranking_silver,
              activeIndex === 2 && s.ranking_bronze,
            )}
          >
            <div className={s.ranking__userIcon}>
              <img
                src={
                  activeData
                    ? (activeData.image ?? 'https://btcs.gg/newcoin.svg')
                    : 'https://btcs.gg/newcoin.svg'
                }
              />
            </div>
            <div
              className={cn(
                s.user,
                activeIndex === 0 && 'User-info-container-gold',
                activeIndex === 1 && 'User-info-container-silver',
                activeIndex === 2 && 'User-info-container-bronze',
              )}
            >
              <div className={s.user__info}>
                <div className={s.pedestalCard__prizes}>
                  <div className={s.pedestalCard__prize}>
                    <div className={s.pedestalCard__prize_title}>LB Prize</div>
                    <div className={s.pedestalCard__prize_count}>
                      $
                      {activeData ? numberWithCommas(activeData.prize, 0) : '-'}
                    </div>
                  </div>

                  <div className={s.pedestalCard__prize}>
                    <div className={s.pedestalCard__prize_title}>
                      Rewards Earned
                    </div>
                    <div className={s.pedestalCard__prize_count}>
                      $
                      {activeData
                        ? calculateVIPRewards(
                            activeData.totalWagered! || activeData.wagered!,
                            true,
                          )
                        : '-'}
                    </div>
                  </div>
                </div>
                <h4>
                  $
                  {activeData
                    ? numberWithCommas(
                        activeData.totalWagered || activeData.wagered,
                        0,
                      )
                    : '-'}
                </h4>
                <span>Wagered</span>
                <div className={s.user__username}>
                  {activeData ? activeData.username.slice(0, 2) : '-'}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={s.selection}>
        <div
          onClick={() => setActiveIndex(0)}
          className={cn(
            s.selection__first,
            activeIndex === 0 && s.selection__first_active,
          )}
        >
          1
        </div>
        <div
          onClick={() => setActiveIndex(1)}
          className={cn(
            s.selection__second,
            activeIndex === 1 && s.selection__second_active,
          )}
        >
          2
        </div>
        <div
          onClick={() => setActiveIndex(2)}
          className={cn(
            s.selection__third,
            activeIndex === 2 && s.selection__third_active,
          )}
        >
          3
        </div>
      </div>
    </div>
  );
};

export default TopLeaderboardSlider;
