import { useEffect, useState } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useSearchParams } from 'react-router';
import { useDebounce } from 'use-debounce';

import { formatDateYearTime } from 'src/components/Crates';
import SpinnerLoading from 'src/components/loadings/SpinnerLoading';
import { ModalProps } from 'src/layouts/AdminLayout';
import moderateService from 'src/services/adminDashboard/moderateService';
import { Challenge, ConcludeSlotBattleRequest, Player } from 'src/types';
import Input from 'src/ui/Input';
import Modal from 'src/ui/Modal';
import Pagination from 'src/ui/Pagination';
import { axiosPut } from 'src/utils/httpClient';

import RowItem from '../leaderboard/table/RowItem';
import TableHeader from '../leaderboard/table/TableHeader';

export type ConcludeChallengeModalProps = {
  editing?: Challenge;
  players: Array<Player>;
} & ModalProps;

export const dialogStyles = {
  backgroundColor: '#1a1d23',
  color: 'white',
  padding: '25px',
  boxShadow: 'none',
};

const HEADER_LIST = [
  {
    id: 0,
    title: 'Name',
    column: 'accountName',
    isSortable: true,
  },
  {
    id: 1,
    title: 'Shuffle Name',
    column: 'shuffleUsername',
    isSortable: true,
  },

  {
    id: 2,
    title: 'Authority',
    column: 'authority',
    isSortable: true,
  },
  {
    id: 3,
    title: 'Last Online',
    column: 'lastSeen',
    isSortable: true,
  },
];

function ConcludeChallengeModal(props: ConcludeChallengeModalProps) {
  const { onClose, open } = props;
  const [searchParams, setSearchParams] = useSearchParams();

  const sortColumn = searchParams.get('userSortColumn');
  const sortOrder = searchParams.get('userSortOrder') || 'asc';
  const page = Number(searchParams.get('usersPage'));
  const itemsPerPage = 10;
  const [search, setSearch] = useState('');
  const [searchUserName] = useDebounce(search, 1000);

  const [warned, setWarned] = useState<boolean>(false);
  const [selected, setSelected] = useState<Player | undefined>(undefined);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const concludeWinner = async (
    request: ConcludeSlotBattleRequest,
  ): Promise<Array<Challenge>> =>
    axiosPut(`/moderate/set-challenge-winner`, request);

  const handleClose = () => {
    onClose();
  };

  const {
    data: players = { data: [], total: 0 },
    isLoading: isLoadingPlayers,
  } = useQuery({
    queryKey: ['players', { page, sortColumn, sortOrder, searchUserName }],
    queryFn: () =>
      moderateService.getPlayers({
        size: itemsPerPage,
        name: searchUserName,
        page: page - 1,
        sortColumn,
        sortOrder,
      }),
    placeholderData: (previousData) => previousData,
  });

  useEffect(() => {
    if (!searchParams.has('usersPage')) {
      setUrlQuery('usersPage', '1');
    }
  }, []);

  const setUrlQuery = (key: string, value: string) => {
    setSearchParams((prev) => {
      prev.set(key, value);
      return prev;
    });
  };

  const removeUrlQuery = (key: string | null) => {
    if (!key) return;

    searchParams.delete(key);
  };

  const handleChangePage = (value: number) => {
    setSearchParams((prev) => {
      prev.set('usersPage', value.toString());
      return prev;
    });
  };

  const onChangeSearch = (value: string) => {
    setSearch(value);

    if (value) {
      removeUrlQuery('userSortColumn');
      removeUrlQuery('userSortOrder');
      setUrlQuery('usersPage', '1');
    }
  };

  useEffect(() => {
    if (sortColumn && sortOrder) {
      setSearch('');
    }
  }, [sortColumn, sortOrder]);

  return (
    <Modal isCloseIcon handleClose={handleClose} open={open} maxWidth="xl">
      <h2>Conclude Challenge</h2>
      <div className="Btcs-form">
        <div className="Form-input-field">
          <h6>Winner</h6>
        </div>

        <div className="Form-input-field">
          <div>
            <div>
              <div className="Bonus-hunt-search">
                <div className="search-input">
                  <img src="/navigation/search.svg" width={20} />
                  <Input
                    type="text"
                    placeholder="Search"
                    value={search}
                    onChange={(e) => onChangeSearch(e.target.value)}
                  />
                </div>
              </div>

              {isLoadingPlayers ? (
                <SpinnerLoading />
              ) : (
                <div className="Btcs-table">
                  <TableHeader
                    sortColKey="userSortColumn"
                    sortColOrder="userSortOrder"
                    columns={HEADER_LIST}
                  />

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '10px',
                      maxHeight: '400px',
                      overflowY: 'auto',
                    }}
                  >
                    {players.data.map((player) => {
                      return (
                        <RowItem
                          styles={{ background: '#202431' }}
                          item={player}
                          fields={[
                            {
                              key: 'accountName',
                              render: (value) => value,
                            },
                            {
                              key: 'shuffleUsername',
                              render: (value) => value,
                            },
                            {
                              key: 'authority',
                              render: (value) => value,
                            },
                            {
                              key: 'lastOnline',
                              render: (value) => formatDateYearTime(value),
                            },
                          ]}
                        />
                      );
                    })}
                  </div>
                </div>
              )}
            </div>

            <div style={{ paddingTop: '20px' }}>
              {players && players.total > itemsPerPage && (
                <Pagination
                  count={Math.ceil(players.total / itemsPerPage)}
                  itemsPerPage={itemsPerPage}
                  page={page}
                  onChangePage={(e, value) => handleChangePage(value)}
                />
              )}
            </div>
          </div>
        </div>

        <FormControlLabel
          control={
            <Checkbox
              name="Warning"
              checked={warned}
              onChange={(e) => setWarned(!warned)}
            />
          }
          label="I understand that this action cannot be undone."
        />

        <div className="Form-submission">
          <button
            className={warned && selected ? '' : 'Alt'}
            onClick={() => {
              if (submitting) {
                return;
              }
              if (!selected || !warned) {
                return;
              }
              setSubmitting(true);
              if (props.editing) {
                concludeWinner({
                  id: props.editing.id,
                  game: selected.username,
                }).then((r) => {
                  setWarned(false);
                  setSelected(undefined);
                  setSubmitting(false);
                  handleClose();
                });
              }
            }}
          >
            End Challenge & Reward Player
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default ConcludeChallengeModal;
