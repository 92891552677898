import React from 'react';
import { Link } from 'react-router';

import { Login } from 'src/components/Header';
import Button from 'src/ui/Button';
import Divider from 'src/ui/Divider';

import s from './style.module.scss';

import LogoImage from 'src/assets/images/btcs-logo.png';

const LoginModal = () => {
  const { url } = Login();

  return (
    <div className={s.block}>
      <div className={s.block__logo}>
        <img src={LogoImage} alt="logo" />
      </div>

      <div className={s.block__info}>
        <div>
          <h2 className={s.block__title}>Sign In required to continue</h2>
          <p className={s.block__desc}>
            Please <b>Sign In</b> to get started.
          </p>
        </div>

        <div>
          <Divider />
        </div>

        <div className={s.block__btnContainer}>
          <Link to={url}>
            <Button borderRadius="6px">Login</Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LoginModal;
