import {
  ISponsorBlacklistItem,
  ISponsorDefinition,
  ISponsorPrize,
} from 'src/@types/leaderboard';
import { IRaffle, RaffleStatus } from 'src/@types/raffles';
import {
  axiosDelete,
  axiosGet,
  axiosGetWithHeaders,
  axiosPost,
  axiosPut,
} from 'src/utils/httpClient';
// import { IGetRafflesResponse } from './playerService';
import { addQueryParams } from 'src/utils/url';

const sponsorConroller = {
  async createSponsor(body: any) {
    const response = await axiosPost('/admin/sponsor', body);

    return response;
  },

  async editSponsor({ id, body }: { id: string; body: IRaffle }) {
    const response = await axiosPut(`/admin/sponsor`, body);

    return response;
  },

  async getSponsor({ id }: { id: string }): Promise<IRaffle> {
    const response = await axiosGet(`/admin/sponsor/${id}`);

    return response;
  },

  async getSponsors({
    status,
    page,
    size,
    sortColumn,
    sortOrder,
  }: {
    status?: RaffleStatus;
    page?: number;
    size?: number;
    sortColumn?: string | null;
    sortOrder?: string;
  }): Promise<any> {
    const url = `/admin/sponsor`;

    const response = await axiosGet(url);

    return response?.filter((sponsor) => sponsor.name !== 'CasesGG');

    // return {
    //   data: response.data,
    //   total: parseInt(response.headers['x-total-count'], 10),
    // };
  },

  async deleteSponsor({ id }: { id: string }) {
    const response = await axiosDelete(`/admin/sponsor/${id}`);

    return response;
  },

  async deleteSponsorLeaderboard({ id }: { id: string }) {
    const response = await axiosDelete(`/admin/sponsor/${id}/leaderboard`);

    return response;
  },

  async createImage(file: FormData) {
    const response = await axiosPost('/moderate/images', file);

    return response;
  },

  async getSponsorPrize({ id }: { id: string }): Promise<any> {
    const response = await axiosGet(`/admin/sponsor/${id}/prize?size=100`);

    return response;
  },

  async getSponsorBlacklist({ id }: { id: string }): Promise<any> {
    const response = await axiosGet(`/admin/sponsor/${id}/blacklist`);

    return response;
  },

  async syncSponsorData({ id }: { id: string }): Promise<any> {
    const response = await axiosPost(`/admin/sponsor/${id}/sync`);

    return response;
  },

  async loadSponsorData({
    body,
  }: {
    body: { file: any; sponsorId: string };
  }): Promise<any> {
    const response = await axiosPost(`/moderate/leaderboard-data`, body);

    return response;
  },

  async addSponsorPrize({
    sponsorId,
    body,
  }: {
    sponsorId: string;
    body: ISponsorPrize;
  }): Promise<any> {
    const response = await axiosPost(`/admin/sponsor/${sponsorId}/prize`, body);

    return response;
  },

  async updateSponsorPrize({
    sponsorId,
    body,
  }: {
    sponsorId: string;
    body: ISponsorPrize;
  }): Promise<any> {
    const response = await axiosPut(`/admin/sponsor/${sponsorId}/prize`, body);

    return response;
  },

  async deleteSponsorPrize({
    sponsorId,
    id,
  }: {
    sponsorId: string;
    id: string;
  }): Promise<any> {
    const response = await axiosDelete(
      `/admin/sponsor/${sponsorId}/prize/${id}`,
    );

    return response;
  },

  async addSponsorBlacklist({
    sponsorId,
    body,
  }: {
    sponsorId: string;
    body: ISponsorBlacklistItem;
  }): Promise<any> {
    const response = await axiosPost(
      `/admin/sponsor/${sponsorId}/blacklist`,
      body,
    );

    return response;
  },

  async updateSponsorBlacklist({
    id,
    body,
  }: {
    id: string;
    body: ISponsorBlacklistItem;
  }): Promise<any> {
    const response = await axiosPut(`/admin/sponsor/${id}/blacklist`, body);

    return response;
  },

  async deleteSponsorBlacklist({
    sponsorId,
    id,
  }: {
    sponsorId: string;
    id: string;
  }): Promise<any> {
    const response = await axiosDelete(
      `/admin/sponsor/${sponsorId}/blacklist/${id}`,
    );

    return response;
  },

  async getSponsorsUsers({
    id,
    page,
    size,
    name,
    sortColumn,
    sortOrder,
  }: {
    id: string;
    page: number;
    size: number;
    name: string | null;
    sortColumn?: string | null;
    sortOrder?: string;
  }): Promise<any> {
    const url = `/admin/sponsor/${id}/users`;

    let formedUrl = addQueryParams(url, {
      page,
      size,
      username: name,
    });

    if (sortColumn && sortOrder) {
      formedUrl += `&sort=${sortColumn},${sortOrder}`;
    }

    const response = await axiosGetWithHeaders(formedUrl);

    return {
      data: response.data,
      total: parseInt(response.headers['x-total-count'], 10),
    };
  },

  async getSponsorDefinition({ id }: { id: string }): Promise<any> {
    const response = await axiosGet(`/admin/sponsor/${id}/item-definition`);

    return response;
  },

  async abuseAmountUser({
    id,
    body,
  }: {
    id: string;
    body: { abuseAmount: string; username: string };
  }) {
    const response = await axiosPut(
      `/admin/sponsor/${id}/leaderboard/abuse-coefficient`,
      body,
    );

    return response;
  },

  async clearAbuseAmounts({ id }: { id: string }) {
    const response = await axiosDelete(
      `/admin/sponsor/${id}/leaderboard/clear-all-abuse-coefficients`,
    );

    return response;
  },

  async getSponsorDefinitions({
    sponsorId,
  }: {
    sponsorId: string;
  }): Promise<any> {
    const response = await axiosGet(
      `/admin/sponsor/${sponsorId}/item-definition`,
    );

    return response;
  },

  async updateSponsorDefinitions({
    sponsorId,
    body,
  }: {
    sponsorId: string;
    body: ISponsorDefinition;
  }): Promise<any> {
    const response = await axiosPut(
      `/admin/sponsor/${sponsorId}/item-definition`,
      body,
    );

    return response;
  },

  async addSponsorDefinitions({
    sponsorId,
    body,
  }: {
    sponsorId: string;
    body: ISponsorDefinition;
  }): Promise<any> {
    const response = await axiosPost(
      `/admin/sponsor/${sponsorId}/item-definition`,
      body,
    );

    return response;
  },

  async deleteSponsorDefinition({
    sponsorId,
    id,
  }: {
    sponsorId: string;
    id: number;
  }): Promise<any> {
    const response = await axiosDelete(
      `/admin/sponsor/${sponsorId}/item-definition/${id}`,
    );

    return response;
  },

  async getAllDefinitions(): Promise<any> {
    const response = await axiosGet(`/general/item-defs`);

    return response;
  },
};

export default sponsorConroller;
