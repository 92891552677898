import * as Yup from 'yup';

export const INITIAL_VALUES = {
  name: '',
  image: '',
  value: 0,
};

export const FORM_VALIDATION_SCHEMA = Yup.object().shape({
  image: Yup.string().required('Image is required'),
  value: Yup.number().required('Value is required'),
  name: Yup.string().required('Name is required'),
});
