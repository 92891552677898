import React from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';

import useModal from 'src/hooks/useModal';
import sponsorConroller from 'src/services/leaderboard/sponsorConroller';
import Button from 'src/ui/Button';

import AddSponsorPrize from '../../modals/AddSponsorPrize';
import RowItem from '../RowItem';
import TableHeader from '../TableHeader';

import s from './style.module.scss';

const HEADER_LIST = [
  {
    id: 0,
    title: 'Prize',
  },
  {
    id: 1,
    title: 'Actions',
  },
];

interface ITablePanelPrizeProps {
  data: any;
}

const TablePanelPrize = ({ data }: ITablePanelPrizeProps) => {
  const { isOpen, openModal, closeModal } = useModal();
  const [editingPrize, setEditingPrize] = React.useState<any | null>(null);
  const queryClient = useQueryClient();
  const params = useParams();
  const sponsorId = params.id;

  const { mutate: deleteBlacklistItem } = useMutation({
    mutationFn: ({ sponsorId, id }: { sponsorId: string; id: string }) =>
      sponsorConroller.deleteSponsorPrize({ sponsorId, id }),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: ['sponsor-prize', sponsorId],
      });
      toast.success('Prize deleted successfully');
    },
    onError: (error: any) => {
      const errorMessage =
        error?.response?.data?.message || 'Error deleting prize';
      toast.error(errorMessage);
    },
  });

  const onEditBlacklistItem = (item: any) => {
    setEditingPrize(item);
    openModal();
  };

  return (
    <>
      <div className={s.panel}>
        <div className={s.panel__action}>
          <Button borderRadius="6px" onClick={openModal}>
            Add Prize
          </Button>
        </div>

        {data.length > 0 ? (
          <>
            <div className={s.panel__header}>
              {/* <Button onClick={openModal}>Add Prize</Button> */}
              <h4>Prizes</h4>
            </div>

            <TableHeader columns={HEADER_LIST} />
            <ul className={s.panel__table}>
              {data.map((item, index) => (
                <li>
                  <RowItem
                    item={item}
                    fields={[
                      {
                        key: 'prize',
                        render: (value) => (
                          <span>
                            {index + 1}. {value}
                          </span>
                        ),
                      },
                    ]}
                    actions={[
                      {
                        label: 'Edit',
                        onClick: (item) => onEditBlacklistItem(item),
                      },
                      {
                        id: 'delete',
                        label: 'Delete',
                        onClick: (item) =>
                          deleteBlacklistItem({
                            sponsorId: sponsorId!,
                            id: item.id,
                          }),
                      },
                    ]}
                  />
                </li>
              ))}
            </ul>
          </>
        ) : (
          <h4>Prizes is empty</h4>
        )}
      </div>

      <AddSponsorPrize
        sponsorId={sponsorId!}
        key={editingPrize ? editingPrize.id : 'no-id-prize'}
        editingPrize={editingPrize}
        open={isOpen}
        onClose={() => {
          closeModal();
          setEditingPrize(null);
        }}
      />
    </>
  );
};

export default TablePanelPrize;
