import React from 'react';

import { IRafflePublicWinner } from 'src/@types/raffles';

import s from './style.module.scss';

import { ReactComponent as WinnerMedalIcon } from 'src/assets/icons/winnerMedal.svg';

interface IWinnerCardProps {
  item: IRafflePublicWinner;
}

const WinnerCard = ({ item }: IWinnerCardProps) => {
  return (
    <div className={s.card}>
      <WinnerMedalIcon />
      <div className={s.card__avatar}>
        <img src={item.avatarUrl} alt="avatar" />
      </div>

      <div className={s.card__info}>
        <div title={item.accountName} className={s.card__name}>
          {item.accountName}
        </div>
        {item.joinedTimes > 0 && (
          <div className={s.card__ticket}>Tickets: {item.joinedTimes}</div>
        )}
      </div>
    </div>
  );
};

export default WinnerCard;
