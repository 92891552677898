import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useLocation, useNavigate } from 'react-router';
import { Element } from 'react-scroll';

import { ISponsor, ISponsorDefinition } from 'src/@types/leaderboard';
import SponsorTabs from 'src/components/leaderboard/SponsorTabs';
import SpinnerLoading from 'src/components/loadings/SpinnerLoading';
import PagePlaceholderText from 'src/components/PagePlaceholderText';
import SettingsModal from 'src/components/SettingsModal';
import CurrentTier from 'src/components/vip/CurrentTier';
import CurrentTierProgress from 'src/components/vip/CurrentTierProgress';
import TiersList from 'src/components/vip/TiersList';
import { getCurrentMonthInEST } from 'src/components/vip/utils';
import useModalManager from 'src/hooks/useModalManager';
import { routes } from 'src/routes';
import publicConroller from 'src/services/leaderboard/publicController';
import useAuthStore from 'src/store/authStore';

import 'src/assets/CSS/VIP.scss';
import s from './style.module.scss';

export const VIP = () => {
  const { isModalOpen, closeModal } = useModalManager();
  const { user, userSettings } = useAuthStore();
  const isUserLoggedIn = user !== null;
  const location = useLocation();
  const navigate = useNavigate();

  const {
    data: sponsorsData = [],
    isLoading: isFetchingSponsors,
    refetch: refetchSponsors,
  } = useQuery<ISponsor[]>({
    queryKey: ['sponsors'],
    queryFn: () => publicConroller.getSponsors(),
  });

  const currentSponsor = location.state?.sponsor;
  const sponsorId = location.state?.sponsor?.id;
  const isCanFetchSponsorDefinition = sponsorsData.length !== 0 && !!sponsorId;

  const {
    data: sponsorDefinitions = [],
    isLoading: isFetchingSponsorDefinition,
    refetch: refetchSponsorDefinition,
  } = useQuery<ISponsorDefinition[]>({
    queryKey: ['sponsors-definition', sponsorId],
    queryFn: () => {
      if (isCanFetchSponsorDefinition && isUserLoggedIn) {
        return publicConroller.getSponsorDefinitionsWithStatus({
          id: sponsorId,
        });
      } else if (sponsorsData.length !== 0 && !isUserLoggedIn) {
        return publicConroller.getSponsorDefinitions({
          id: sponsorId,
        });
      } else {
        return [];
      }
    },
    enabled: isCanFetchSponsorDefinition,
  });

  const onClickSponsor = (sponsor: ISponsor) => {
    const urlSponsorName = sponsor.name.replace(/\s+/g, '');
    navigate(routes.vipSponsor.replace(':id', urlSponsorName), {
      state: { sponsor },
    });
  };

  return (
    <div className="App-contents Challenges VIP">
      <div className={s.page}>
        {isFetchingSponsors ? (
          <div>Loading...</div>
        ) : (
          <SponsorTabs
            sponsorId={sponsorId}
            sponsors={sponsorsData}
            onClick={onClickSponsor}
          />
        )}
        <br />
        <br />
        <div className="Landing-heading">
          <h2 className="Main-heading Main-heading-larger">Wager Rewards</h2>

          <h2 className="Main-heading" style={{ margin: '0', padding: '0' }}>
            {currentSponsor?.name}
          </h2>
          <div className="Heading-divider" />

          <p className="Main-subheading">
            Wager to claim <b>{getCurrentMonthInEST()}&apos;s</b> rewards!
          </p>
        </div>

        {sponsorDefinitions.length > 0 && (
          <div className="Current-Tier-Container">
            {
              <CurrentTier
                user={user}
                sponsorDefinitions={sponsorDefinitions}
                currentSponsor={currentSponsor}
              />
            }
          </div>
        )}

        {sponsorDefinitions.length > 0 && isUserLoggedIn && (
          <CurrentTierProgress
            user={user}
            currentSponsor={currentSponsor}
            sponsorDefinitions={sponsorDefinitions}
          />
        )}

        {isFetchingSponsorDefinition ? (
          <SpinnerLoading />
        ) : sponsorDefinitions.length > 0 ? (
          <Element name="tiers">
            <TiersList
              onClaim={refetchSponsorDefinition}
              data={sponsorDefinitions}
              currency={currentSponsor?.currency || '$'}
            />
          </Element>
        ) : (
          <PagePlaceholderText text="Coming Soon" />
        )}
      </div>

      {user ? (
        <SettingsModal
          open={isModalOpen('settings')}
          onClose={() => closeModal('settings')}
          details={user}
        />
      ) : (
        <></>
      )}
    </div>
  );
};
