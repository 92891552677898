import React from 'react';
import { useNavigate } from 'react-router';

import { IRaffle } from 'src/@types/raffles';
import SpinnerLoading from 'src/components/loadings/SpinnerLoading';
import RaffleCard from 'src/components/raffles/RaffleCard';
import Pagination from 'src/ui/Pagination';

import s from './style.module.scss';

interface IRafflesBlockProps {
  id: string;
  title: string;
  data: { data: IRaffle[]; total: number } | undefined;
  isLoading: boolean;
  page: number;
  handleChangePage: (value: number) => void;
  itemsPerPage: number;
}

const RafflesBlock = ({
  id,
  title,
  data,
  isLoading,
  page,
  handleChangePage,
  itemsPerPage,
}: IRafflesBlockProps) => {
  const navigate = useNavigate();
  const currentUrl = `${window.location.origin}${window.location.pathname}${window.location.search}`;

  const onClickRaffles = (id: string) => {
    navigate(`/raffle/${id}`, { state: { from: currentUrl } });
  };

  return (
    <div className={s.rafflesBlock}>
      <h4 id={id} className={s.rafflesBlock__title}>
        {title}
      </h4>

      {isLoading && !data ? (
        <SpinnerLoading />
      ) : data?.total === 0 ? (
        <div className={s.rafflesBlock__notFound}>No Raffles</div>
      ) : (
        <>
          <div className={s.rafflesBlock__cardList}>
            {data?.data?.map((raffle) => (
              <RaffleCard
                key={raffle.id}
                item={raffle}
                onClick={() => onClickRaffles(raffle.id)}
              />
            ))}
          </div>

          {data && data.total > itemsPerPage && (
            <Pagination
              count={Math.ceil(data.total / itemsPerPage)}
              itemsPerPage={itemsPerPage}
              page={page}
              onChangePage={(e, value) => handleChangePage(value)}
            />
          )}
        </>
      )}
    </div>
  );
};

export default RafflesBlock;
