import React from 'react';
import cn from 'classnames';

import { ISponsor } from 'src/@types/leaderboard';
import Button from 'src/ui/Button';
import { createImageUrl } from 'src/utils/url';

import s from './style.module.scss';

interface ISponsorTabsProps {
  sponsorId: string;
  sponsors: ISponsor[];
  onClick: (sponsor: ISponsor) => void;
}

const SponsorTabs = ({ sponsorId, sponsors, onClick }: ISponsorTabsProps) => {
  //TODO: Need to show Shuffle sponsor first, in the future it should be via backend
  const filteredSponsors = [...sponsors].sort((a, b) => {
    if (a.name === 'Shuffle') return -1;
    if (b.name === 'Shuffle') return 1;
    return 0;
  });

  return (
    <div className={s.buttonGroup}>
      <div className={s.buttonGroup__content}>
        {filteredSponsors.map((sponsor) => (
          <Button
            onClick={() => onClick(sponsor)}
            className={cn(
              s.buttonGroup__button,
              sponsorId === sponsor.id && s.buttonGroup__active,
            )}
          >
            <div>
              {sponsor.logo ? (
                <div className={s.buttonGroup__title}>
                  <div className={s.buttonGroup__img}>
                    <img
                      src={createImageUrl(sponsor.logo?.url)}
                      alt="Sponsor Logo"
                    />
                  </div>
                  {sponsor.name === 'CasesGG' ? null : (
                    <div>{sponsor.name}</div>
                  )}
                </div>
              ) : (
                <div>{sponsor.name}</div>
              )}
            </div>
          </Button>
        ))}
      </div>
    </div>
  );
};

export default SponsorTabs;
