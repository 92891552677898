import React from 'react';
import cn from 'classnames';

import s from './style.module.scss';

interface IFiltersProps {
  tab: number;
  setTab: (tab: number) => void;
  isShowPreviousMounthTab: boolean;
}

const Filters = ({ tab, setTab, isShowPreviousMounthTab }: IFiltersProps) => {
  return (
    <div className={s.filter}>
      <div
        className={cn(s.filter__item, tab === 0 && s.filter_active)}
        onClick={() => setTab(0)}
      >
        Current Month
      </div>

      {isShowPreviousMounthTab ? (
        <div
          className={cn(s.filter__item, tab === 1 && s.filter_active)}
          onClick={() => setTab(1)}
        >
          Previous Month
        </div>
      ) : null}
    </div>
  );
};

export default Filters;
