import { axiosGetWithHeaders, axiosPut } from 'src/utils/httpClient';
// import { IGetRafflesResponse } from './playerService';
import { addQueryParams } from 'src/utils/url';

const moderateService = {
  async getPendingTransactions({
    page,
    size,
    name,
    sortColumn,
    sortOrder,
  }: {
    page: number;
    size: number;
    name?: string;
    sortColumn?: string | null;
    sortOrder?: string;
  }): Promise<any> {
    const url = `/moderate/pending-transactions-pageable`;

    let formedUrl = addQueryParams(url, {
      page,
      size,
      username: name,
    });

    if (sortColumn && sortOrder) {
      formedUrl += `&sort=${sortColumn},${sortOrder}`;
    }

    const response = await axiosGetWithHeaders(formedUrl);

    return {
      data: response.data,
      total: parseInt(response.headers['x-total-count'], 10),
    };
  },

  async getShuffleVerification({
    page,
    size,
    name,
    sortColumn,
    sortOrder,
  }: {
    page: number;
    size: number;
    name?: string;
    sortColumn?: string | null;
    sortOrder?: string;
  }): Promise<any> {
    const url = `/moderate/pending-shuffle-verification-pageable`;

    let formedUrl = addQueryParams(url, {
      page,
      size,
      username: name,
    });

    if (sortColumn && sortOrder) {
      formedUrl += `&sort=${sortColumn},${sortOrder}`;
    }

    const response = await axiosGetWithHeaders(formedUrl);

    return {
      data: response.data,
      total: parseInt(response.headers['x-total-count'], 10),
    };
  },

  async getPlayers({
    page,
    size,
    name,
    sortColumn,
    sortOrder,
  }: {
    page: number;
    size: number;
    name?: string;
    sortColumn?: string | null;
    sortOrder?: string;
  }): Promise<any> {
    const url = `/admin/players-pageable`;

    let formedUrl = addQueryParams(url, {
      page,
      size,
      name,
    });

    if (sortColumn && sortOrder) {
      formedUrl += `&sort=${sortColumn},${sortOrder}`;
    }

    console.log('formedUrl', formedUrl);

    const response = await axiosGetWithHeaders(formedUrl);

    return {
      data: response.data,
      total: parseInt(response.headers['x-total-count'], 10),
    };
  },

  async resetShuffleNameVerification({ id }: { id: string }): Promise<any> {
    const url = `/moderate/reset-shuffle`;

    const response = await axiosPut(url, { uuid: id });

    return response;
  },
};

export default moderateService;
