import React from 'react';

import s from './style.module.scss';

interface IPagePlaceholderTextProps {
  text: string;
}

const PagePlaceholderText = ({ text }: IPagePlaceholderTextProps) => {
  return <div className={s.comingSoon}>{text}</div>;
};

export default PagePlaceholderText;
