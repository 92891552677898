import React from 'react';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { Link } from 'react-router';

import s from './style.module.scss';

interface ISponsorItem {
  id: number;
  icon: React.ReactNode;
  name: string;
  codes: { title: string; link: string }[];
  bonuses?: string[];
}

interface ISponsorCardProps {
  item: ISponsorItem;
}

const SponsorCard = ({ item }: ISponsorCardProps) => {
  return (
    <div className={s.card}>
      {item.icon}

      <ul className={s.card__codes}>
        {item.codes.map((code) => (
          <li className={s.code}>
            <div>
              <span>Code:</span>{' '}
              <Link className={s.code__link} to={code.link}>
                {code.title}
              </Link>
            </div>
          </li>
        ))}
      </ul>

      {item.bonuses && (
        <ul className={s.card__bonuses}>
          {item.bonuses.map((bonus) => (
            <li className={s.bonus}>
              <CheckCircleOutlineOutlinedIcon className={s.bonus__icon} />
              {bonus}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SponsorCard;
