import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useLocation, useParams, useSearchParams } from 'react-router';
import { toast } from 'react-toastify';
import { useDebounce } from 'use-debounce';

import SpinnerLoading from 'src/components/loadings/SpinnerLoading';
import { REFETCHING_TIME } from 'src/constants/fetching';
import useModalManager from 'src/hooks/useModalManager';
import sponsorConroller from 'src/services/leaderboard/sponsorConroller';
import Button from 'src/ui/Button';
import Input from 'src/ui/Input';
import Modal from 'src/ui/Modal';
import Pagination from 'src/ui/Pagination';
import { numberWithCommas } from 'src/utils/utils';

import AbuseSponsorWagered from '../../modals/AbuseSponsorWagered';
import ClearSponsorAbusesModal from '../../modals/ClearSponsorAbusesModal';
import EditSponsorWagered from '../../modals/EditSponsorWagered';
import RowItem from '../RowItem';
import TableHeader from '../TableHeader';

import s from './style.module.scss';

interface ITablePanelUsersProps {
  data: any;
  total: number;
}

const HEADER_LIST = [
  {
    id: 0,
    title: 'Name',
    column: 'username',
    isSortable: false,
  },
  {
    id: 1,
    title: 'Wagered',
    column: 'totalWagered',
    isSortable: false,
  },
  {
    id: 3,
    title: 'Modify Wagered',
    column: 'modifyWagered',
    isSortable: false,
  },
  {
    id: 4,
    title: 'Wager Abuse Coefficient',
    column: 'abuseCoefficient',
    isSortable: false,
  },
  {
    id: 5,
    title: 'Actions',
  },
];

const TablePanelUsers = () => {
  const { isModalOpen, openModal, closeModal } = useModalManager();
  const [editingItem, setEditingItem] = React.useState<any | null>(null);
  const itemsPerPage = 30;
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = Number(searchParams.get('page'));
  const userSearch = searchParams.get('userSearch');
  // const sortColumn = searchParams.get('sortColumn');
  // const sortOrder = searchParams.get('sortOrder') || 'asc';
  const queryClient = useQueryClient();
  const params = useParams();

  const [search, setSearch] = useState('');
  const [searchUserName] = useDebounce(search, 1000);

  const sponsorId = params.id!;

  const {
    data: sponsorUsersData = { data: [], total: 0 },
    isLoading: isFetchingSponsorUsers,
    refetch: refetchSponsorUsers,
    isFetched: isFetchedSponsorUsers,
  } = useQuery({
    queryKey: [
      'sponsor-users',
      sponsorId,
      userSearch,
      page,
      // sortColumn,
      // sortOrder,
    ],
    queryFn: () =>
      sponsorConroller.getSponsorsUsers({
        id: sponsorId,
        page: page === 0 ? page : page - 1,
        size: 30,
        name: userSearch,
        // sortColumn,
        // sortOrder,
      }),
    refetchInterval: REFETCHING_TIME,
    placeholderData: (previousData) => previousData,
    enabled: Boolean(sponsorId),
  });

  const { mutate: clearAllAbuses } = useMutation({
    mutationFn: () => sponsorConroller.clearAbuseAmounts({ id: sponsorId }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['sponsor-users', sponsorId] });
      toast.success('Abuse coefficients cleared successfully');
      closeModal('sponsor-clear-abuses');
    },
  });

  const onEditEditWagered = (item: any) => {
    openModal('edit-wagered');
    setEditingItem(item);
  };

  const onAbuseWagered = (item: any) => {
    openModal('abuse-wagered');
    setEditingItem(item);
  };

  const handleChangePage = (value: number) => {
    setSearchParams((prev) => {
      prev.set('page', value.toString());
      return prev;
    });
  };

  const calculateUserPosition = (index: number) => {
    return (page - 1) * itemsPerPage + index + 1;
  };

  const onClearAllAbuses = () => {
    if (sponsorId) {
      clearAllAbuses();
    }
  };

  useEffect(() => {
    if (!searchParams.has('page')) {
      setSearchParams((prev) => {
        prev.set('page', '1');
        return prev;
      });
    }
  }, [location]);

  useEffect(() => {
    if (searchUserName !== '') {
      setSearchParams((prev) => {
        prev.set('userSearch', searchUserName);
        prev.set('page', '1');
        return prev;
      });
    }

    if (searchUserName === '' && searchParams.has('userSearch')) {
      setSearchParams((prev) => {
        prev.delete('userSearch');
        prev.set('page', '1');
        return prev;
      });
    }
  }, [searchUserName]);

  return (
    <>
      <div className={s.panel}>
        <div className={s.panel__action}>
          <Button
            borderRadius="6px"
            color="#C60000"
            onClick={() => openModal('sponsor-clear-abuses')}
          >
            Clear All Abuse Coefficients
          </Button>
        </div>

        <div>
          <div className={s.panel__header}>
            <h4>Users</h4>
          </div>

          {isFetchingSponsorUsers || !sponsorId ? (
            <SpinnerLoading />
          ) : (
            <>
              <div style={{ maxWidth: '350px' }}>
                <Input
                  placeholder="Search"
                  // className={s.raffle__search}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>

              {sponsorUsersData.data.length > 0 ? (
                <div className={s.panel__content}>
                  <div>
                    <TableHeader columns={HEADER_LIST} />
                    <ul className={s.panel__table}>
                      {sponsorUsersData.data.map((item, index) => (
                        <li>
                          <RowItem
                            item={item}
                            fields={[
                              {
                                key: 'username',
                                render: (value) => (
                                  <span>
                                    {calculateUserPosition(index)} {value}
                                  </span>
                                ),
                              },

                              {
                                key: 'totalWagered',
                                render: (value) => (
                                  <span>{numberWithCommas(value)}</span>
                                ),
                              },

                              {
                                key: 'modifyWagered',
                                render: (value) => (
                                  <span>
                                    {value ? numberWithCommas(value, 1) : '-'}
                                  </span>
                                ),
                              },

                              {
                                key: 'abuseCoefficient',
                                render: (value) => (
                                  <span>
                                    {value
                                      ? numberWithCommas(value, 1)
                                      : numberWithCommas(1, 1)}
                                  </span>
                                ),
                              },
                            ]}
                            actions={[
                              {
                                label: 'Edit',
                                onClick: (item) => onEditEditWagered(item),
                              },
                              {
                                id: 'delete',
                                label: 'Abuse',
                                onClick: (item) => onAbuseWagered(item),
                              },
                            ]}
                          />
                        </li>
                      ))}
                    </ul>
                  </div>

                  {sponsorUsersData.data &&
                    sponsorUsersData.total > itemsPerPage && (
                      <Pagination
                        itemsPerPage={itemsPerPage}
                        count={Math.ceil(sponsorUsersData.total / itemsPerPage)}
                        page={page}
                        onChangePage={(e, value) => handleChangePage(value)}
                      />
                    )}
                </div>
              ) : (
                <h4>There are no users</h4>
              )}
            </>
          )}
        </div>
      </div>

      <Modal
        maxWidth="xs"
        open={isModalOpen('sponsor-clear-abuses')}
        handleClose={() => closeModal('sponsor-clear-abuses')}
      >
        <ClearSponsorAbusesModal
          onClose={() => closeModal('sponsor-clear-abuses')}
          onDelete={onClearAllAbuses}
        />
      </Modal>

      <AbuseSponsorWagered
        sponsorId={sponsorId}
        // key={editingPrize ? editingPrize.id : 'no-id-prize'}
        editingItem={editingItem}
        open={isModalOpen('abuse-wagered')}
        onClose={() => {
          closeModal('abuse-wagered');
          setEditingItem(null);
        }}
      />

      <EditSponsorWagered
        sponsorId={sponsorId}
        // key={editingPrize ? editingPrize.id : 'no-id-prize'}
        editingItem={editingItem}
        open={isModalOpen('edit-wagered')}
        onClose={() => {
          closeModal('edit-wagered');
          setEditingItem(null);
        }}
      />
    </>
  );
};

export default TablePanelUsers;
