import React, { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useSearchParams } from 'react-router';
import { useLocation } from 'react-router';

import { RaffleStatus, RaffleType } from 'src/@types/raffles';
import About from 'src/components/raffles/About';
import Hero from 'src/components/raffles/Hero';
import HowToEarnWpoints from 'src/components/raffles/HowToEarnWpoints';
import RafflesBlock from 'src/components/raffles/RafflesBlock';
import { REFETCHING_TIME } from 'src/constants/fetching';
import playerService from 'src/services/playerService';

import s from './style.module.scss';

const Raffles: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const itemsPerPage = 6;

  const getPage = (param: string, defaultPage: number) =>
    Number(searchParams.get(param)) || defaultPage;

  const page = getPage('page', 1);
  const previousRafflesPage = getPage('previousRafflesPage', 1);
  const upcomingRafflesPage = getPage('upcomingRafflesPage', 1);

  const fetchRaffles = (status: RaffleStatus, page: number) =>
    playerService.getRaffles({
      type: RaffleType.RAFFLE,
      status,
      page: page === 0 ? page : page - 1,
      size: itemsPerPage,
    });

  const { data, isLoading } = useQuery({
    queryKey: ['publicCurrentRaffles', page],
    queryFn: () => fetchRaffles(RaffleStatus.ACTIVE, page),
    placeholderData: (previousData) => previousData,
    refetchInterval: REFETCHING_TIME,
    staleTime: REFETCHING_TIME,
  });

  const { data: dataUpcoming, isLoading: isLoadingUpcoming } = useQuery({
    queryKey: ['publicUpcomingRaffles', upcomingRafflesPage],
    queryFn: () => fetchRaffles(RaffleStatus.UPCOMING, upcomingRafflesPage),
    placeholderData: (previousData) => previousData,
    refetchInterval: REFETCHING_TIME,
    staleTime: REFETCHING_TIME,
  });

  const { data: previousRaffles, isLoading: isLoadingPreviousRaffles } =
    useQuery({
      queryKey: ['previousRaffles', previousRafflesPage],
      queryFn: () => fetchRaffles(RaffleStatus.COMPLETED, previousRafflesPage),
      placeholderData: (previousData) => previousData,
      refetchInterval: REFETCHING_TIME,
      staleTime: REFETCHING_TIME,
    });

  const handleChangePage = (
    value: number,
    key: 'page' | 'previousRafflesPage' | 'upcomingRafflesPage',
  ) => {
    setSearchParams(
      (prev) => {
        prev.set(key, value.toString());
        return prev;
      },
      { replace: true },
    );
  };

  useEffect(() => {
    setDefaultQueries();
  }, [location]);

  const setDefaultQueries = () => {
    if (!searchParams.has('page')) {
      setDefaultPage('page', page);
    }

    if (!searchParams.has('upcomingRafflesPage')) {
      setDefaultPage('upcomingRafflesPage', upcomingRafflesPage);
    }

    if (!searchParams.has('previousRafflesPage')) {
      setDefaultPage('previousRafflesPage', previousRafflesPage);
    }
  };

  const setDefaultPage = (key: string, value: number) => {
    setSearchParams(
      (prev) => {
        prev.set(key, value.toString());
        return prev;
      },
      { replace: true },
    );
  };

  return (
    <div className={s.raffles}>
      <Hero />

      <div className={s.raffles__container}>
        <div className="container">
          <div className={s.raffles__content}>
            <About />

            <RafflesBlock
              id="currentRaffles"
              title={'Current raffles'}
              data={data}
              isLoading={isLoading}
              page={page}
              handleChangePage={(page: number) =>
                handleChangePage(page, 'page')
              }
              itemsPerPage={itemsPerPage}
            />

            <RafflesBlock
              id="upcomingRaffles"
              title={'Upcoming raffles'}
              data={dataUpcoming}
              isLoading={isLoadingUpcoming}
              page={upcomingRafflesPage}
              handleChangePage={(page: number) =>
                handleChangePage(page, 'upcomingRafflesPage')
              }
              itemsPerPage={itemsPerPage}
            />

            <RafflesBlock
              id="previousRaffles"
              title={'Previous raffles'}
              data={previousRaffles}
              isLoading={isLoadingPreviousRaffles}
              page={previousRafflesPage}
              handleChangePage={(page: number) =>
                handleChangePage(page, 'previousRafflesPage')
              }
              itemsPerPage={itemsPerPage}
            />
          </div>
        </div>
      </div>

      <div className={s.raffles__content}>
        <HowToEarnWpoints />
      </div>
    </div>
  );
};

export default Raffles;
