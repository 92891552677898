import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface IAuthStore {
  user: any;
  userSettings: any;
  isAuthorise: boolean;
  setUser(user: any): void;
  setUserSettings(userSettings: any): void;
  updateField(field: string, value: any): void;
}

const useAuthStore = create(
  devtools<IAuthStore>((set) => ({
    user: null,
    userSettings: null,
    isAuthorise: false,

    updateField: (field: string, value: any) =>
      set((state) => ({ ...state, [field]: value })),
    setUser: (user: any) => set({ user }),
    setUserSettings: (userSettings: any) => set({ userSettings }),
  })),
);

export default useAuthStore;
