export const VIP_TIERS = [
  {
    name: 'Zero',
    req: 1,
    rew: 'No Reward',
    // ico: '/vip-img/btcs-logo.png',
    ico: '/vip-img/logo.svg',
    achieved: false,
  },
  {
    name: 'Wood',
    req: 10000,
    rew: '$20 Reward',
    ico: '/vip-img/wood.svg',
    achieved: false,
  },
  {
    name: 'Iron',
    req: 25000,
    rew: '$50 Reward',
    ico: '/vip-img/ironcointier.svg',
    achieved: false,
  },
  {
    name: 'Bronze',
    req: 50000,
    rew: '$100 Reward',
    ico: '/vip-img/bronze.svg',
    achieved: false,
  },
  {
    name: 'Silver',
    req: 100000,
    rew: '$2000 Reward',
    ico: '/vip-img/silver.svg',
    achieved: false,
  },
  {
    name: 'Gold',
    req: 250000,
    rew: '$500 Reward',
    ico: '/vip-img/gold.svg',
    achieved: false,
  },
  {
    name: 'Jade',
    req: 500000,
    rew: '$1,000 Reward',
    ico: '/vip-img/jade.svg',
    achieved: false,
  },
  {
    name: 'Sapphire',
    req: 1000000,
    rew: '$2,000 Reward',
    ico: '/vip-img/sapphire.svg',
    achieved: false,
  },
  {
    name: 'Emerald',
    req: 2500000,
    rew: '$5,000 Reward',
    ico: '/vip-img/emerald.svg',
    achieved: false,
  },
  {
    name: 'Ruby',
    req: 5000000,
    rew: '$10,000 Reward',
    ico: '/vip-img/ruby.svg',
    achieved: false,
  },
  {
    name: 'Obsidian',
    req: 10000000,
    rew: '$20,000 Reward',
    ico: '/vip-img/obsidian.svg',
    achieved: false,
  },
  {
    name: 'Amethyst',
    req: 50000000,
    rew: '$100,000 Reward',
    ico: '/vip-img/amethystIcon.svg',
    achieved: false,
  },
];
