import React from 'react';

import s from './style.module.scss';

const PageLoading = () => {
  return (
    <div className="container">
      <div className="wrapper">
        <div className={s.page}>
          <div className={s.page__loading}>
            <img width={100} height={100} src="/btcs-logo.png" alt="logo" />
            <div>Loading</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageLoading;
