import React from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';

import useModal from 'src/hooks/useModal';
import sponsorConroller from 'src/services/leaderboard/sponsorConroller';
import Button from 'src/ui/Button';

import AddSponsorBlacklist from '../../modals/AddSponsorBlacklist';
import RowItem from '../RowItem';
import TableHeader from '../TableHeader';

import s from './style.module.scss';

const HEADER_LIST = [
  {
    id: 0,
    title: 'Name',
    isSortable: false,
  },
  {
    id: 1,
    title: 'Actions',
    column: 'actions',
    isSortable: false,
  },
];

interface ITablePanelBlacklistProps {
  data: any;
}

const TablePanelBlacklist = ({ data }: ITablePanelBlacklistProps) => {
  const { isOpen, openModal, closeModal } = useModal();
  const [editingBlacklistItem, setEditingBlacklistItem] = React.useState<
    any | null
  >(null);
  const queryClient = useQueryClient();
  const params = useParams();
  const sponsorId = params.id;

  const { mutate: deleteBlacklistItem } = useMutation({
    mutationFn: ({ sponsorId, id }: { sponsorId: string; id: string }) =>
      sponsorConroller.deleteSponsorBlacklist({ sponsorId, id }),
    onSuccess: (data, variables) => {
      //FIX: refactorn, update only one item in the list
      queryClient.invalidateQueries({
        queryKey: ['sponsor-blacklist', sponsorId],
      });
      toast.success('Blacklist item deleted successfully');
    },
    onError: (error: any) => {
      const errorMessage =
        error?.response?.data?.message || 'Error deleting item';
      toast.error(errorMessage);
    },
  });

  const onEditBlacklistItem = (item: any) => {
    setEditingBlacklistItem(item);
    openModal();
  };

  return (
    <>
      <div className={s.panel}>
        <div className={s.panel__action}>
          <Button borderRadius="6px" onClick={openModal}>
            Add To Blacklist
          </Button>
        </div>

        {data.length > 0 ? (
          <>
            <div className={s.panel__header}>
              <h4>Blacklist</h4>
            </div>

            <TableHeader columns={HEADER_LIST} />
            <ul className={s.panel__table}>
              {data.map((item) => (
                <li>
                  <RowItem
                    item={item}
                    fields={[
                      {
                        key: 'username',
                        render: (value) => <span>{value}</span>,
                      },
                    ]}
                    actions={[
                      {
                        label: 'Edit',
                        onClick: (item) => onEditBlacklistItem(item),
                        // icon: <EditIcon />,
                      },
                      {
                        id: 'delete',
                        label: 'Delete',
                        onClick: (item) =>
                          deleteBlacklistItem({
                            sponsorId: sponsorId!,
                            id: item.id,
                          }),
                        // icon: TrashIcon,
                      },
                    ]}
                  />
                </li>
              ))}
            </ul>
          </>
        ) : (
          <h4>Blacklist is empty</h4>
        )}
      </div>

      <AddSponsorBlacklist
        sponsorId={sponsorId!}
        editingItem={editingBlacklistItem}
        key={editingBlacklistItem ? editingBlacklistItem.id : 'no-id-prize'}
        open={isOpen}
        onClose={() => {
          closeModal();
          setEditingBlacklistItem(null);
        }}
      />
    </>
  );
};

export default TablePanelBlacklist;
