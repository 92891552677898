import React, { useEffect, useState } from 'react';

import AddHighlightModal from 'src/components/Admin/modals/AddHighlightModal';
import { formatDateYearTime } from 'src/components/Crates';
import { HighlightClip } from 'src/types';
import Button from 'src/ui/Button';
import { axiosGet, axiosPut } from 'src/utils/httpClient';

import 'src/assets/CSS/Admin.scss';

const HighlightsAdmin = () => {
  const getHighlights = async (): Promise<Array<HighlightClip>> =>
    axiosGet(`/moderate/highlights`);
  const deleteHighlight = async (id: string): Promise<Array<HighlightClip>> =>
    axiosPut(`/moderate/delete-highlight`, { uuid: id });

  const [highlights, setHighlights] = useState<Array<HighlightClip>>([]);
  const [editing, setEditing] = useState<HighlightClip | undefined>(undefined);
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
    setEditing(undefined);
  };

  useEffect(() => {
    getHighlights().then((response: Array<HighlightClip>) => {
      setHighlights(response);
    });
  }, []);

  return (
    <div>
      <div className="Section-heading">
        <h4>Highlights</h4>
        <Button
          customStyle={{
            padding: '5px',
            borderRadius: '6px',
            fontSize: '16px',
          }}
          onClick={() => setOpen(true)}
        >
          Add Highlight
        </Button>
      </div>

      <div className="Btcs-table">
        <ul className="Header">
          <li>Name</li>
          <li>Date</li>
          <li>Action</li>
        </ul>
        {highlights.map((highlight) => {
          return (
            <ul className="Entry">
              <li>{highlight.title}</li>
              <li>{formatDateYearTime(highlight.creationDate)}</li>
              <li className="Actions">
                <button
                  onClick={() => {
                    setEditing(highlight);
                    setOpen(true);
                  }}
                >
                  Edit
                </button>

                <button
                  onClick={() => {
                    deleteHighlight(highlight.id).then((r) => {
                      setHighlights(r);
                    });
                  }}
                >
                  Delete
                </button>
              </li>
            </ul>
          );
        })}
      </div>

      <AddHighlightModal
        key={editing ? editing.id : 'no-id-highlight'}
        open={open}
        onClose={handleClose}
        editing={editing}
        setItems={setHighlights}
      />
    </div>
  );
};

export default HighlightsAdmin;
