import React from 'react';
import { useState } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Outlet, ScrollRestoration } from 'react-router';
import { Slide, ToastContainer } from 'react-toastify';

import Footer from 'src/components/Footer';
import { Header } from 'src/components/Header';
import MenuBurger from 'src/components/MenuBurger';
import AuthProvider from 'src/providers/AuthProvider';

import 'src/assets/CSS/null.scss';
import 'src/assets/CSS/App.scss';
import 'src/assets/CSS/App.responsive.scss';
import 'src/assets/CSS/global.scss';

// TODO: Remove this when the issue with ReactQueryDevtools will be fixed
window.ResizeObserver = class {
  observe() {}
  unobserve() {}
  disconnect() {}
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});

const AppLayout = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <div className="App">
          <AuthProvider>
            <div className="App-header-container">
              <Header onToggleMenu={(value: boolean) => setMenuOpen(value)} />
            </div>

            <ScrollRestoration
              getKey={(location) => {
                return location.pathname;
              }}
            />
            <Outlet />

            <MenuBurger
              isOpen={isMenuOpen}
              onClose={() => setMenuOpen(false)}
            />
            <Footer />
          </AuthProvider>
        </div>

        <ToastContainer
          position="bottom-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          transition={Slide}
        />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </>
  );
};

export default AppLayout;
