import { IRafflePublicPlayer } from 'src/@types/raffles';
import EntryItem from 'src/components/raffles/EntryItem';
import Pagination from 'src/ui/Pagination';

import s from './style.module.scss';

interface IRaffleParticipantsSectionProps {
  isLoading: boolean;
  playersData: { data: IRafflePublicPlayer[]; total: number } | undefined;
  itemsPerPage: number;
  playersPage: number;
  handleChangePage: (value: number) => void;
}

const RaffleParticipantsSection = ({
  isLoading,
  playersData,
  itemsPerPage,
  playersPage,
  handleChangePage,
}: IRaffleParticipantsSectionProps) => {
  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className={s.entries}>
      <div className={s.entries__head}>
        <h4 className={s.entries__title}>
          Entries <span>({playersData?.total})</span>
        </h4>
      </div>
      {playersData?.total === 0 ? (
        <div className={s.raffle__empty}>
          <h4>No Participants Yet</h4>
        </div>
      ) : (
        <div className={s.entries__content}>
          <ul className={s.entries__list}>
            {playersData?.data?.map((item) => (
              <li key={item.id}>
                <EntryItem item={item} />
              </li>
            ))}
          </ul>

          {playersData && playersData?.total > itemsPerPage && (
            <Pagination
              itemsPerPage={itemsPerPage}
              count={Math.ceil(playersData?.total / itemsPerPage)}
              page={playersPage}
              onChangePage={(e, value) => handleChangePage(value)}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default RaffleParticipantsSection;
