import { axiosGet, axiosPut } from 'src/utils/httpClient';
// import { IGetRafflesResponse } from './playerService';

const publicConroller = {
  async getSponsors(): Promise<any> {
    const response = await axiosGet(`/public/sponsors`);
    return response?.filter((sponsor) => sponsor.name !== 'CasesGG');
  },

  async getSponsorDefinitionsWithStatus({ id }: { id: string }): Promise<any> {
    const response = await axiosGet(
      `/public/sponsor/${id}/item-definitions-status`,
    );

    return response;
  },

  async getSponsorDefinitions({ id }: { id: string }): Promise<any> {
    const response = await axiosGet(`/public/sponsor/${id}/item-definitions`);

    return response;
  },

  async getSponsorVipUsers({ id }) {
    const response = await axiosGet(`/public/vip-users/${id}`);

    return response;
  },

  async getSponsorSnapshot({ id, date }) {
    const response = await axiosGet(
      `/general/snapshots?sponsorId=${id}&date=${date}&page=0&size=50`,
    );

    return response;
  },

  async claimReward({ sponsorId, tier }) {
    const response = await axiosPut(
      `/player/claim-vip/${tier}/sponsor/${sponsorId}`,
    );

    return response;
  },
};

export default publicConroller;
