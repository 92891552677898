import React, { useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
// import {makeStyles} from "@material-ui/core/styles";
import { makeStyles } from '@mui/styles';
import { useQuery } from '@tanstack/react-query';

import { AchievementsTable } from 'src/components/AchievementsTable';
import { PlayerItems } from 'src/components/PlayerItems';
import { formatSecondsToTime } from 'src/utils/time';

import { PlayerProfileApi } from '../types';
import { axiosGet } from '../utils/httpClient';
import { MenuProps } from './Hunts';

import '../assets/CSS/Profile.scss';
import '../assets/CSS/Profile.responsive.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      // margin: theme.spacing(1),
      width: '25ch',
    },
  },
  gridList: {
    width: '100%',
    height: 'auto',
  },
  card: {
    minWidth: 100,
    maxHeight: 100,
    height: '100%',
    backgroundColor: 'transparent !important',
    margin: '15px',
    border: 'none',
    boxShadow: 'none',
  },
  cardContent: {
    backgroundSize: 'cover',
    backgroundColor: '#080808',
    height: '56px',
    border: '2px solid #2a2e38',
    textAlign: 'left',
    fontSize: '11px !important',
    color: '#cecece',
  },
  select: {
    '&:before': {
      borderColor: 'white !important',
    },
    '&:after': {
      borderColor: 'white !important',
    },
    '&:not(.Mui-disabled):hover::before': {
      borderColor: 'white !important',
    },
    color: 'white !important',
    background: '#1b1d27 !important',
  },
  icon: {
    fill: 'white !important',
  },
  accordion: {
    background: 'linear-gradient(180deg, #041128, #1e1826) !important',
    borderRadius: '10px',
    color: 'white !important',
  },
}));

const getProfileDetails = async (): Promise<PlayerProfileApi> =>
  axiosGet(`/player/profile`);

export const PlayerProfile = () => {
  const classes = useStyles();
  const { data: defs, isLoading: isLoadingDefs } = useQuery({
    queryKey: ['item-defs'],
    queryFn: () => axiosGet('/general/item-defs'),
  });

  const [profile, setProfile] = useState<PlayerProfileApi | undefined>(
    undefined,
  );
  const [streamerFilter, setStreamerFilter] = useState<string>('This Month');

  useEffect(() => {
    getProfileDetails()
      .then((prof: PlayerProfileApi) => {
        setProfile(prof);
      })
      .catch((e) => {
        //@ts-ignore
        // window.location.href = '/';
      });
  }, []);

  if (!profile) {
    return <div className="App-contents" />;
  }

  if (isLoadingDefs) {
    return <div>Loading...</div>;
  }

  const streamers = ['This Month'];

  const handleStreamerChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setStreamerFilter(value);
  };

  if (profile.items) {
    profile.items.map((i) => {
      const def = defs?.find((d) => d.id == i.item);
      i.name = def?.name ?? 'N/A';
    });
  }

  return (
    <>
      <div className="App-contents">
        <div className="Profile-general To-Row">
          <div className="Account-settings-container Account-settings-container-half">
            <div className="Landing-heading Landing-heading-left">
              <h4>General</h4>
            </div>
            <div className="Player-overview Player-overview-full">
              <ul>
                <li>Username</li>
                <li>{profile ? profile.username : '-'}</li>
              </ul>
              <ul>
                <li>Stream Watch Time</li>
                <li>{formatSecondsToTime(profile?.watchTime ?? 0)}</li>
              </ul>
              <ul>
                <li>Challenges Completed</li>
                <li>
                  {profile ? profile.challenges : '-'} /{' '}
                  {profile ? profile.totalChallenges : '-'}
                </li>
              </ul>
              <ul>
                <li>Wager Rewards Earned</li>
                <li>
                  {profile ? profile.vip : '-'} /{' '}
                  {profile ? profile.totalVip : '-'}
                </li>
              </ul>
              <ul>
                <li>Balance</li>
                <li>
                  <div className="Balance">
                    <img src="/currency.svg" />
                    <div className="Points-value">
                      {profile ? profile.balance.toLocaleString('en-US') : '-'}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div className="Account-settings-container Account-settings-container-half">
            <div className="Landing-heading Landing-heading-left">
              <h4>Achievements</h4>
              <FormControl sx={{ m: 1, width: 300 }}>
                <InputLabel
                  id="demo-multiple-checkbox-label"
                  sx={{ color: 'white' }}
                >
                  Month
                </InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  className={classes.select}
                  inputProps={{
                    classes: {
                      icon: classes.icon,
                      root: classes.root,
                    },
                  }}
                  id="demo-multiple-checkbox"
                  value={streamerFilter}
                  onChange={handleStreamerChange}
                  sx={{
                    color: 'white',
                    '.MuiOutlinedInput-notchedOutline': {
                      borderColor: '#2a2e38',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#2a2e38',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#2a2e38',
                    },
                    '.MuiSvgIcon-root ': {
                      fill: 'white !important',
                    },
                  }}
                  input={
                    <OutlinedInput sx={{ color: 'white' }} label="Streamer" />
                  }
                  renderValue={(selected) => selected}
                  MenuProps={MenuProps}
                >
                  {streamers.map((variant) => (
                    //@ts-ignore
                    <MenuItem key={variant.id} value={variant}>
                      <ListItemText primary={variant} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <AchievementsTable
              defs={defs}
              rows={profile ? profile.achievements : []}
            />
          </div>
        </div>

        <div
          className="Account-settings-container"
          style={{ width: 'calc(100% - 20px)' }}
        >
          <div className="Landing-heading Landing-heading-left">
            <h4>Items</h4>
          </div>
          <PlayerItems defs={defs} rows={profile ? profile.items : []} />
        </div>
      </div>
    </>
  );
};
