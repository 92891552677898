import React, { useEffect, useState } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import Dialog from '@mui/material/Dialog';

import { ModalProps } from 'src/layouts/AdminLayout';
import {
  Challenge,
  CreateChallengeRequest,
  EditChallengeRequest,
} from 'src/types';
import { axiosPost, axiosPut } from 'src/utils/httpClient';

import 'src/assets/CSS/Admin.scss';
export const dialogStyles = {
  backgroundColor: '#1a1d23',
  color: 'white',
  padding: '25px',
  boxShadow: 'none',
};

export type ChallengeModalProps = {
  editing?: Challenge;
  onSuccess: any;
} & ModalProps;

function AddChallengeModal(props: ChallengeModalProps) {
  const { onClose, open, onSuccess } = props;

  const [name, setName] = useState<string | undefined>(undefined);
  const [image, setImage] = useState<string | undefined>(undefined);
  const [multiplier, setMultiplier] = useState<number | undefined>(undefined);
  const [wager, setWager] = useState<number | undefined>(undefined);
  const [tip, setTip] = useState<number | undefined>(undefined);
  const [end, setEnd] = useState<string | undefined>(undefined);
  const [timed, setTimed] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const createChallenge = async (
    request: CreateChallengeRequest,
  ): Promise<Array<Challenge>> => axiosPost(`/admin/create-challenge`, request);
  const editChallenge = async (
    request: EditChallengeRequest,
  ): Promise<Array<Challenge>> => axiosPut(`/admin/edit-challenge`, request);

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    if (props.editing) {
      setName(props.editing.name);
      setImage(props.editing.image);
      setMultiplier(props.editing.targetMultiplier);
      setWager(props.editing.minimumWagerRequired);
      setEnd(props.editing.endDate);
      setTip(props.editing.reward);
      setTimed(props.editing.timed);
    }
  }, []);

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      PaperProps={{ style: dialogStyles }}
    >
      <h2>{props.editing ? 'Edit' : 'Create'} Shuffle Challenge</h2>
      <div className="Btcs-form">
        <div className="Form-input-field">
          <h6>Challenge Name</h6>
          <div className="Form-input">
            <div className="Form-icon">
              <img src="/profile/name.png" />
            </div>
            <input
              type="text"
              placeholder="Challenge Name..."
              defaultValue={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="Form-input-field">
          <h6>Challenge Image</h6>
          <div className="Form-input">
            <div className="Form-icon">
              <img src="/profile/img.png" />
            </div>
            <input
              type="text"
              placeholder="Challenge Image..."
              defaultValue={image}
              onChange={(e) => {
                setImage(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="Form-input-field">
          <h6>Hit (x)</h6>
          <div className="Form-input">
            <div className="Form-icon">
              <img src="/profile/multiply.png" />
            </div>
            <input
              type="number"
              placeholder="Multiplier"
              defaultValue={multiplier}
              onChange={(e) => {
                setMultiplier(Number(e.target.value));
              }}
            />
          </div>
        </div>
        <div className="Form-input-field">
          <h6>And Bet at least ($)</h6>
          <div className="Form-input">
            <div className="Form-icon">
              <img src="/navigation/btc.svg" />
            </div>
            <input
              type="number"
              placeholder="Bet Min."
              defaultValue={wager}
              onChange={(e) => {
                setWager(Number(e.target.value));
              }}
            />
          </div>
        </div>
        <div className="Form-input-field">
          <h6>To Win (Shuffle Tip)</h6>
          <div className="Form-input">
            <div className="Form-icon">
              <img src="/profile/shuffle.png" />
            </div>
            <input
              type="number"
              placeholder="Tip"
              defaultValue={tip}
              onChange={(e) => {
                setTip(Number(e.target.value));
              }}
            />
          </div>
        </div>
        <div className="Form-input-field">
          <h6>Challenge End</h6>
          <div className="Form-input">
            <input
              type="datetime-local"
              defaultValue={end}
              onChange={(e) => {
                setEnd(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="Form-input-field">
          <h6>Timed Challenge</h6>

          <FormControlLabel
            control={
              <Checkbox
                name="Timed"
                checked={timed}
                onChange={(e) => setTimed(!timed)}
              />
            }
            label="Timed"
          />
        </div>
        <div className="Form-submission">
          <button
            onClick={() => {
              if (submitting) {
                return;
              }
              if (!name || !image || !multiplier || !wager || !tip) {
                return;
              }
              setSubmitting(true);
              if (props.editing) {
                editChallenge({
                  id: props.editing.id,
                  name: name,
                  image: image,
                  target: multiplier,
                  min: wager,
                  reward: tip,
                  end: end,
                  timed: timed,
                }).then((response) => {
                  setSubmitting(false);
                  setName(undefined);
                  setImage(undefined);
                  setMultiplier(undefined);
                  setWager(undefined);
                  setTip(undefined);
                  setTimed(false);
                  onSuccess();
                  onClose();
                });
              } else {
                createChallenge({
                  name: name,
                  image: image,
                  target: multiplier,
                  min: wager,
                  reward: tip,
                  end: end,
                  timed: timed,
                }).then((response) => {
                  setSubmitting(false);
                  setName(undefined);
                  setImage(undefined);
                  setMultiplier(undefined);
                  setWager(undefined);
                  setTip(undefined);
                  setTimed(false);
                  onSuccess();
                  onClose();
                });
              }
            }}
          >
            {submitting
              ? 'Submitting...'
              : (props.editing ? 'Save' : 'Create') + ' Challenge'}
          </button>
        </div>
      </div>
    </Dialog>
  );
}

export default AddChallengeModal;
