import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { IRaffle } from 'src/@types/raffles';

interface IPlayerStore {
  giveaways: IRaffle[];
  updateField(field: string, value: any): void;
  findGiveawayById(id: string): IRaffle | undefined;
}

const usePlayerStore = create(
  devtools<IPlayerStore>((set, get) => ({
    giveaways: [],

    findGiveawayById: (id: string) => {
      return get().giveaways.find((giveaway) => giveaway.id === id);
    },

    updateField: (field: string, value: any) =>
      set((state) => ({ ...state, [field]: value })),
  })),
);

export default usePlayerStore;
