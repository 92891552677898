import React from 'react';
import moment from 'moment';

export function formatDateTime(date: Date) {
  const newDate = moment(date);
  return newDate.format('D MMMM - HH:mm');
}

export function formatDateYearTime(date: Date) {
  const newDate = moment(date);
  return newDate.format("D MMMM 'YY - HH:mm");
}

export function formatDateYearNoTime(date: Date) {
  const newDate = moment(date);
  return newDate.format("D MMMM 'YY");
}

export const Crates: React.FC = () => {
  function getCrates() {
    const crates: any = [];
    for (let i = 0; i < 10; i++) {
      crates.push({
        image: '/nolimit.png',
        username: 'hunterowner',
        value: 1000,
        date: Date(),
      });
    }
    return (
      <ul>
        {crates.map((crate: any) => (
          <li>
            <div className="Crate-container">
              <div className="Crate-date">{formatDateTime(crate.date)}</div>
              <div className="Crate-Content">
                <div className="Crate-image"></div>
                <div className="Crate-Player">{crate.username}</div>
                <div className="Crate-Value">
                  <img src="/currency.svg" width={15} />
                  <span>{crate.value.toLocaleString()}</span>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    );
  }

  return (
    <div className="Crates">
      <div className="Crates-heading">
        <h2>Activity</h2>
      </div>
      <div className="Crates-list">
        <ul>{getCrates()}</ul>
      </div>
    </div>
  );
};
