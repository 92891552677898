// import { IGetRafflesResponse } from './playerService';
import { YoutubeVideos } from 'src/@types/channels';
import { axiosGet } from 'src/utils/httpClient';

const publicConroller = {
  async getYoutubeVideos(): Promise<YoutubeVideos> {
    const response = await axiosGet(`/public/youtube`);

    return response;
  },

  async getChannelImage({ id }: { id: string }): Promise<any> {
    const response = await axiosGet(
      `/public/youtube-channel-avatar-url?channelId=${id}`,
    );

    return response;
  },
};

export default publicConroller;
