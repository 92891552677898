import React from 'react';

import SponsorCard from 'src/components/casino/SponsorCard';
import Divider from 'src/ui/Divider';

import 'src/assets/CSS/Casino.scss';
import 'src/assets/CSS/Casino.responsive.scss';
import s from './style.module.scss';

import { ReactComponent as BigEmpireDropLogoIcon } from 'src/assets/icons/big-empire-drop-logo.svg';
import { ReactComponent as CsgoBigLogoIcon } from 'src/assets/icons/csgobigLogo.svg';
//import { ReactComponent as CasesLogoIcon } from 'src/assets/icons/casesLogo.svg';
import { ReactComponent as ShuffleLogoIcon } from 'src/assets/icons/shuffleLogo.svg';
import BtcsCoinImage from 'src/assets/images/coins/coin1.png';
import BtcsCoinImage2 from 'src/assets/images/coins/coin2.png';

const sponsors = [
  {
    id: 1,
    name: 'Shuffle',
    icon: (
      <ShuffleLogoIcon
        style={{
          height: '35px',
        }}
      />
    ),
    codes: [
      { title: 'BTCS', link: 'https://shuffle.com/?r=btcs' },
      { title: 'GRAY', link: 'https://shuffle.com/?r=gray' },
      { title: 'HUNTER', link: 'https://shuffle.com/?r=hunter' },
      { title: 'LANCE', link: 'https://shuffle.com/?r=lance' },
      { title: 'D3', link: 'https://shuffle.com/?r=D3' },
    ],
    bonuses: ['$50 Deposit Bonus', 'Leaderboard'],
  },

  // {
  //   id: 2,
  //   name: 'CasesGG',
  //   icon: (
  //     <CasesLogoIcon
  //       style={{
  //         height: '30px',
  //       }}
  //     />
  //   ),
  //   codes: [{ title: 'BTCS', link: 'https://cases.gg/r/BTCS' }],
  // },
  {
    id: 3,
    name: 'CSGOBIG',
    icon: <CsgoBigLogoIcon style={{ height: '35px' }} width={235} />,
    codes: [{ title: 'BTCS', link: 'https://csgobig.com/r/btcs' }],
  },
  {
    id: 4,
    name: 'Big Empire Drop',
    icon: <BigEmpireDropLogoIcon style={{ height: '35px' }} width={235} />,
    codes: [{ title: 'BTCS', link: 'https://empiredrop.com/?r=btcs' }],
  },
];

export default function Casino() {
  return (
    <>
      <div className={s.hero}>
        <div className={s.hero__coin_left}>
          <img src={BtcsCoinImage} alt="Coin" />
        </div>

        <div className={s.hero__coin_right}>
          <img src={BtcsCoinImage2} alt="Coin" />
        </div>

        <div className={s.hero__container}>
          <div className={s.hero__titleBlock}>
            <h3 className={s.hero__title}>Casino</h3>
            <div
              style={{
                width: '100%',
              }}
            >
              <Divider
                borderWidth="1px"
                style={{
                  maxWidth: '900px',
                  width: '100%',
                }}
                color="#FFFFFF"
              />
            </div>
            <p className={s.hero__subtitle}>
              Use any of our sign up codes to qualify for our leaderboard,
              giveaways, and offers!
            </p>
          </div>
        </div>
        <div className={s.hero__cards}>
          {sponsors.map((sponsor) => (
            <SponsorCard item={sponsor} />
          ))}
        </div>
      </div>
    </>
  );
}
