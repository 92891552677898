const QUALITIES = [
  'maxresdefault',
  'sddefault',
  'hqdefault',
  'mqdefault',
  'default',
];

export const getBestThumbnail = (videoId: string): Promise<string> => {
  return new Promise((resolve) => {
    const testImg = new Image();
    let qualityIndex = 0;

    const tryNextQuality = () => {
      if (qualityIndex >= QUALITIES.length) {
        return resolve(`https://img.youtube.com/vi/${videoId}/default.jpg`);
      }

      const url = `https://img.youtube.com/vi/${videoId}/${QUALITIES[qualityIndex]}.jpg`;
      testImg.src = url;

      testImg.onload = () =>
        testImg.width > 120 ? resolve(url) : tryNextQuality();
      testImg.onerror = tryNextQuality;

      qualityIndex++;
    };

    tryNextQuality();
  });
};
