export const routes = {
  home: '/',

  profile: '/user',
  settings: '/settings',

  tos: '/tos',
  privacyPolicy: '/privacy-policy',
  faq: '/faq',

  leaderboard: '/leaderboard',
  leaderboardSponsor: '/leaderboard/:id',

  highlights: '/highlights',

  vip: '/rewards',
  vipSponsor: '/rewards/:id',

  oldVip: '/vip',
  oldVipSponsor: '/vip/:id',

  casino: '/casino',
  challenges: '/challenges',
  battles: '/battles',
  cases: '/cases',
  store: '/store',
  hunts: '/hunts',
  hunt: '/hunt/:hunt',
  giveaways: '/giveaways',
  giveaway: '/giveaway/:id',
  raffles: '/raffles',
  raffle: '/raffle/:id',

  admin: '/admin',
  adminDashboard: '/admin/dashboard',
  adminUsers: '/admin/users',
  adminUser: '/admin/users/:id',
  adminRaffles: '/admin/raffles',
  adminLeaderboard: '/admin/leaderboard',
  adminLeaderboardSponsor: '/admin/leaderboard/:id',
  adminChannels: '/admin/channels',
};

export const links = {
  shuffle: 'https://shuffle.com/',
  runescape: 'https://play.runescape.com/',
};
