import React from 'react';
import cn from 'classnames';
import { Link, useNavigate } from 'react-router';

import s from './style.module.scss';

interface PageLinkProps {
  to?: string;
  children: React.ReactNode;
  className?: string;
  isBackButton?: boolean;
}

const PageLink: React.FC<PageLinkProps> = ({
  to,
  children,
  className,
  isBackButton,
}) => {
  const navigate = useNavigate();

  const handleClick = (e: React.MouseEvent) => {
    if (isBackButton) {
      e.preventDefault();
      navigate(-1);
    }
  };

  return (
    //@ts-ignore
    <Link
      to={isBackButton ? '#' : to || '/'}
      className={cn(s.link, className)}
      onClick={handleClick}
    >
      {children}
    </Link>
  );
};

export default PageLink;
