import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import cn from 'classnames';
import { useLocation, useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';

import { ISponsor } from 'src/@types/leaderboard';
import DeleteSponsorModal from 'src/components/Admin/leaderboard/modals/DeleteSponsorModal';
import LeaderboardTable from 'src/components/Admin/leaderboard/table/LeaderboardTable';
import SponsorModal from 'src/components/Admin/modals/SponsorModal';
import useModalManager from 'src/hooks/useModalManager';
import { routes } from 'src/routes';
import sponsorConroller from 'src/services/leaderboard/sponsorConroller';
import { StaticList } from 'src/types';
import Button from 'src/ui/Button';
import Modal from 'src/ui/Modal';
import {
  axiosGet,
  axiosGetBlob,
  backend,
  postFile,
} from 'src/utils/httpClient';

import 'src/assets/CSS/Admin.scss';
import s from './style.module.scss';

const getBlacklist = async (): Promise<StaticList> =>
  axiosGet(`/admin/blacklist`);
const getLeaderboardPrizes = async (): Promise<StaticList> =>
  axiosGet(`/admin/leaderboard-prizes`);

const uploadLeaderboardData = async (file: any): Promise<boolean> =>
  postFile(backend, `/moderate/leaderboard-data`, file);

const downloadSnapshot = async (file: string): Promise<Blob | MediaSource> =>
  axiosGetBlob(`/moderate/download-snapshot/${file}`);
const downloadLatestSnapshot = async (): Promise<Blob | MediaSource> =>
  axiosGetBlob(`/moderate/download-snapshot/latest`);

const LeaderboardAdmin = () => {
  const navigate = useNavigate();
  const { isModalOpen, openModal, closeModal } = useModalManager();
  const [blacklist, setBlacklist] = useState<StaticList>({ strings: '' });
  const [prizes, setPrizes] = useState<StaticList>({ strings: '' });
  const [open, setOpen] = React.useState(false);
  const [openPrizes, setOpenPrizes] = React.useState(false);
  const [editingSponsor, setEditingSponsor] = React.useState<ISponsor | null>(
    null,
  );
  const location = useLocation();
  const params = useParams();
  const queryClient = useQueryClient();
  const sponsorId = params.id;

  const handleClose = () => {
    setOpen(false);
  };

  const handleClosePrizes = () => {
    setOpenPrizes(false);
  };

  useEffect(() => {
    getLeaderboardPrizes().then((response: StaticList) => {
      setPrizes(response);
    });
  }, []);

  const handleOpenSponsorModal = () => {
    openModal('sponsor-modal');
  };

  const {
    data: sponsorsData = [],
    isLoading: isFetchingSponsors,
    refetch: refetchSponsors,
  } = useQuery({
    queryKey: ['sponsors-admin'],
    queryFn: () => sponsorConroller.getSponsors({}),
  });

  //TODO: Need to show Shuffle sponsor first, in the future it should be via backend
  const filteredSponsors = [...sponsorsData].sort((a, b) => {
    if (a.name === 'Shuffle') return -1;
    if (b.name === 'Shuffle') return 1;
    return 0;
  });

  const { mutate, isPending: isPendingSyncData } = useMutation({
    mutationFn: () =>
      sponsorConroller.syncSponsorData({
        id: sponsorId || '',
      }),
    onSuccess: () => {
      toast.success('Leaderboard data successfully uploaded');

      queryClient.invalidateQueries({
        queryKey: ['sponsor-users', sponsorId],
      });

      queryClient.invalidateQueries({
        queryKey: ['sponsor-prize', sponsorId],
      });
      queryClient.invalidateQueries({
        queryKey: ['sponsor-blacklist', sponsorId],
      });
    },
  });

  const { mutate: loadSponsorData, isPending: isPendingLoadSponsorData } =
    useMutation({
      mutationFn: (body: any) =>
        sponsorConroller.loadSponsorData({
          body,
        }),
      onSuccess: () => {
        toast.success('Leaderboard data successfully uploaded');

        queryClient.invalidateQueries({
          queryKey: ['sponsor-users', sponsorId],
        });
      },
    });

  const { mutate: deleteMutate } = useMutation({
    mutationFn: (id: string) => sponsorConroller.deleteSponsor({ id }),
    onSuccess: () => {
      closeModal('sponsor-delete-modal');
      toast.success('Sponsor successfully deleted');
      queryClient.invalidateQueries({ queryKey: ['sponsors-admin'] });
      navigate(routes.adminLeaderboard);
    },
    onError: (error: any) => {
      const errorMessage =
        error?.response?.data?.message || 'Error deleting sponsor';
      toast.error(errorMessage);
      console.error('Delete sponsor error:', error);
    },
  });

  const onDeleteSponsor = () => {
    if (sponsorId) {
      deleteMutate(sponsorId);
    }
  };

  const { mutate: deleteLeaderboardMutate } = useMutation({
    mutationFn: (id: string) =>
      sponsorConroller.deleteSponsorLeaderboard({ id }),
    onSuccess: () => {
      closeModal('sponsor-leaderboard-delete-modal');
      toast.success('Sponsor leaderboard deleted');
      queryClient.invalidateQueries({
        queryKey: ['sponsor-users', sponsorId],
      });
    },
    onError: (error: any) => {
      const errorMessage =
        error?.response?.data?.message || 'Error deleting sponsor leaderboard';
      toast.error(errorMessage);
      console.error('Delete sponsor leaderboard error:', error);
    },
  });

  const onDeleteSponsorLeaderboard = () => {
    if (sponsorId) {
      deleteLeaderboardMutate(sponsorId);
    }
  };

  const onEditSponsor = () => {
    const findSponsor = sponsorsData.find((item) => item.id === params.id);

    setEditingSponsor(findSponsor);
    openModal('sponsor-modal');
  };

  const onRefetchSponsorData = () => {
    mutate();
  };

  const onLoadSponsorData = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files ? event.target.files[0] : null;

    if (selectedFile && sponsorId) {
      const formData = new FormData();

      formData.append('file', selectedFile);
      formData.append('sponsorId', sponsorId);

      loadSponsorData(formData);
    }
  };

  useEffect(() => {
    if (
      location.pathname === routes.adminLeaderboard &&
      sponsorsData.length > 0
    ) {
      const findSponsor = sponsorsData.find((item) => item.name === 'Shuffle');

      navigate(
        routes.adminLeaderboardSponsor.replace(
          ':id',
          findSponsor ? findSponsor.id : sponsorsData[0].id,
        ),
      );
    }
  }, [sponsorsData, location.pathname]);

  return (
    <div className={s.page}>
      <div className="Section-heading">
        <h4>Leaderboard</h4>
      </div>

      {isFetchingSponsors ? (
        <div>Loading...</div>
      ) : (
        <div className={s.buttonGroup}>
          <div className={s.buttonGroup__content}>
            {filteredSponsors.map((sponsor) => (
              <Button
                onClick={() =>
                  navigate(
                    routes.adminLeaderboardSponsor.replace(':id', sponsor.id),
                  )
                }
                className={cn(
                  s.buttonGroup__button,
                  location.pathname.includes(sponsor.id) &&
                    s.buttonGroup__active,
                )}
              >
                {sponsor.image ? sponsor.image : sponsor.name}
              </Button>
            ))}

            <Button
              className={s.buttonGroup__button}
              onClick={handleOpenSponsorModal}
            >
              Add Sponsor
            </Button>
          </div>
        </div>
      )}

      <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <div className={s.page__btns}>
          <div className={s.page__btns}>
            <label className={s.page__uploader}>
              Load sponsor data from CSV
              <input onChange={onLoadSponsorData} type="file" accept=".csv" />
            </label>

            <span>OR</span>

            <Button borderRadius="6px" onClick={onRefetchSponsorData}>
              Sync Leaderboard Data
            </Button>
          </div>
          <Button borderRadius="6px" onClick={onEditSponsor}>
            Edit sponsor
          </Button>
        </div>

        <div className={s.page__btns}>
          <Button
            color="#C60000"
            borderRadius="6px"
            onClick={() => openModal('sponsor-leaderboard-delete-modal')}
          >
            Delete sponsor leaderboard
          </Button>

          <Button
            color="#C60000"
            borderRadius="6px"
            onClick={() => openModal('sponsor-delete-modal')}
          >
            Delete sponsor
          </Button>
        </div>
      </div>

      <div className={s.page}>
        {sponsorsData.length === 0 ? (
          <div>Not found sponsors</div>
        ) : (
          <LeaderboardTable isSyncUsers={isPendingSyncData} />
        )}
      </div>

      <SponsorModal
        open={isModalOpen('sponsor-modal')}
        key={editingSponsor ? editingSponsor.id : 'no-id-sponsor'}
        onClose={() => {
          closeModal('sponsor-modal');
          setEditingSponsor(null);
        }}
        editingSponsor={editingSponsor}
      />

      <Modal
        maxWidth="xs"
        open={isModalOpen('sponsor-delete-modal')}
        handleClose={() => closeModal('sponsor-delete-modal')}
      >
        <DeleteSponsorModal
          title="Confirm Sponsor Delete"
          text="Are you sure you want to delete this sponsor?"
          onClose={() => closeModal('sponsor-delete-modal')}
          onDelete={onDeleteSponsor}
        />
      </Modal>

      <Modal
        maxWidth="xs"
        open={isModalOpen('sponsor-leaderboard-delete-modal')}
        handleClose={() => closeModal('sponsor-leaderboard-delete-modal')}
      >
        <DeleteSponsorModal
          title="Confirm Sponsor Leaderboard Delete"
          text="Are you sure you want to delete leaderboard for this sponsor?"
          onClose={() => closeModal('sponsor-leaderboard-delete-modal')}
          onDelete={onDeleteSponsorLeaderboard}
        />
      </Modal>
    </div>
  );
};

export default LeaderboardAdmin;
