import React, { ReactNode, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router';

import { routes } from 'src/routes';
import authService from 'src/services/authService';
import userService from 'src/services/userService';
import useAuthStore from 'src/store/authStore';
import { PlayerDetails } from 'src/types';
import { axiosGet } from 'src/utils/httpClient';

const discordIntegration = async (code: string): Promise<any> =>
  axiosGet(`/player/discord-callback?code=${code}`);
const getProfileDetails = async (): Promise<PlayerDetails> =>
  axiosGet(`/player/details`);

const redirectUri = process.env.REACT_APP_TWITCH_REDIRECT_URI;

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { setUser } = useAuthStore();
  const hasToken = localStorage.getItem('bearer') != null;

  const { mutate: fetchTwichToken } = useMutation({
    mutationFn: (code: string) => authService.fetchTwitchToken(code),
    onSuccess: async (data) => {
      localStorage.setItem('bearer', data.access_token);
      localStorage.setItem('refresh', data.refresh_token);
      localStorage.setItem('expiry', `${data.expires_in}`);

      queryClient.invalidateQueries({ queryKey: ['user-settings'] });
      navigate(routes.home);
    },
    onError: (error) => {
      console.error('Failed to fetch access token:', error);
    },
  });

  const { data: userDetails, isError } = useQuery({
    queryKey: ['user-details'],
    queryFn: () => userService.getUserDetails(),
    enabled: hasToken,
  });

  useEffect(() => {
    if (userDetails) {
      setUser(userDetails);
    }

    if (isError) {
      localStorage.removeItem('bearer');
      localStorage.removeItem('refresh');
      localStorage.removeItem('expiry');
    }
  }, [userDetails]);

  async function checkTwitchLogin() {
    const url = window.location.href;
    const codeMatches = url.match(/code=([^&]*)/);
    const errorMatches = url.match(/error=([^&]*)/);
    let code: string | undefined = undefined;
    let error: string | undefined = undefined;
    const hasToken = localStorage.getItem('bearer') != null;
    if (codeMatches) {
      code = codeMatches[0].substring(5);
    } else if (errorMatches) {
      error = errorMatches[0].substring(6);
    }

    if (code) {
      if (localStorage.getItem('discord_integration') != null) {
        const valid =
          Number(localStorage.getItem('discord_integration')) >
          new Date().getTime();

        localStorage.removeItem('discord_integration');
        if (valid) {
          await discordIntegration(code)
            .then((r) => {
              // @ts-ignore
              window.location.href = redirectUri;
            })
            .catch((e) => {
              //@ts-ignore
              window.location.href = redirectUri;
            });
          return;
        }
      }

      if (hasToken) {
        localStorage.removeItem('bearer');
        localStorage.removeItem('refresh');
        localStorage.removeItem('expiry');
      }

      localStorage.setItem('twitch', code);
      // await fetchToken();
      fetchTwichToken(code);
      //@ts-ignore

      // window.location.href = process.env.REACT_APP_TWITCH_REDIRECT_URI;
    } else if (error) {
      if (hasToken) {
        localStorage.removeItem('bearer');
        localStorage.removeItem('refresh');
        localStorage.removeItem('expiry');
      }
    }
  }

  useEffect(() => {
    checkTwitchLogin();
  }, []);

  return <>{children}</>;
};

export default AuthProvider;
