import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Link, useSearchParams } from 'react-router';
import { useDebounce } from 'use-debounce';

import RowItem from 'src/components/Admin/leaderboard/table/RowItem';
import TableHeader from 'src/components/Admin/leaderboard/table/TableHeader';
import { formatDateYearTime } from 'src/components/Crates';
import SpinnerLoading from 'src/components/loadings/SpinnerLoading';
import moderateService from 'src/services/adminDashboard/moderateService';
import Input from 'src/ui/Input';
import Pagination from 'src/ui/Pagination';

import 'src/assets/CSS/Admin.scss';

const HEADER_LIST = [
  {
    id: 0,
    title: 'Name',
    column: 'accountName',
    isSortable: true,
  },
  {
    id: 1,
    title: 'Shuffle Name',
    column: 'shuffleUsername',
    isSortable: true,
  },

  {
    id: 2,
    title: 'Discord Name',
    column: 'discordUsername',
    isSortable: true,
  },

  {
    id: 3,
    title: 'Authority',
    column: 'authority',
    isSortable: true,
  },
  {
    id: 4,
    title: 'Last Online',
    column: 'lastSeen',
    isSortable: true,
  },
];

const PlayersAdmin = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const sortColumn = searchParams.get('sortColumn');
  const sortOrder = searchParams.get('sortOrder') || 'asc';
  const page = Number(searchParams.get('page'));
  const itemsPerPage = 15;
  const [search, setSearch] = useState('');
  const [searchUserName] = useDebounce(search, 1000);

  const {
    data: players = { data: [], total: 0 },
    isLoading: isLoadingPlayers,
  } = useQuery({
    queryKey: ['players', { page, sortColumn, sortOrder, searchUserName }],
    queryFn: () =>
      moderateService.getPlayers({
        size: itemsPerPage,
        name: searchUserName,
        page: page - 1,
        sortColumn,
        sortOrder,
      }),
    placeholderData: (previousData) => previousData,
  });

  useEffect(() => {
    if (searchUserName !== '') {
      setSearchParams((prev) => {
        prev.set('page', '1');
        return prev;
      });
    }

    if (searchUserName === '') {
      setSearchParams((prev) => {
        prev.set('page', '1');
        return prev;
      });
    }
  }, [searchUserName]);

  useEffect(() => {
    if (!searchParams.has('page')) {
      setSearchParams((prev) => {
        prev.set('page', '1');
        return prev;
      });
    }
  }, []);

  const handleChangePage = (value: number) => {
    setSearchParams((prev) => {
      prev.set('page', value.toString());
      return prev;
    });
  };

  return (
    <div>
      <div className="Section-heading">
        <h4>Players</h4>
      </div>

      <div>
        <div>
          <div style={{ maxWidth: '350px' }}>
            <Input
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>

          {isLoadingPlayers ? (
            <SpinnerLoading />
          ) : (
            <div className="Btcs-table">
              <TableHeader columns={HEADER_LIST} />

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                }}
              >
                {players &&
                  players?.data.map((player) => {
                    return (
                      <Link to={`/admin/users/${player.id}`} key={player.id}>
                        <RowItem
                          item={player}
                          fields={[
                            {
                              key: 'accountName',
                              render: (value) => value,
                            },
                            {
                              key: 'shuffleUsername',
                              render: (value) => (value ? value : 'N/A'),
                            },
                            {
                              key: 'discordUsername',
                              render: (value) => (value ? value : 'N/A'),
                            },
                            {
                              key: 'authority',
                              render: (value) => value,
                            },
                            {
                              key: 'lastSeen',
                              render: (value) => formatDateYearTime(value),
                            },
                          ]}
                        />
                      </Link>
                    );
                  })}
              </div>
            </div>
          )}
        </div>

        <div style={{ paddingTop: '20px' }}>
          {players && players?.total > itemsPerPage && (
            <Pagination
              count={Math.ceil(players?.total / itemsPerPage)}
              itemsPerPage={itemsPerPage}
              page={page}
              onChangePage={(e, value) => handleChangePage(value)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PlayersAdmin;
