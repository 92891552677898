import React from 'react';
import cn from 'classnames';

import { calculateVIPRewards, numberWithCommas } from 'src/utils/utils';

// import 'src/assets/CSS/Leaderboard.scss';
import s from './style.module.scss';

const TopUserCard = ({
  user,
  type,
  currency = '$',
}: {
  user: any;
  type: 'first' | 'second' | 'third';
  currency?: string;
}) => {
  return (
    <div className={s.ranking}>
      <div
        className={cn(
          s.ranking__content,
          type === 'first' && s.ranking_gold,
          type === 'second' && s.ranking_silver,
          type === 'third' && s.ranking_bronze,
        )}
      >
        <div className={s.ranking__userIcon}>
          <img
            src={
              user
                ? (user.image ?? 'https://btcs.gg/newcoin.svg')
                : 'https://btcs.gg/newcoin.svg'
            }
          />
        </div>

        <div
          className={cn(
            s.user,
            type === 'first' && 'User-info-container-gold',
            type === 'second' && 'User-info-container-silver',
            type === 'third' && 'User-info-container-bronze',
          )}
        >
          <div className={s.user__info}>
            <div className={s.pedestalCard__prizes}>
              <div className={s.pedestalCard__prize}>
                <div className={s.pedestalCard__prize_title}>LB Prize</div>
                <div className={s.pedestalCard__prize_count}>
                  {currency}
                  {user && user ? numberWithCommas(user.prize, 0) : '-'}
                </div>
              </div>

              <div className={s.pedestalCard__prize}>
                <div className={s.pedestalCard__prize_title}>
                  Rewards Earned
                </div>
                <div className={s.pedestalCard__prize_count}>
                  $
                  {user
                    ? calculateVIPRewards(
                        user.totalWagered || user.wagered,
                        true,
                      )
                    : '-'}
                </div>
              </div>
            </div>
            <h4>
              {currency}
              {user
                ? numberWithCommas(user.totalWagered || user.wagered, 0)
                : '-'}
            </h4>
            <span>Wagered</span>
            <div className={s.user__username}>
              {user ? user.username.slice(0, 2) : '-'}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopUserCard;
