import React, { useEffect, useState } from 'react';
import { Link } from 'react-router';

import { routes } from 'src/routes';
import Button from 'src/ui/Button';

import s from './style.module.scss';

import { ReactComponent as ArrowRightIcon } from '../../../assets/icons/arrowRight.svg';
import RafflesBanneImage from '../../../assets/images/raffles/rafflesBanner.png';
import RafflesBanneMobileImage from '../../../assets/images/raffles/rafflesBannerMobile.png';

const Hero = () => {
  const [isMobile, setIsMobile] = useState(false);

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  // create an event listener
  useEffect(() => {
    window.addEventListener('resize', handleResize);
  });

  return (
    <div className={s.hero}>
      <div className={s.hero__container}>
        <div className={s.hero__titleBlock}>
          <h3 className={s.hero__title}>Raffles</h3>
          <p className={s.hero__subtitle}>
            Spend your points to buy amazing bonuses!
          </p>

          <div className={s.hero__btnContainer}>
            <a href="#currentRaffles">
              <Button isWhite>
                <div className={s.hero__btn}>
                  View Raffles <ArrowRightIcon />
                </div>
              </Button>
            </a>

            <Link to={routes.giveaways}>
              <Button isOutlinedWhite>
                <div className={s.hero__btn}>
                  View Giveaways <ArrowRightIcon />
                </div>
              </Button>
            </Link>
          </div>
        </div>

        <img
          className={s.hero__banner}
          src={isMobile ? RafflesBanneMobileImage : RafflesBanneImage}
          alt="raffles"
        />
      </div>
    </div>
  );
};

export default Hero;
