import React, { forwardRef } from 'react';
import cn from 'classnames';

import s from './style.module.scss';

interface ButtonProps {
  isUnderline?: boolean;
  children: string | React.ReactNode;
  color?: string;
  disabled?: boolean;
  disabledView?: boolean;
  className?: string;
  isOutlinedWhite?: boolean;
  onClick?: (e: any) => void;
  isWhite?: boolean;
  isOutlined?: boolean;
  isClear?: boolean;
  isBlack?: boolean;
  isIcon?: boolean;
  type?: 'button' | 'submit' | 'reset';
  borderRadius?: '6px' | '40px';
  customStyle?: React.CSSProperties;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      color,
      className,
      onClick,
      isUnderline,
      disabled,
      isOutlinedWhite,
      disabledView,
      isWhite,
      isOutlined,
      isBlack,
      isClear,
      isIcon,
      type = 'button',
      borderRadius,
      customStyle,
    }: ButtonProps,
    ref,
  ) => {
    return (
      <button
        ref={ref}
        type={type}
        onClick={onClick}
        disabled={disabled}
        className={cn(s.btn, className, {
          [s.btnOutlined]: isOutlined,
          [s.btnUnderline]: isUnderline,
          [s.btnWhite]: isWhite,
          [s.btnBlack]: isBlack,
          [s.btnOutlinedWhite]: isOutlinedWhite,
          [s.btnIcon]: isIcon,
          [s.btnClear]: isClear,
          [s.btnDisabled]: disabled || disabledView,
        })}
        style={{
          background: color,
          borderRadius: borderRadius,
          ...customStyle,
        }}
      >
        {children}
      </button>
    );
  },
);

Button.displayName = 'Button';

export default Button;
