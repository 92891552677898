import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Link, useSearchParams } from 'react-router';
import { toast } from 'react-toastify';
import { useDebounce } from 'use-debounce';

import RowItem from 'src/components/Admin/leaderboard/table/RowItem';
import TableHeader from 'src/components/Admin/leaderboard/table/TableHeader';
import { formatDateYearTime } from 'src/components/Crates';
import SpinnerLoading from 'src/components/loadings/SpinnerLoading';
import moderateService from 'src/services/adminDashboard/moderateService';
import Input from 'src/ui/Input';
import Pagination from 'src/ui/Pagination';

import 'src/assets/CSS/Admin.scss';

const HEADER_LIST = [
  {
    id: 0,
    title: 'Name',
    column: 'accountName',
    isSortable: true,
  },
  {
    id: 1,
    title: 'Shuffle Name',
    column: 'shuffleUsername',
    isSortable: true,
  },
  {
    id: 2,
    title: 'Discord Name',
    column: 'discordUsername',
    isSortable: true,
  },
  {
    id: 3,
    title: 'Authority',
    column: 'authority',
    isSortable: true,
  },
  {
    id: 4,
    title: 'Last Online',
    column: 'lastSeen',
    isSortable: true,
  },
  {
    id: 5,
    title: 'Actions',
    column: 'actions',
  },
];

const UsernameVerificationTable = () => {
  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();

  const page = Number(searchParams.get('verificationPage'));
  const sortColumn = searchParams.get('verificationSortColumn');
  const sortOrder = searchParams.get('verificationSortOrder') || 'asc';
  const itemsPerPage = 10;

  const [search, setSearch] = useState('');
  const [searchUserName] = useDebounce(search, 1000);

  const {
    data: transactions = { data: [], total: 0 },
    isLoading: isLoadingTransactions,
    refetch: refetchTransactions,
  } = useQuery({
    queryKey: [
      'shuffle-verification',
      { page, searchUserName, sortColumn, sortOrder },
    ],
    queryFn: () =>
      moderateService.getShuffleVerification({
        page: page - 1,
        size: itemsPerPage,
        name: searchUserName,
        sortColumn,
        sortOrder,
      }),
    placeholderData: (previousData) => previousData,
  });

  const { mutate: resetShuffleVerification } = useMutation({
    mutationFn: (id: string) =>
      moderateService.resetShuffleNameVerification({ id }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          'shuffle-verification',
          { page, searchUserName, sortColumn, sortOrder },
        ],
      });
    },
    onError: (e) => {
      console.log('Error:', e);
      toast.error('Failed to reset shuffle name verification');
    },
  });

  useEffect(() => {
    if (searchUserName !== '') {
      setSearchParams((prev) => {
        prev.set('verificationPage', '1');
        return prev;
      });
    }

    if (searchUserName === '') {
      setSearchParams((prev) => {
        prev.set('verificationPage', '1');
        return prev;
      });
    }
  }, [searchUserName]);

  useEffect(() => {
    if (!searchParams.has('verificationPage')) {
      setSearchParams((prev) => {
        prev.set('verificationPage', '1');
        return prev;
      });
    }
  }, []);

  const handleChangePage = (value: number) => {
    setSearchParams((prev) => {
      prev.set('verificationPage', value.toString());
      return prev;
    });
  };

  const onDeleteUsernameVerification = (item: any) => {
    resetShuffleVerification(item.id);
  };

  return (
    <div className="Dashboard-Pending">
      <span>Pending Shuffle Username Verification</span>

      <div style={{ maxWidth: '350px' }}>
        <Input
          placeholder="Search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        {isLoadingTransactions ? (
          <SpinnerLoading />
        ) : (
          <div
            className="Btcs-table"
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              width: '100%',
            }}
          >
            <TableHeader
              sortColKey="verificationSortColumn"
              sortColOrder="verificationSortOrder"
              columns={HEADER_LIST}
            />

            {transactions.data.map((player) => {
              return (
                <Link to={`/admin/users/${player.id}`}>
                  <RowItem
                    item={player}
                    fields={[
                      {
                        key: 'accountName',
                        render: (value) => value,
                      },
                      {
                        key: 'shuffleUsername',
                        render: (value) => (value ? value : 'N/A'),
                      },
                      {
                        key: 'discordUsername',
                        render: (value) => (value ? value : 'N/A'),
                      },
                      {
                        key: 'authority',
                        render: (value) => value,
                      },
                      {
                        key: 'lastSeen',
                        render: (value) => formatDateYearTime(value),
                      },
                    ]}
                    actions={[
                      {
                        id: 'delete',
                        label: 'Reset',
                        onClick: (item: any) =>
                          onDeleteUsernameVerification(item),
                      },
                    ]}
                  />
                </Link>
              );
            })}
          </div>
        )}

        <div>
          {transactions && transactions.total > itemsPerPage && (
            <Pagination
              count={Math.ceil(transactions.total / itemsPerPage)}
              itemsPerPage={itemsPerPage}
              page={page}
              onChangePage={(e, value) => handleChangePage(value)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default UsernameVerificationTable;
