import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router';

import { StoreItem } from 'src/types';
import Button from 'src/ui/Button';
import { axiosGet, axiosPut } from 'src/utils/httpClient';

import AddStoreItemModal from '../../components/Admin/modals/AddStoreItemModal';

import 'src/assets/CSS/Admin.scss';
const StoreAdmin = () => {
  const { itemDefs } = useOutletContext<any>();

  const getItems = async (): Promise<Array<StoreItem>> =>
    axiosGet(`/admin/store-items`);
  const increaseItemQty = async (id: string): Promise<Array<StoreItem>> =>
    axiosPut(`/admin/increase-quantity`, { uuid: id });
  const decreaseItemQty = async (id: string): Promise<Array<StoreItem>> =>
    axiosPut(`/admin/decrease-quantity`, { uuid: id });
  const deleteStoreItem = async (id: string): Promise<Array<StoreItem>> =>
    axiosPut(`/admin/delete-store-item`, { uuid: id });

  const [items, setItems] = useState<Array<StoreItem>>([]);
  const [editing, setEditing] = useState<StoreItem | undefined>(undefined);
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
    setEditing(undefined);
  };

  useEffect(() => {
    getItems().then((response: Array<StoreItem>) => {
      setItems(response);
    });
  }, []);

  return (
    <div>
      <div className="Section-heading">
        <h4>Store Items</h4>
        <Button
          customStyle={{
            padding: '5px',
            borderRadius: '6px',
            fontSize: '16px',
          }}
          onClick={() => setOpen(true)}
        >
          Add Item
        </Button>
      </div>

      <div className="Btcs-table">
        <ul className="Header">
          <li>Name</li>
          <li>Price</li>
          <li>Quantity</li>
          <li>Action</li>
        </ul>
        {items.map((item) => {
          return (
            <ul className="Entry">
              <li>{item.definition.name}</li>
              <li>{item.cost.toLocaleString('en-US')}</li>
              <li>{item.quantity.toLocaleString('en-US')}</li>
              <li className="Actions">
                <button
                  onClick={() => {
                    setEditing(item);
                    setOpen(true);
                  }}
                >
                  Edit
                </button>

                <button
                  onClick={() => {
                    deleteStoreItem(item.id).then((r) => {
                      setItems(r);
                    });
                  }}
                >
                  Delete
                </button>

                <button
                  onClick={() => {
                    increaseItemQty(item.id).then((r) => {
                      setItems(r);
                    });
                  }}
                >
                  + Qty.
                </button>

                <button
                  onClick={() => {
                    decreaseItemQty(item.id).then((r) => {
                      setItems(r);
                    });
                  }}
                >
                  - Qty.
                </button>
              </li>
            </ul>
          );
        })}
      </div>

      <AddStoreItemModal
        key={editing ? editing.id : 'no-id-store-item'}
        open={open}
        onClose={handleClose}
        editing={editing}
        items={itemDefs}
        setItems={setItems}
      />
    </div>
  );
};

export default StoreAdmin;
