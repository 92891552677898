import React from 'react';

import Button from 'src/ui/Button';

import s from './style.module.scss';

interface Field {
  key: string;
  render?: (value: any, item: any) => React.ReactNode;
}

interface Action {
  id?: string;
  label: string | React.ReactNode;
  onClick: (item: any) => void;
  isIcon?: boolean;
  icon?: React.ComponentType;
}

interface RowProps {
  item: any;
  fields: Field[];
  actions?: Action[];
  styles?: any;
}

const RowItem: React.FC<RowProps> = ({
  item,
  fields,
  actions = [],
  styles,
}) => {
  return (
    <div style={styles} className={s.item}>
      {fields.map((field) => (
        <li key={field.key} className={s.item__name}>
          {typeof field.render === 'function'
            ? field.render(item[field.key], item)
            : item[field.key]}
        </li>
      ))}

      {actions.length > 0 && (
        <li className={s.item__actions}>
          {actions.map(({ id, label, isIcon, onClick }, actionIndex) => (
            <Button
              key={actionIndex}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onClick(item);
              }}
              isIcon={isIcon}
              borderRadius="6px"
              color={id === 'delete' ? '#C60000' : ''}
            >
              {label}
            </Button>
          ))}
        </li>
      )}
    </div>
  );
};

export default RowItem;
