import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams, useSearchParams } from 'react-router';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';

import {
  IRaffle,
  IRafflePlayer,
  RaffleJoinRequirementsType,
  RaffleStatus,
  RaffleType,
} from 'src/@types/raffles';
import BanModal from 'src/components/Admin/modals/BanModal';
import DeleteRaffleModal from 'src/components/Admin/modals/DeleteRaffleModal';
import GiveawayModal from 'src/components/Admin/modals/GiveawayModal/GiveawayModal';
import RaffleModal from 'src/components/Admin/modals/RaffleModal/RaffleModal';
import RaffleParticipantsSection from 'src/components/Admin/RaffleParticipantsSection';
import GiveawayDetailsCard from 'src/components/giveaways/giveaway/GiveawayDetailsCard';
import PageLink from 'src/components/PageLink';
import RaffleDetailsCard from 'src/components/raffles/RaffleDetailsCard';
import { REFETCHING_TIME } from 'src/constants/fetching';
import useModal from 'src/hooks/useModal';
import { routes } from 'src/routes';
import moderateRafflesService from 'src/services/moderateRafflesService';
import Button from 'src/ui/Button';
import Modal from 'src/ui/Modal';

import s from './style.module.scss';

import { ReactComponent as EditIcon } from 'src/assets/icons/edit.svg';
import { ReactComponent as LeftArrowIcon } from 'src/assets/icons/leftArrow.svg';
import { ReactComponent as TrashIcon } from 'src/assets/icons/trash.svg';

const RaffleAdmin = () => {
  const location = useLocation();
  const { id } = useParams();
  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const playersPage = Number(searchParams.get('playersPage'));
  const { isOpen, openModal, closeModal } = useModal();
  const navigate = useNavigate();
  const [isDeleteRaffleModal, setDeleteRaffleModal] = useState<IRaffle | null>(
    null,
  );
  const [banningUser, setBanningUser] = useState<IRafflePlayer | null>(null);
  const [backUrl] = useState(location.state?.from || routes.adminRaffles);

  const itemsPerPage = 12;

  const { data, isFetching } = useQuery({
    queryKey: ['raffle', id],
    queryFn: () => moderateRafflesService.getRaffle({ id: id ? id : '' }),
    enabled: !!id,
    refetchInterval: REFETCHING_TIME,
  });

  const { mutate: selectWinners } = useMutation({
    mutationFn: () =>
      moderateRafflesService.winnersSelection({ id: id ? id : '' }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['winners', id] });
      toast.success('Winners have been selected');
    },
    onError: (error: any) => {
      const errorMessage =
        error?.response?.data?.message || 'Error selecting winners';
      toast.error(errorMessage);
      console.error('Error selecting winners:', error);
    },
  });

  const handleDeleteRaffle = () => {
    navigate(routes.adminRaffles);
  };

  const handleChangePage = (value: number) => {
    setSearchParams((prev) => {
      prev.set('playersPage', value.toString());
      return prev;
    });
  };

  useEffect(() => {
    if (!searchParams.has('playersPage')) {
      setSearchParams((prev) => {
        prev.set('playersPage', '1');
        return prev;
      });
    }
  }, []);

  if (!data && isFetching) {
    return <div>Loading...</div>;
  }

  if (!data) {
    return <div>Not Found</div>;
  }

  return (
    <>
      <div className={s.raffle}>
        <div className={s.raffle__head}>
          <PageLink to={backUrl}>
            <LeftArrowIcon />
            Back to All{' '}
            {data.type === RaffleType.GIVEAWAY ? 'Giveaways' : 'Raffles'}
          </PageLink>

          <li className={s.raffle__actions}>
            <Button borderRadius="6px" onClick={openModal}>
              <EditIcon /> Edit
            </Button>

            {data.status === RaffleStatus.COMPLETED &&
              data.joinRequirementsType !== RaffleJoinRequirementsType.BET && (
                <Button borderRadius="6px" onClick={selectWinners}>
                  Re-elect the winners
                </Button>
              )}
            <Button
              color="#C60000"
              isIcon
              borderRadius="6px"
              onClick={() => setDeleteRaffleModal(data)}
            >
              <TrashIcon />
            </Button>
          </li>
        </div>

        {data.type === RaffleType.GIVEAWAY && data.category && (
          <div className={s.raffle__head}>
            <Button>{data.category}</Button>
          </div>
        )}

        <div className={s.raffle__card}>
          {data.type === RaffleType.GIVEAWAY ? (
            <GiveawayDetailsCard isAdmin item={data} />
          ) : (
            <RaffleDetailsCard isAdmin item={data} />
          )}
        </div>

        {data.type === RaffleType.RAFFLE && (
          <RaffleParticipantsSection
            itemsPerPage={itemsPerPage}
            playersPage={playersPage}
            handleChangePage={handleChangePage}
            onBan={(user) => setBanningUser(user)}
          />
        )}
      </div>

      {data.type === RaffleType.GIVEAWAY ? (
        <GiveawayModal
          open={isOpen}
          key={data.id}
          onClose={closeModal}
          editingItem={data}
        />
      ) : (
        <RaffleModal
          open={isOpen}
          key={data.id}
          onClose={closeModal}
          editingRaffle={data}
        />
      )}

      <Modal
        maxWidth="sm"
        open={!!banningUser}
        handleClose={() => setBanningUser(null)}
        isCloseIcon
      >
        <BanModal
          entityId={data.id}
          user={banningUser}
          onClose={() => setBanningUser(null)}
        />
      </Modal>

      <Modal
        maxWidth="xs"
        open={!!isDeleteRaffleModal}
        handleClose={() => setDeleteRaffleModal(null)}
      >
        <DeleteRaffleModal
          onDelete={handleDeleteRaffle}
          item={isDeleteRaffleModal}
          onClose={() => setDeleteRaffleModal(null)}
        />
      </Modal>
    </>
  );
};

export default RaffleAdmin;
