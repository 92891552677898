import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Form, FormikProvider, useFormik } from 'formik';
import { toast } from 'react-toastify';

import { IRaffle, IRaffleImage, RaffleType } from 'src/@types/raffles';
import { ModalProps } from 'src/layouts/AdminLayout';
import moderateRafflesService from 'src/services/moderateRafflesService';
import Button from 'src/ui/Button';
import FileUploader from 'src/ui/FileUploader';
import Input from 'src/ui/Input';
import Modal from 'src/ui/Modal';
import { convertDateToIso, convertToDatetimeLocalFormat } from 'src/utils/date';
import { createImageUrl } from 'src/utils/url';

import DynamicList from '../../DynamicList';
import { FORM_VALIDATION_SCHEMA, INITIAL_VALUES } from './constants';

import s from './style.module.scss';

import LogoImage from 'src/assets/images/btcs-logo.png';

export type GiveawayModalProps = {
  editingItem: IRaffle | null;
  onSuccessCreated?: (raffle: IRaffle) => void;
} & ModalProps;

interface IFormState {
  name: string;
  type: RaffleType;
  category: string;
  description: string;
  descriptionItems: string[] | null;
  image: IRaffleImage | null;
  requirements: string;
  // subscriptionLinks: string[];
  // joinRequirementsType: any;
  startDate: string;
  endDate: string;
  activationDate: string;
  link: string;
  // maxPlayers: number;
  // maxWinners: number;
  // rewardAmount: number;
  // watchingTime: number;
  status: string;
  version: number;
}

const clearFormState = {
  name: '',
  type: RaffleType.RAFFLE,
  category: '',
  description: '',
  descriptionItems: null,
  startDate: '',
  image: null,
  requirements: '',
  // subscriptionLinks: [''],
  // joinRequirementsType: CONDITION_TYPES[1],
  endDate: '',
  activationDate: '',
  link: '',
  // maxPlayers: 0,
  // maxWinners: 0,
  // watchingTime: 0,
  // rewardAmount: 0,
  status: 'PENDING',
  version: 0,
};

function GiveawayModal({
  editingItem,
  onSuccessCreated,
  onClose,
  open,
}: GiveawayModalProps) {
  const queryClient = useQueryClient();
  const isEdit = !!editingItem;
  const [error, setError] = useState<string | null>(null);
  const [image, setImage] = useState<any>('');

  const [formState, setFormState] = useState<IFormState>(clearFormState);

  const formikProps = {
    initialValues: INITIAL_VALUES,
    validationSchema: FORM_VALIDATION_SCHEMA,
    onSubmit: (values) => {
      if (isLoadingCreate || isLoadingEdit) {
        return;
      }

      if (isEdit) {
        onEdit();
      } else {
        onCreate();
      }
    },
  };

  const formik = useFormik(formikProps);

  const { mutate: create, isPending: isLoadingCreate } = useMutation({
    mutationFn: (body: any) => moderateRafflesService.createRaffle(body),
    onSuccess: (data) => {
      if (onSuccessCreated) onSuccessCreated(data);
      queryClient.invalidateQueries({ queryKey: ['giveaways-admin'] });
      handleClose();
    },
    onError: (error: any) => {
      const errorMessage =
        error?.response?.data?.message || 'Error creating giveaway';
      setError(errorMessage);
      toast.error(errorMessage);
      console.error('Create giveaway error:', error);
    },
  });

  // Edit giveaway
  const { mutate: edit, isPending: isLoadingEdit } = useMutation({
    mutationFn: (body: any) =>
      moderateRafflesService.editRaffle({ id: editingItem?.id || '', body }),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['giveaways-admin'] });
      toast.success('Giveaway edited successfully');
      handleClose();
    },
    onError: (error: any) => {
      const errorMessage =
        error?.response?.data?.message || 'Error editing giveaway';
      setError(errorMessage);
      toast.error(errorMessage);
      console.error('Edit giveaway error:', error);
    },
  });

  // Create image
  const { mutate: createImage, isPending: isLoadingImage } = useMutation({
    mutationFn: (file: FormData) => moderateRafflesService.createImage(file),
    onSuccess: (response) => {
      handleChange({ target: { name: 'image', value: response } });
      setImage(response);
    },
    onError: (error: any) => {
      const errorMessage =
        error?.response?.data?.message || 'Error upload image';
      setError(errorMessage);
      toast.error(errorMessage);
      console.error('Error upload image:', error);
    },
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };

  const handleClose = () => {
    setError(null);
    onClose();
  };

  const handleChangeImage = async (file: File | null) => {
    if (!file) return;
    const formData = new FormData();

    formData.append('file', file);

    await createImage(formData);
  };

  const handleDeleteImage = () => {
    setImage('');
    handleChange({ target: { name: 'image', value: null } });
  };

  const formBody = () => {
    const {
      name,
      category,
      description,
      descriptionItems,
      startDate,
      endDate,
      activationDate,
      // maxPlayers,
      image,
      link,
      // maxWinners,
      // subscriptionLinks,
      // joinRequirementsType,
      // watchingTime,
      requirements,
      status,
      // rewardAmount,
      version,
    } = formState;

    const body = {
      name: name,
      type: RaffleType.GIVEAWAY,
      category: category || null,
      description: description,
      startDate: convertDateToIso(startDate),
      endDate: convertDateToIso(endDate),
      activationDate: convertDateToIso(activationDate),
      // joinRequirementsType: joinRequirementsType.id,
      // maxPlayers: 0,
      // maxWinners: 0,
      meta: {
        betAmount: 0,
        requiredSubscriptions: [link],
        description: requirements,
        descriptionItems: descriptionItems === null ? [''] : descriptionItems,
        requiredDuration: [
          {
            platform: '',
            duration: 0,
          },
        ],
      },
      rewardAmount: 0,
      imageId: image?.id,
      status: status,
      version: version,
      players: [],
    };

    return body;
  };

  const onCreate = () => {
    const body = formBody();

    create(body);
  };

  const onEdit = () => {
    const body = formBody();

    edit(body);
  };

  const onCancel = () => {
    onClose();
    setFormState(clearFormState);
  };

  const handleAddDescItem = () => {
    handleChange({
      target: {
        name: 'descriptionItems',
        value: [...(formState.descriptionItems || []), ''],
      },
    });
  };

  const handleDeleteDescItem = (index: number) => {
    const newdescriptionItems = (formState.descriptionItems || []).filter(
      (_, i) => i !== index,
    );

    handleChange({
      target: {
        name: 'descriptionItems',
        value: newdescriptionItems,
      },
    });
  };

  const handleChangeDescItem = (index: number, value: string) => {
    const newdescriptionItems = [...(formState.descriptionItems || [])];

    newdescriptionItems[index] = value;

    handleChange({
      target: {
        name: 'descriptionItems',
        value: newdescriptionItems,
      },
    });
  };

  useEffect(() => {
    if (editingItem) {
      setFormState({
        name: editingItem.name || '',
        type: RaffleType.RAFFLE,
        category: editingItem.category || '',
        description: editingItem.description || '',
        descriptionItems: editingItem.meta.descriptionItems || [''],
        status: editingItem.status || 'PENDING',
        requirements: editingItem.meta.description || '',
        image: editingItem.image || null,
        link: editingItem.meta.requiredSubscriptions[0] || '',
        startDate: convertToDatetimeLocalFormat(editingItem.startDate) || '',
        endDate: convertToDatetimeLocalFormat(editingItem.endDate) || '',
        activationDate:
          convertToDatetimeLocalFormat(editingItem.activationDate) || '',
        version: editingItem.version || 0,
      });

      formik.setValues({
        name: editingItem.name || '',
        description: editingItem.description || '',
        link: editingItem.meta.requiredSubscriptions[0] || '',
      });
    }
  }, [editingItem]);

  return (
    <Modal open={open} handleClose={handleClose} isCloseIcon>
      <FormikProvider value={formik}>
        <Form>
          <div className={s.modal__header}>
            <img src={LogoImage} alt="logo" />

            <h2>
              {isEdit ? `Edit ` : `Add `}
              Giveaway
            </h2>
          </div>

          <div className={s.modal__form}>
            <Input
              label="Name"
              name="name"
              placeholder="Type here"
              value={formState.name}
              onChange={(e) => {
                formik.setFieldValue(e.target.name, e.target.value);
                handleChange(e);
              }}
              error={formik.errors.name}
            />

            <Input
              label="Description"
              name="description"
              placeholder="Type here"
              value={formState.description}
              onChange={(e) => {
                formik.setFieldValue(e.target.name, e.target.value);
                handleChange(e);
              }}
              error={formik.errors.description}
            />

            <Input
              label="Link"
              name="link"
              placeholder="https://example.com"
              value={formState.link}
              onChange={(e) => {
                formik.setFieldValue(e.target.name, e.target.value);
                handleChange(e);
              }}
              error={formik.errors.link}
            />

            <Input
              label="Requirement description"
              name="requirements"
              placeholder="Type here"
              value={formState.requirements}
              onChange={handleChange}
            />

            <DynamicList
              title="Description list"
              items={formState?.descriptionItems || []}
              onAdd={handleAddDescItem}
              onDelete={handleDeleteDescItem}
              onChange={handleChangeDescItem}
            />

            {isLoadingImage ? (
              <div>Loading image...</div>
            ) : (
              <div style={{ width: 'auto' }}>
                <FileUploader
                  value={image}
                  setValue={handleChangeImage}
                  label="Icon upload"
                  url={createImageUrl(image.url)}
                  onDelete={handleDeleteImage}
                  title="Attach raffle's logo here"
                  name="image"
                  acceptedFileTypes={['image/jpeg', 'image/png']}
                />
              </div>
            )}

            <Input
              label="Sponsor"
              name="category"
              placeholder="Type here"
              value={formState.category}
              onChange={(e) => {
                formik.setFieldValue(e.target.name, e.target.value);
                handleChange(e);
              }}
            />

            <div className={s.modal__row}>
              <Input
                label="Activation Date"
                type="datetime-local"
                name="activationDate"
                value={formState.activationDate}
                onChange={handleChange}
              />

              <Input
                label="Start Date"
                type="datetime-local"
                name="startDate"
                value={formState.startDate}
                onChange={handleChange}
              />

              <Input
                label="End Date"
                type="datetime-local"
                name="endDate"
                value={formState.endDate}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className={s.modal__footer}>
            {error && <div className={s.modal__error}>{error}</div>}

            <div className={s.modal__row}>
              <Button
                className={s.modal__btn}
                onClick={onCancel}
                isOutlinedWhite
              >
                Cancel
              </Button>
              <Button className={s.modal__btn} type="submit">
                {isLoadingCreate || isLoadingEdit
                  ? 'Submitting...'
                  : isEdit
                    ? `Edit`
                    : `Add`}
              </Button>
            </div>
          </div>
        </Form>
      </FormikProvider>
    </Modal>
  );
}

export default GiveawayModal;
