export enum RaffleType {
  GIVEAWAY = 'GIVEAWAY',
  RAFFLE = 'RAFFLE',
}

export enum RaffleCategory {
  NONE = 'NONE',
  SHUFFLE = 'SHUFFLE',
  RUNESCAPE = 'RUNESCAPE',
}

export enum RaffleStatus {
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  COMPLETED = 'COMPLETED',
  UPCOMING = 'UPCOMING',
}

export enum WagerRequirementType {
  MONTHLY = 'MONTHLY',
  TOTAL = 'TOTAL',
}

export interface IRaffleImage {
  id: string;
  mediaType: string;
  addedOn: string;
  url: string;
}

export enum RewardStatus {
  PENDING = 'PENDING',
  REWARDED = 'REWARDED',
}

export enum RaffleJoinRequirementsType {
  BET = 'BET',
  SUBSCRIPTION = 'SUBSCRIPTION',
  WAGER = 'WAGER',
}

export interface IRafflePlayer {
  id: string;
  accountName: string;
  signupDate: string;
  lastSeen: string;
  points: number;
  avatarUrl: string;
  rewardStatus: RewardStatus;
  banned: boolean;
  joinedTimes: number;
}

export interface IRafflePublicWinner {
  id: string;
  accountName: string;
  avatarUrl: string;
  joinedTimes: number;
}

export interface IRafflePublicPlayer {
  id: string;
  accountName: string;
  avatarUrl: string;
  joinedTimes: number;
}

export interface IRaffle {
  id: string;
  name: string;
  type: RaffleType;
  category: RaffleCategory;
  imageId: null | string;
  description: string;
  startDate: string;
  endDate: string;
  activationDate: string;
  joinRequirementsType: any;
  maxPlayers: number;
  maxWinners: number;
  meta: {
    betAmount: 0.0;
    requiredSubscriptions: ['string'];
    providerType: RaffleCategory;
    requiredDuration: [
      {
        platform: 'string';
        duration: 0;
      },
    ];
    description: string;
    descriptionItems: string[];
    wagerRequirement: 0.0;
    requiredSponsorId: string;
    wagerRequirementType: WagerRequirementType;
  };
  rewardAmount: string;
  status: RaffleStatus;
  version: number;
  image: IRaffleImage | null;
  players: IRafflePlayer[];
  winners: IRafflePlayer[];
  joined: boolean;
  numberOfPlayers: number;
  numberOfWinners: number;
  unlimitedPlayers: boolean;
  joinedTimes: number;
}

export interface IBanUserBody {
  reason: string;
  banType: string;
  entityId?: string;
}

export enum BanUserType {
  ENTIRE_ACCOUNT = 'ENTIRE_ACCOUNT',
  RAFFLES = 'RAFFLES',
  GIVEAWAYS = 'GIVEAWAYS',
  GAME_ID = 'GAME_ID',
}
