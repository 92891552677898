import React from 'react';

import s from './style.module.scss';

import { ReactComponent as CoinIcon } from 'src/assets/icons/bet.svg';

interface IRaffleRequirementsProps {
  title?: string;
  betAmount: number;
}

const RaffleBetRequirements = ({
  title,
  betAmount,
}: IRaffleRequirementsProps) => {
  return (
    <div className={s.block}>
      {title && <div className={s.block__title}>{title}</div>}

      <div className={s.block__table}>
        <div className={s.block__iconContainer}>
          <CoinIcon />
          <div className={s.block__value}>
            {betAmount} <span>Bet</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RaffleBetRequirements;
