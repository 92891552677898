import React, { useEffect, useState } from 'react';

import AddSlotBattleModal from 'src/components/Admin/modals/AddSlotBattleModal';
import ConcludeSlotBattleModal from 'src/components/Admin/modals/ConcludeSlotBattleModal';
import { formatDateYearTime } from 'src/components/Crates';
import { SlotBattle } from 'src/types';
import Button from 'src/ui/Button';
import { axiosGet, axiosPut } from 'src/utils/httpClient';

import 'src/assets/CSS/Admin.scss';

const SlotBattlesAdmin = () => {
  const getBattles = async (): Promise<Array<SlotBattle>> =>
    axiosGet(`/general/slot-battles`);
  const deleteBattle = async (id: string): Promise<Array<SlotBattle>> =>
    axiosPut(`/moderate/delete-battle`, { uuid: id });

  const [battles, setBattles] = useState<Array<SlotBattle>>([]);
  const [editing, setEditing] = useState<SlotBattle | undefined>(undefined);
  const [open, setOpen] = React.useState(false);
  const [openConclude, setOpenConclude] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
    setEditing(undefined);
  };

  const handleCloseConclude = () => {
    setOpenConclude(false);
    setEditing(undefined);
  };

  useEffect(() => {
    getBattles().then((response: Array<SlotBattle>) => {
      setBattles(response);
    });
  }, []);

  return (
    <div>
      <div className="Section-heading">
        <h4>Slot Battles</h4>
        <Button
          customStyle={{
            padding: '5px',
            borderRadius: '6px',
            fontSize: '16px',
          }}
          onClick={() => setOpen(true)}
        >
          New Battle
        </Button>
      </div>

      <div className="Btcs-table">
        <ul className="Header">
          <li>Streamer</li>
          <li>Date</li>
          <li>Action</li>
        </ul>
        {battles.map((battle) => {
          return (
            <ul className="Entry">
              <li>{battle.streamer}</li>
              <li>{formatDateYearTime(battle.creationDate)}</li>
              <li className="Actions">
                {battle.winner ? (
                  <></>
                ) : (
                  <button
                    onClick={() => {
                      setEditing(battle);
                      setOpenConclude(true);
                    }}
                  >
                    Conclude
                  </button>
                )}

                <button
                  onClick={() => {
                    setEditing(battle);
                    setOpen(true);
                  }}
                >
                  Edit
                </button>

                <button
                  onClick={() => {
                    deleteBattle(battle.id).then((r) => {
                      setBattles(r);
                    });
                  }}
                >
                  Delete
                </button>
              </li>
            </ul>
          );
        })}
      </div>

      <AddSlotBattleModal
        key={editing ? editing.id : 'no-id-slotbattle'}
        open={open}
        onClose={handleClose}
        editing={editing}
        setItems={setBattles}
      />

      <ConcludeSlotBattleModal
        key={editing ? editing.id : 'no-id-concludeslotbattle'}
        open={openConclude}
        onClose={handleCloseConclude}
        editing={editing}
        setItems={setBattles}
      />
    </div>
  );
};

export default SlotBattlesAdmin;
