import { useMutation, useQueryClient } from '@tanstack/react-query';
import cn from 'classnames';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';

import {
  ISponsorDefinition,
  SponsorDefinitionStatus,
} from 'src/@types/leaderboard';
import { Login } from 'src/components/Header';
import useModalManager from 'src/hooks/useModalManager';
import publicConroller from 'src/services/leaderboard/publicController';
import useAuthStore from 'src/store/authStore';
import Button from 'src/ui/Button';
import Modal from 'src/ui/Modal';
import { numberWithCommas } from 'src/utils/utils';

import LoginModal from '../modals/LoginModal';

import 'src/assets/CSS/VIP.scss';
import s from './style.module.scss';

const TiersList = ({
  data,
  onClaim,
  currency = '$',
}: {
  data: ISponsorDefinition[];
  onClaim: () => void;
  currency?: string;
}) => {
  const sortedData = data
    .filter((item) => item.value !== 0)
    .sort((a, b) => a.value - b.value);
  const location = useLocation();
  const sponsorId = location.state.sponsor.id;
  const queryClient = useQueryClient();
  const { isModalOpen, closeModal, openModal } = useModalManager();
  const { url } = Login();
  const { user, userSettings } = useAuthStore();
  const isUserLoggedIn = user !== null;

  const { mutate: claimReward } = useMutation({
    mutationFn: ({ tier, sponsorId }: { tier: number; sponsorId: string }) =>
      publicConroller.claimReward({ sponsorId, tier }),
    onSuccess: (response) => {
      if (onClaim) {
        onClaim();
      }

      if (response === false) {
        toast.info('Not enough wagers');
      }
      queryClient.invalidateQueries({ queryKey: ['vip-claims'] });
    },
  });

  const onClaimReward = (
    tier: number,
    status: SponsorDefinitionStatus | null | undefined,
  ) => {
    if (status === undefined) {
      openModal('need-login');
      return;
    }

    if (
      status === SponsorDefinitionStatus.PENDING ||
      status === SponsorDefinitionStatus.COMPLETED
    )
      return;

    claimReward({ tier, sponsorId });
  };

  return (
    <>
      <div
        className={s.container}
        id="rewards"
        style={{ overflow: 'hidden', width: '100%' }}
      >
        <div className={s.list}>
          {sortedData.map((t, index) => {
            const btnText =
              t.status === SponsorDefinitionStatus.COMPLETED
                ? 'Received'
                : t.status === SponsorDefinitionStatus.PENDING
                  ? 'Claimed'
                  : 'Claim';

            return (
              <div className={s.entry}>
                <div className={s.entry__icon}>
                  <img src={t.image} />
                </div>
                <div className={s.entry__text}>
                  <div className={s.entry__wager}>
                    Wager {currency}
                    {numberWithCommas(t.value, 0)}
                  </div>
                  <div className={s.entry__reward}>{t.name}</div>
                </div>
                <div className={s.entry__action}>
                  <Button
                    borderRadius="6px"
                    disabled={t.status === SponsorDefinitionStatus.PENDING}
                    className={cn(
                      s.btn,
                      t.status === SponsorDefinitionStatus.COMPLETED &&
                        s.btn_completed,
                    )}
                    onClick={() => {
                      onClaimReward(t.id!, t?.status);
                    }}
                  >
                    {btnText}
                  </Button>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <Modal
        maxWidth="xs"
        open={isModalOpen('need-login')}
        handleClose={() => closeModal('need-login')}
      >
        <LoginModal />
      </Modal>
    </>
  );
};

export default TiersList;
