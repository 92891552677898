import React, { CSSProperties, ReactNode } from 'react';
import cn from 'classnames';

import s from './style.module.scss';

import { ReactComponent as BatteryBigIcon } from '../../../assets/icons/batteryBig.svg';
import { ReactComponent as GiftBigIcon } from '../../../assets/icons/giftBig.svg';
import { ReactComponent as TwichBigIcon } from '../../../assets/icons/twichBig.svg';
import ArrowDownImage from 'src/assets/images/raffles/arrowDown.png';
import ArrowDownSecondImage from 'src/assets/images/raffles/arrowDownSecond.png';

const InfoItem = ({
  icon,
  text,
  className,
  textStyle,
}: {
  icon: ReactNode;
  text: string;
  className?: string;
  textStyle?: CSSProperties | undefined;
}) => {
  return (
    <div className={cn(s.infoItem, className)}>
      <div className={s.infoItem__icon}>{icon}</div>
      <div style={textStyle} className={cn(s.infoItem__text)}>
        {text}
      </div>
    </div>
  );
};

const HowToEarnWpoints = () => {
  return (
    <div className={s.block}>
      <div className="container">
        <div className={s.block__content}>
          <div className={s.block__text}>
            <div className={s.block__subtitle}>HOW TO EARN</div>
            <div className={s.block__title}>WPOINTS?</div>
          </div>

          <div className={cn(s.block__steps, s.block__steps_desktop)}>
            <InfoItem
              textStyle={{ maxWidth: '253px' }}
              className={cn(s.infoItem_first)}
              icon={<TwichBigIcon />}
              text="Follow any of the streamer channels that we have"
            />

            {/* <img className={s.block__stepsArrow} src={ArrowDownImage} alt='arrow' /> */}
            {/* </div> */}

            <InfoItem
              textStyle={{ maxWidth: '168px' }}
              className={s.infoItem_second}
              icon={<BatteryBigIcon />}
              text="watch any of the streamers"
            />

            {/* <img
              className={cn(s.block__stepsArrow, s.block__stepsArrow_second)}
              src={ArrowDownSecondImage}
              alt='arrow'
            /> */}

            <InfoItem icon={<GiftBigIcon />} text="earn points" />
          </div>

          <div className={cn(s.block__steps, s.block__steps_mobile)}>
            {/* <div style={{ position: 'relative' }}> */}
            <InfoItem
              textStyle={{ maxWidth: '253px' }}
              className={cn(s.infoItem_first)}
              icon={<TwichBigIcon />}
              text="Follow any of the streamer channels that we have"
            />

            <img
              className={s.block__steps_mobile__arrowFirst}
              src={ArrowDownImage}
              alt="arrow"
            />
            {/* </div> */}

            <InfoItem
              textStyle={{ maxWidth: '168px' }}
              className={s.infoItem_second}
              icon={<BatteryBigIcon />}
              text="watch any of the streamers"
            />

            <img
              className={s.block__steps_mobile__arrowSecond}
              src={ArrowDownSecondImage}
              alt="arrow"
            />

            <InfoItem icon={<GiftBigIcon />} text="earn points" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowToEarnWpoints;
