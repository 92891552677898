import React from 'react';

import { IRafflePublicPlayer } from 'src/@types/raffles';

import s from './style.module.scss';

const EntryItem = ({ item }: { item: IRafflePublicPlayer }) => {
  return (
    <div className={s.entry}>
      <div className={s.entry__img}>
        <img src={item.avatarUrl} alt="avatar" />
      </div>

      <div className={s.entry__info}>
        <div className={s.entry__name}>{item.accountName}</div>
        <div className={s.entry__ticket}>Tickets: {item.joinedTimes}</div>
      </div>
    </div>
  );
};

export default EntryItem;
