import * as Yup from 'yup';

const CONDITION_TYPES = [
  {
    label: 'Watching points',
    value: 'watchingPoints',
    id: 'BET',
  },
  {
    label: 'Subscription',
    value: 'subscription',
    id: 'SUBSCRIPTION',
  },
];

export const INITIAL_VALUES = {
  name: '',
  description: '',
  link: '',
};

export const FORM_VALIDATION_SCHEMA = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  description: Yup.string().required('Description is required'),
  link: Yup.string().url().required('Link is required'),
});
