import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { IChannel } from 'src/@types/channels';
import ChannelModal from 'src/components/Admin/channels/modals/ChannelModal';
import DeleteChannelModal from 'src/components/Admin/channels/modals/DeleteChannelModal';
import RowItem from 'src/components/Admin/leaderboard/table/RowItem';
import TableHeader from 'src/components/Admin/leaderboard/table/TableHeader';
import useModalManager from 'src/hooks/useModalManager';
import channelsService from 'src/services/channels/moderateService';
import Button from 'src/ui/Button';
import Modal from 'src/ui/Modal';

import s from './style.module.scss';

import { ReactComponent as PlusIcon } from 'src/assets/icons/plus.svg';

const HEADER_LIST = [
  {
    id: 0,
    title: 'Name',
    isSortable: false,
  },

  {
    id: 1,
    title: 'Channel ID',
    isSortable: false,
  },
  {
    id: 2,
    title: 'Number Of Videos',
    isSortable: false,
  },
  {
    id: 3,
    title: 'Actions',
    column: 'actions',
    isSortable: false,
  },
];

const ChannelsAdmin = () => {
  const { isModalOpen, closeModal, openModal, toggleModal } = useModalManager();

  const [isDeleteChannelModal, setDeleteChannelModal] =
    useState<IChannel | null>(null);
  const [editingItem, setEditingItem] = useState<IChannel | null>(null);
  const queryClient = useQueryClient();

  const {
    data: channelsData,
    isLoading: isFetchingChannels,
    refetch: channelsRefetch,
  } = useQuery({
    queryKey: ['channels-admin'],
    queryFn: () => channelsService.getChannels(),
  });

  const { mutate: mutateDelete } = useMutation({
    mutationFn: ({ id }: { id: string }) =>
      channelsService.deleteChannel({ id }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['channels-admin'] });
    },
  });

  const onCloseChannelModal = () => {
    closeModal('channel-modal');
    setEditingItem(null);
  };

  const onCloseDeleteChannelModal = async () => {
    closeModal('delete-channel-modal');
    setDeleteChannelModal(null);
  };

  const onDeleteChannel = async (id: string) => {
    channelsRefetch();
    mutateDelete({ id });
    closeModal('delete-channel-modal');
  };

  return (
    <>
      <div className={s.panel}>
        <div className={s.panel__header}>
          <h4>Active Channels</h4>
          <Button
            className="iconBtn"
            borderRadius="6px"
            onClick={() => openModal('channel-modal')}
          >
            <PlusIcon /> Add Channel
          </Button>
        </div>

        <div>
          <TableHeader columns={HEADER_LIST} />

          {isFetchingChannels && <div>Loading...</div>}

          <ul className={s.panel__table}>
            {channelsData &&
              !isFetchingChannels &&
              channelsData.map((item) => (
                <li>
                  <RowItem
                    item={item}
                    fields={[
                      {
                        key: 'name',
                        render: (value) => <span>{value}</span>,
                      },
                      {
                        key: 'channelId',
                        render: (value) => <span>{value}</span>,
                      },
                      {
                        key: 'numberOfItemsToFetch',
                        render: (value) => <span>{value}</span>,
                      },
                    ]}
                    actions={[
                      {
                        label: 'Edit',
                        onClick: (item) => {
                          setEditingItem(item);
                          openModal('channel-modal');
                        },
                      },
                      {
                        id: 'delete',
                        label: 'Delete',
                        onClick: (item) => {
                          setDeleteChannelModal(item);
                          openModal('delete-channel-modal');
                        },
                      },
                    ]}
                  />
                </li>
              ))}
          </ul>
        </div>

        <ChannelModal
          open={isModalOpen('channel-modal')}
          key={editingItem ? editingItem.id : 'new-channel'}
          onClose={onCloseChannelModal}
          editingItem={editingItem}
        />
      </div>

      <Modal
        maxWidth="xs"
        open={isModalOpen('delete-channel-modal')}
        handleClose={onCloseDeleteChannelModal}
      >
        <DeleteChannelModal
          onDelete={onDeleteChannel}
          item={isDeleteChannelModal}
          onClose={onCloseDeleteChannelModal}
        />
      </Modal>
    </>
  );
};

export default ChannelsAdmin;
