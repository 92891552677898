import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useQuery } from '@tanstack/react-query';

import { ISponsor } from 'src/@types/leaderboard';
import LeaderboardBlock from 'src/components/main/LeaderboardBlock';
import Streamers from 'src/components/Streamers';
import { StreamersVideos } from 'src/components/StreamersVideos';
import publicConroller from 'src/services/leaderboard/publicController';
import playerService from 'src/services/playerService';
import useAuthStore from 'src/store/authStore';

import { LeaderboardEntry } from '../types';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      // margin: theme.spacing(1),
      width: '25ch',
    },
  },
  gridList: {
    width: '100%',
    height: 'auto',
  },
  card: {
    minWidth: 100,
    maxHeight: 100,
    height: '100%',
    backgroundColor: 'transparent !important',
    margin: '15px',
    border: 'none',
    boxShadow: 'none',
  },
  cardContent: {
    backgroundSize: 'cover',
    backgroundColor: '#080808',
    height: '56px',
    border: '2px solid #2a2e38',
    textAlign: 'left',
    fontSize: '11px !important',
    color: '#cecece',
  },
  select: {
    '&:before': {
      borderColor: 'white !important',
    },
    '&:after': {
      borderColor: 'white !important',
    },
    '&:not(.Mui-disabled):hover::before': {
      borderColor: 'white !important',
    },
    color: 'white !important',
    background: '#1b1d27 !important',
  },
  icon: {
    fill: 'white !important',
  },
  accordion: {
    background: 'linear-gradient(180deg, #041128, #1e1826) !important',
    borderRadius: '10px',
    color: 'white !important',
  },
}));

export const Landing: React.FC = () => {
  const { user } = useAuthStore();
  const classes = useStyles();
  const [highlightFilter, setHighlightsFilter] = useState<string>('1st Place');
  const [currentSponsor, setCurrentSponsor] = useState<ISponsor | null>(null);

  const { data: sponsorsData } = useQuery({
    queryKey: ['sponsors'],
    queryFn: () => publicConroller.getSponsors(),
  });

  useEffect(() => {
    if (sponsorsData && sponsorsData.length > 0) {
      const findSponsor = sponsorsData.find(
        (sponsor) => sponsor.name === 'Shuffle',
      );

      setCurrentSponsor(findSponsor ? findSponsor : sponsorsData[0]);
    }
  }, [sponsorsData]);

  const { data } = useQuery({
    queryKey: ['top3-leaderboard'],
    queryFn: () =>
      playerService.getTop3Leaderboard({ id: currentSponsor?.id! }),
    enabled: !!currentSponsor,
  });

  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setHighlightsFilter(value);
  };

  const snapshots = ['1st Place', '2nd Place', '3rd Place'];
  let viewing: LeaderboardEntry | undefined;
  if (data) {
    viewing =
      highlightFilter == '1st Place'
        ? data[0]
        : highlightFilter == '2nd Place'
          ? data[1]
          : data[2];
  }

  return (
    <div className="App-contents-container">
      <div className="App-contents">
        <div className="hero">
          <img className="Background-img" src="/chips-cropped.png" />
          <div className="Hero-Content">
            <div className="Headline">
              <h3>Welcome</h3>
              <h1>To BTCS</h1>
              <p>
                {user
                  ? 'Start watching our streams to earn rewards with Points!'
                  : 'Sign in to start earning points.'}
              </p>
            </div>
            <div className="Streamer-List-Container">
              <div className="Streamer-List">
                <Streamers />
              </div>
            </div>
          </div>
        </div>
        <div className="Landing-content">
          <br />
          <br />

          <LeaderboardBlock />

          <div className="Landing-heading Heading-Landing-2">
            <h2 className="Main-heading">Latest Videos</h2>
            <div className="Heading-divider" />
            <p className="Main-subheading">
              Catch the latest and greatest on our Youtube Channel! Subscribe
              now for endless entertainment and stay updated with our content.
            </p>
          </div>
          <StreamersVideos />
        </div>
      </div>
    </div>
  );
};
