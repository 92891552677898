import React, { useEffect, useState } from 'react';
import { Card, CardContent, CardMedia } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useQueries, useQuery } from '@tanstack/react-query';

import publicConroller from 'src/services/publicController';
import { getBestThumbnail } from 'src/utils/youtubeThumbnail';

import SpinnerLoading from './loadings/SpinnerLoading';

import '../assets/CSS/App.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      // margin: theme.spacing(1),
      width: '25ch',
    },
  },
  gridList: {
    width: '100%',
    height: 'auto',
    display: 'inline-flex',
    flexDirection: 'row',
  },
  card: {
    height: '280px',
    maxWidth: '445px',
    minWidth: '445px',
    transition: '.6s !important',
    backgroundColor: 'transparent !important',
    padding: '0 !important',
    margin: '0 !important',
    boxShadow: 'none !important',
    border: 'none',
    borderRadius: '15px !important',
  },
  cardMedia: {
    height: '220px',
    borderRadius: '15px',
    boxShadow:
      '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 4px 4px 0px rgba(0, 0, 0, 0.14), 0px 4px 7px 0px rgba(0, 0, 0, 0.12) !important',
  },
  cardContent: {
    textAlign: 'left',
    fontSize: '11px !important',
    boxShadow: 'none',
    padding: '0 !important',
    color: '#cecece',
  },
}));

export const StreamersVideos: React.FC = () => {
  const classes = useStyles();
  const [videoThumbnails, setVideoThumbnails] = useState<
    Record<string, string>
  >({});

  const { data: youtubeVideos, isLoading: isLoadingYoutubeVideos } = useQuery({
    queryKey: ['youtubeVideos'],
    queryFn: () => publicConroller.getYoutubeVideos(),
  });

  const channelQueries = useQueries({
    queries: (youtubeVideos ? Object.keys(youtubeVideos) : []).map((key) => ({
      queryKey: ['channelImage', key],
      queryFn: () => publicConroller.getChannelImage({ id: key }),
    })),
  });

  useEffect(() => {
    const fetchThumbnails = async () => {
      const thumbnails: Record<string, string> = {};

      for (const channel in youtubeVideos) {
        for (const video of youtubeVideos[channel]) {
          thumbnails[video.videoId] = await getBestThumbnail(video.videoId);
        }
      }

      setVideoThumbnails(thumbnails);
    };

    fetchThumbnails();
  }, [youtubeVideos]);

  if (isLoadingYoutubeVideos) {
    return <SpinnerLoading />;
  } else if (channelQueries.some((query) => query.isLoading)) {
    return <SpinnerLoading />;
  }

  return (
    <>
      {youtubeVideos
        ? Object.entries(youtubeVideos).map(
            ([streamer, videos], channelIndex) => {
              return (
                <>
                  {videos.length !== 0 ? (
                    <>
                      <p className="StreamerName">{videos[0]?.channel.name}</p>
                      <div className="Infinite-horizontal Streamer-videos">
                        <div
                          className={classes.gridList + ' Infinite-horizontal'}
                        >
                          {videos.map((video, index) => {
                            return (
                              <a
                                target="_action"
                                href={`https://www.youtube.com/watch?v=${video.videoId}`}
                                className={'Video-case CardHover-video'}
                              >
                                <Card className={classes.card + ' Card'}>
                                  <CardMedia
                                    component="img"
                                    alt={video.title}
                                    className={classes.cardMedia}
                                    height="140"
                                    image={videoThumbnails[video.videoId]}
                                  />
                                  <CardContent className={classes.cardContent}>
                                    <div className="Video-Details">
                                      <div className="Video-Uploader">
                                        <img
                                          src={
                                            channelQueries[channelIndex].data
                                          }
                                          alt={video.channel.name}
                                        />
                                      </div>
                                      <div className="Video-Title">
                                        <span>{video.title}</span>
                                        <p>{video.channel.name}</p>
                                      </div>
                                    </div>
                                  </CardContent>
                                </Card>
                              </a>
                            );
                          })}
                        </div>
                      </div>
                    </>
                  ) : null}
                </>
              );
            },
          )
        : null}
    </>
  );
};
