import Dialog from '@mui/material/Dialog';

import { dialogStyles, ModalProps } from 'src/layouts/AdminLayout';
import Button from 'src/ui/Button';

import 'src/assets/CSS/Settings.scss';
import s from './style.module.scss';

function SuccsessUpdateProfileModal({ open, onClose }: ModalProps) {
  return (
    <Dialog onClose={onClose} open={open} PaperProps={{ style: dialogStyles }}>
      <div className={s.modal}>
        <div className={s.modal__title}>
          Your account has been successfully updated!
        </div>

        <div className={s.modal__btnContainer}>
          <Button borderRadius="6px" onClick={onClose}>
            Close
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export default SuccsessUpdateProfileModal;
