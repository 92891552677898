import React from 'react';
import cn from 'classnames';
import { Link, useLocation } from 'react-router';

import { routes } from 'src/routes';

import s from './style.module.scss';

import { ReactComponent as ChallengesIcon } from 'src/assets/icons/challenges.svg';
import { ReactComponent as GiveawaysRafflesIcon } from 'src/assets/icons/giveawaysRaffles.svg';
import { ReactComponent as HighlightsIcon } from 'src/assets/icons/highlights.svg';
import { ReactComponent as HomeIcon } from 'src/assets/icons/home.svg';
import { ReactComponent as ItemsIcon } from 'src/assets/icons/items.svg';
import { ReactComponent as LeaderboardIcon } from 'src/assets/icons/leaderboard.svg';
import { ReactComponent as SlotBattleIcon } from 'src/assets/icons/slotBattles.svg';
import { ReactComponent as StoreIcon } from 'src/assets/icons/store.svg';
import { ReactComponent as StoreLogIcon } from 'src/assets/icons/storeLog.svg';
import { ReactComponent as UsersIcon } from 'src/assets/icons/users.svg';

export type NavigationProps = {
  authority: string;
};

export const AdminNavigation: React.FC<NavigationProps> = ({ authority }) => {
  const { pathname } = useLocation();

  const all = [
    {
      title: 'Dashboard',
      width: 25,
      url: '/admin/dashboard',
      icon: <HomeIcon />,
    },
    {
      title: 'Challenges',
      width: 25,
      url: '/admin/challenges',
      icon: <ChallengesIcon />,
      admin: false,
    },
    {
      title: 'Highlights',
      width: 25,
      url: '/admin/highlights',
      icon: <HighlightsIcon />,
      admin: false,
    },
    {
      title: 'Items',
      width: 25,
      url: '/admin/items',
      icon: <ItemsIcon />,
      admin: false,
    },
    {
      title: 'Store',
      width: 25,
      url: '/admin/store',
      icon: <StoreIcon />,
      admin: false,
    },
    {
      title: 'Store Log',
      width: 25,
      url: '/admin/store-log',
      icon: <StoreLogIcon />,
      admin: false,
    },
    {
      title: 'Slot Battles',
      width: 25,
      url: '/admin/battles',
      icon: <SlotBattleIcon />,
      admin: false,
    },
    {
      title: 'Giveaways & Raffles',
      width: 25,
      url: '/admin/raffles',
      icon: <GiveawaysRafflesIcon />,
      admin: false,
    },
    {
      title: 'Users',
      width: 25,
      url: '/admin/users',
      icon: <UsersIcon />,
      admin: false,
    },
    {
      title: 'Leaderboard',
      width: 25,
      url: '/admin/leaderboard',
      icon: <LeaderboardIcon />,
    },
    {
      title: 'Channels',
      width: 25,
      url: routes.adminChannels,
      icon: <HighlightsIcon />,
    },
  ];

  const isAdmin = authority == 'ADMINISTRATOR';

  const navigationItems: Array<any> = [];

  all.map((i) => {
    if (i.admin) {
      if (isAdmin) {
        navigationItems.push(i);
      }
    } else {
      navigationItems.push(i);
    }
  });

  return (
    <div className={s.navigation}>
      <div className={s.navigation__content}>
        <ul className={s.navigation__list}>
          {navigationItems.map((item, index: number) => (
            <Link to={item.url} key={index}>
              <li
                className={cn(
                  s.navigation__item,
                  pathname.includes(item.url) && s.navigation__item_active,
                )}
              >
                {item.icon}
                <div>
                  <span>{item.title}</span>
                </div>
              </li>
            </Link>
          ))}
        </ul>
      </div>
    </div>
  );
};
