import React from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import { IRaffle, RaffleType } from 'src/@types/raffles';
import moderateRafflesService from 'src/services/moderateRafflesService';
import Button from 'src/ui/Button';
import Divider from 'src/ui/Divider';

import s from './style.module.scss';

import LogoImage from 'src/assets/images/btcs-logo.png';

interface IDeleteRaffleModalProps {
  item: IRaffle | null;
  onDelete?: () => void;
  onClose: () => void;
}

const DeleteRaffleModal = ({
  item,
  onDelete,
  onClose,
}: IDeleteRaffleModalProps) => {
  const queryClient = useQueryClient();

  const { mutate } = useMutation({
    mutationFn: ({ id, type }: { id: string; type: RaffleType }) =>
      moderateRafflesService.deleteRaffle(id),
    onSuccess: (_, { type }) => {
      toast.success('Raffle deleted successfully');

      if (onDelete) {
        onDelete();
      }
      onClose();
    },
    onError: (error: any) => {
      const errorMessage = error?.response?.data?.message || 'Error deleting';
      toast.error(errorMessage);
      console.error('Delete raffle error:', error);
    },
  });

  const onDeleteRaffle = (id: string, type: RaffleType) => {
    mutate({ id, type });
  };

  return (
    <div className={s.block}>
      <div className={s.block__logo}>
        <img src={LogoImage} alt="logo" />
      </div>

      <div className={s.block__info}>
        <div>
          <h2 className={s.block__title}>
            Confirm {item?.type === RaffleType.RAFFLE ? 'Raffle' : 'Giveaway'}{' '}
            Delete
          </h2>
          <p className={s.block__desc}>
            Are you sure you want to delete this{' '}
            {item?.type === RaffleType.RAFFLE ? 'raffle' : 'giveaway'}?
          </p>
        </div>

        <div>
          <Divider />
        </div>

        <div className={s.block__btnContainer}>
          <Button onClick={onClose}>
            <div className={s.block__btnContent}>Cancel</div>
          </Button>
          <Button
            onClick={() => onDeleteRaffle(item!.id, item!.type)}
            isOutlinedWhite
          >
            <div className={s.block__btnContent}>Delete</div>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DeleteRaffleModal;
