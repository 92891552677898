import { Link } from 'react-router';

import { routes } from 'src/routes';
import { calculateVIPRewards, numberWithCommas } from 'src/utils/utils';

import PagePlaceholderText from './PagePlaceholderText';

export const WagerLB = ({
  rankings,
  currency = '$',
}: {
  rankings: any;
  currency?: string;
}) => {
  if (rankings.length === 0) {
    return (
      <div>
        <PagePlaceholderText text={'Coming Soon'} />
      </div>
    );
  }

  return (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
      <div
        className="Account-settings-container Leaderboard-container Leaderboard-container-Less-padding"
        style={{
          width: '800px',
          maxWidth: '100%',
          overflowX: 'scroll',
          overflowY: 'hidden',
          padding: '0',
          borderRadius: '5px',
          border: '3px solid #7e8ab3',
        }}
      >
        {/*{LeaderboardFilterBar(snapshotFilter, setSnapshotFilter)}*/}

        <ul className="Heading">
          <li style={{ paddingLeft: '10px' }}>
            <span>Rank</span>
          </li>
          <li>
            <span>User</span>
          </li>
          <li>
            <span>Wagered</span>
          </li>
          <li>
            <span>LB Prize</span>
          </li>
          <li>
            <span>
              <Link to={routes.vip}>Rewards Earned</Link>
            </span>
          </li>
        </ul>
        {rankings.slice(3).map((entry: any, index: number) => {
          return (
            <ul>
              <li>
                <p>{index + 4}</p>
              </li>
              <li style={{ display: 'flex', justifyContent: 'left' }}>
                <img
                  width={30}
                  style={{ borderRadius: '50%', padding: '0 15px' }}
                  src={entry.image ?? 'https://btcs.gg/newcoin.svg'}
                />
                {entry.username}...
              </li>

              <li>
                {currency}
                {numberWithCommas(entry.totalWagered || entry.wagered)}
              </li>
              <li>
                {currency}
                {entry.prize ? numberWithCommas(entry.prize, 0) : '-'}
              </li>
              <li>
                ${calculateVIPRewards(entry.totalWagered || entry.wagered)}
              </li>
            </ul>
          );
        })}
      </div>
    </div>
  );
};
