import React from 'react';

import { numberWithCommas } from 'src/utils/utils';

import s from './style.module.scss';

import { ReactComponent as CoinsIcon } from 'src/assets/icons/coins.svg';

interface RaffleWagerRequirementsProps {
  wagerRequirement: number;
  title: string;
}

const RaffleWagerRequirements: React.FC<RaffleWagerRequirementsProps> = ({
  wagerRequirement,
  title,
}) => {
  return (
    // <div className={s.requirements}>
    //   <div className={s.requirements__title}>{title}</div>
    //   <div className={s.requirements__content}>
    //     <div className={s.requirements__item}>
    //       <div className={s.requirements__icon}>
    //         <CoinsIcon />
    //       </div>
    //       <div className={s.requirements__text}>
    //         Wager at least <span>{wagerRequirement}</span> to join
    //       </div>
    //     </div>
    //   </div>
    // </div>

    <div className={s.block}>
      {title && <div className={s.block__title}>{title}</div>}

      <div className={s.block__table}>
        <div className={s.block__iconContainer}>
          <CoinsIcon />
          <div className={s.block__value}>
            Wager Required:{' '}
            <span>${numberWithCommas(wagerRequirement || 0)}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RaffleWagerRequirements;
