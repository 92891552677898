import React from 'react';

import Button from 'src/ui/Button';
import Divider from 'src/ui/Divider';

import s from './style.module.scss';

import LogoImage from 'src/assets/images/btcs-logo.png';

interface IDeleteSponsorModalProps {
  title: string;
  text: string;
  onDelete?: () => void;
  onClose: () => void;
}

const DeleteSponsorModal = ({
  title,
  text,
  onDelete,
  onClose,
}: IDeleteSponsorModalProps) => {
  return (
    <div className={s.block}>
      <div className={s.block__logo}>
        <img src={LogoImage} alt="logo" />
      </div>

      <div className={s.block__info}>
        <div>
          <h2 className={s.block__title}>{title}</h2>
          <p className={s.block__desc}>{text}</p>
        </div>

        <div>
          <Divider />
        </div>

        <div className={s.block__btnContainer}>
          <Button onClick={onClose}>
            <div className={s.block__btnContent}>Cancel</div>
          </Button>
          <Button onClick={onDelete} isOutlinedWhite>
            <div className={s.block__btnContent}>Delete</div>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DeleteSponsorModal;
