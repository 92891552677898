import React from 'react';
import { useOutletContext } from 'react-router';

import PendingTransactions from 'src/components/Admin/dashboard/PendingTransactionsTable';
import UsernameVerificationTable from 'src/components/Admin/dashboard/UsernameVerificationTable';

const DashboardAdmin = () => {
  const { stats } = useOutletContext<any>();

  return (
    <div className="App-contents Dashboard">
      <div className="Dashboard-Metrics">
        <div className="MetricCard">
          <h6>{stats ? stats.signupsToday : '-'}</h6>
          <h3>Signups Today</h3>
        </div>
        <div className="MetricCard">
          <h6>{stats ? stats.signupsWeek : '-'}</h6>
          <h3>Signups This Week</h3>
        </div>
        <div className="MetricCard">
          <h6>{stats ? stats.signupsMonth : '-'}</h6>
          <h3>Signups This Month</h3>
        </div>
        <div className="MetricCard">
          <h6>{stats ? stats.rewardsClaimed : '-'}</h6>
          <h3>Rewards Claimed</h3>
        </div>
        <div className="MetricCard">
          <h6>{stats ? stats.casesOpened : '-'}</h6>
          <h3>Cases Opened</h3>
        </div>

        <a
          href="https://stats.jcy.gg/share/dyeEJdk57xpziI3c/BTCS.GG"
          target="_action"
        >
          <button>Stats</button>
        </a>
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', gap: '80px' }}>
        <PendingTransactions />

        <UsernameVerificationTable />
      </div>
    </div>
  );
};

export default DashboardAdmin;
