import { DateTime } from 'luxon';

import { ISponsorDefinition } from 'src/@types/leaderboard';
import { VIP_TIERS } from 'src/components/vip/constants';
import { DEFAULT_TIME_ZONE } from 'src/constants/time';

// export const getCurrentTierIndex = (wagered: number): number => {
//   let returnIndex = -1;

//   VIP_TIERS.map((tier, index) => {
//     if (tier.req > wagered && returnIndex == -1) {
//       returnIndex = index - 1;
//     }
//   });

//   if (returnIndex != -1) {
//     return returnIndex;
//   }

//   return 0;
// };

export function getLastAndNextReward(
  value: number,
  rewards: ISponsorDefinition[],
): { last: ISponsorDefinition | null; next: ISponsorDefinition | null } {
  const sortedRewards = rewards.sort((a, b) => a.value - b.value);

  let last: ISponsorDefinition | null = null;
  let next: ISponsorDefinition | null = null;

  for (let i = 0; i < sortedRewards.length; i++) {
    if (sortedRewards[i].value <= value) {
      last = sortedRewards[i];
    } else {
      next = sortedRewards[i];
      break;
    }
  }

  return { last, next };
}

export function getFirstWord(name) {
  if (!name) {
    return '';
  }

  return name.split(' ')[0];
}

export const getCurrentTierIndex = (wagered: number): number => {
  let returnIndex = 0;

  for (let i = 0; i < VIP_TIERS.length; i++) {
    if (wagered >= VIP_TIERS[i].req) {
      returnIndex = i;
    } else {
      break;
    }
  }

  return returnIndex;
};

export const getCompletionPerCentage = (wagered: number): number => {
  const required = VIP_TIERS[getCurrentTierIndex(wagered) + 1].req;
  if (required > 0) {
    return (wagered / required) * 100;
  }
  return 0.0;
};

export const getCurrentMonthInEST = (): string => {
  const currentDateInEST = DateTime.now().setZone(DEFAULT_TIME_ZONE);

  return currentDateInEST.toFormat('MMMM');
};
