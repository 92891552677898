import React, { useEffect, useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
// import { makeStyles } from '@material-ui/core/styles';
import { makeStyles } from '@mui/styles';

import { ConnectKickModal } from 'src/components/SettingsModal';
import SuccsessUpdateProfileModal from 'src/components/SettingsModal/SuccsessUpdateProfileModal';
import useModalManager from 'src/hooks/useModalManager';
import Button from 'src/ui/Button';

import { PlayerSettingsApi } from '../types';
import { axiosGet, axiosPost } from '../utils/httpClient';

import '../assets/CSS/Profile.scss';
import '../assets/CSS/Settings.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      // margin: theme.spacing(1),
      width: '25ch',
    },
  },
  gridList: {
    width: '100%',
    height: 'auto',
  },
  card: {
    minWidth: 100,
    maxHeight: 100,
    height: '100%',
    backgroundColor: 'transparent !important',
    margin: '15px',
    border: 'none',
    boxShadow: 'none',
  },
  cardContent: {
    backgroundSize: 'cover',
    backgroundColor: '#080808',
    height: '56px',
    border: '2px solid #2a2e38',
    textAlign: 'left',
    fontSize: '11px !important',
    color: '#cecece',
  },
}));

const getProfileSettings = async (): Promise<PlayerSettingsApi> =>
  axiosGet(`/player/settings`);
const setProfileSettings = async (
  request: PlayerSettingsApi,
): Promise<PlayerSettingsApi> => axiosPost(`/player/settings`, request);

export const PlayerSettings = () => {
  const [profile, setProfile] = useState<PlayerSettingsApi | undefined>(
    undefined,
  );

  /** General Settings */
  const [steamTradeUrl, setSteamTradeUrl] = useState<string | undefined>(
    undefined,
  );
  const [ethAddress, setEthAddress] = useState<string | undefined>(undefined);
  const [btcAddress, setBtcAddress] = useState<string | undefined>(undefined);
  const [shuffleUsername, setShuffleUsername] = useState<string | undefined>(
    undefined,
  );

  const [csgoBigUserId, setCsgoBigUserId] = useState<string | undefined>(
    undefined,
  );
  const [empireDropUserId, setEmpireDropUserId] = useState<string | undefined>(
    undefined,
  );

  /** Connected Accounts */
  const [discordConnected, setDiscordConnected] = useState<boolean>(false);
  const [kickConnected, setKickConnected] = useState<boolean>(false);
  const [openKick, setOpenKick] = React.useState(false);

  useEffect(() => {
    getProfileSettings()
      .then((prof: PlayerSettingsApi) => {
        setProfile(prof);
        setSteamTradeUrl(prof.steamTradeUrl);
        setEthAddress(prof.ethWalletAddress);
        setBtcAddress(prof.btcWalletAddress);
        setShuffleUsername(prof.shuffleUsername);
        setCsgoBigUserId(prof.csgoBigUserId);
        setEmpireDropUserId(prof.empireDropUserId);
        setKickConnected(!!prof.kickConnected);
        setDiscordConnected(!!prof.discordConnected);
      })
      .catch((e) => {
        //@ts-ignore
        // window.location.href = '/';
      });
  }, []);

  if (!profile) {
    return <div className="App-contents" />;
  }

  return (
    <>
      <div className="App-contents">
        <div className="Profile-general">
          <div
            className="Account-settings-container"
            style={{ width: 'calc(100% - 20px)', overflow: 'hidden' }}
          >
            <div className="Profile-Headeing">
              <h4>General</h4>
            </div>
            <AccountSettingsForm
              ethAddress={ethAddress}
              btcAddress={btcAddress}
              shuffleUsername={shuffleUsername}
              csgoBigUserId={csgoBigUserId}
              empireDropUserId={empireDropUserId}
              steamTradeUrl={steamTradeUrl}
              setProfile={setProfile}
              setEthAddress={setEthAddress}
              setBtcAddress={setBtcAddress}
              setShuffleUsername={setShuffleUsername}
              setCsgoBigUserId={setCsgoBigUserId}
              setEmpireDropUserId={setEmpireDropUserId}
            />
          </div>
        </div>
        <div
          className="Account-settings-container"
          style={{ width: 'calc(100% - 20px)' }}
        >
          <div className="Profile-Headeing">
            <h4>Connect Accounts</h4>
          </div>
          <ConnectAccounts
            discordConnected={discordConnected}
            kickConnected={kickConnected}
            openKick={openKick}
            setOpenKick={setOpenKick}
          />
        </div>
      </div>
    </>
  );
};

const AccountSettingsForm = ({
  ethAddress,
  btcAddress,
  shuffleUsername,
  csgoBigUserId,
  empireDropUserId,
  steamTradeUrl,
  setProfile,
  setEthAddress,
  setBtcAddress,
  setShuffleUsername,
  setCsgoBigUserId,
  setEmpireDropUserId,
}: any) => {
  const { isModalOpen, openModal, closeModal } = useModalManager();
  const [updatingDetails, setUpdatingDetails] = useState<boolean>(false);

  const [ethValidity, setEthValidity] = useState<number>(0);
  const [btcValidity, setBtcValidity] = useState<number>(0);
  const [shuffleValidity, setShuffleValidity] = useState<number>(0);
  const [csgoBigValidity, setCsgoBigValidity] = useState<number>(0);
  const [empireDropValidity, setEmpireDropValidity] = useState<number>(0);

  const validateEth = () => {
    const regex = new RegExp(/^(0x)?[0-9a-fA-F]{40}$/);
    let valide = false;

    if (ethAddress == null) {
      setEthValidity(2);
      return false;
    }
    if (regex.test(ethAddress!) == true) {
      setEthValidity(1);
      valide = true;
    } else {
      setEthValidity(2);
      valide = false;
    }

    return valide;
  };

  const validateBtc = () => {
    if (!btcAddress) setBtcValidity(0);

    const regex = new RegExp(
      /^(bc1[a-z0-9]{8,90}|[13][a-km-zA-HJ-NP-Z1-9]{25,34}|tb1[a-z0-9]{8,90})$/,
    );

    let valide = false;

    if (btcAddress == null) {
      setBtcValidity(2);
      valide = false;
    }
    if (regex.test(btcAddress!) == true) {
      setBtcValidity(1);
      valide = true;
    } else {
      setBtcValidity(2);
      valide = false;
    }

    return valide;
  };

  const validateShuffle = () => {
    let valide = false;

    if (!shuffleUsername || shuffleUsername.length < 3) {
      setShuffleValidity(2);
      valide = false;
      return;
    } else {
      valide = true;
      setShuffleValidity(1);
    }

    return valide;
  };

  const validateCsgoBig = () => {
    let valide = false;

    if (!csgoBigUserId || csgoBigUserId.length < 3) {
      setCsgoBigValidity(2);
      valide = false;
      return;
    } else {
      valide = true;
      setCsgoBigValidity(1);
    }

    return valide;
  };

  const validateEmpireDrop = () => {
    let valide = false;

    if (!empireDropUserId || empireDropUserId.length < 3) {
      setEmpireDropValidity(2);
      valide = false;
      return;
    } else {
      valide = true;
      setEmpireDropValidity(1);
    }

    return valide;
  };

  const onUpdateProfile = () => {
    if (updatingDetails) {
      return;
    }

    const newSteamTradeUrl = steamTradeUrl ?? '';
    const newEthAddress = ethAddress ?? '';
    const newBtcAddress = btcAddress ?? '';
    const newShuffleUsername = shuffleUsername ?? '';
    const newCsgoBigUserId = csgoBigUserId ?? '';
    const newEmpireDropUserId = empireDropUserId ?? '';

    const isBtcValid = newBtcAddress ? validateBtc() : true;
    const isEthValid = newEthAddress ? validateEth() : true;
    const isShuffleValid = validateShuffle();
    const isCsgoBigValid = validateCsgoBig();
    const isEmpireDropValid = validateEmpireDrop();

    if (!newShuffleUsername || !isShuffleValid) {
      return;
    }

    if ((newBtcAddress && !isBtcValid) || (newEthAddress && !isEthValid)) {
      return;
    }

    const body: PlayerSettingsApi = {
      steamTradeUrl: newSteamTradeUrl,
      shuffleUsername: newShuffleUsername,
      casesGgUsername: '',
      csgoBigUserId: newCsgoBigUserId,
      empireDropUserId: newEmpireDropUserId,
    };

    if (newBtcAddress && isBtcValid) {
      body.btcWalletAddress = newBtcAddress;
    }

    if (newEthAddress && isEthValid) {
      body.ethWalletAddress = newEthAddress;
    }

    setUpdatingDetails(true);
    setProfileSettings(body)
      .then((response) => {
        setProfile(response);
        openModal('success-update-profile');
      })
      .finally(() => {
        setUpdatingDetails(false);
      });
  };

  const ethSelector = ethValidity === 2 ? 'Invalid-field' : '';
  const btcSelector = btcValidity === 2 ? 'Invalid-field' : '';
  const shuffleSelector = shuffleValidity === 2 ? 'Invalid-field' : '';
  const csgoBigSelector = csgoBigValidity === 2 ? 'Invalid-field' : '';
  const empireDropSelector = empireDropValidity === 2 ? 'Invalid-field' : '';

  return (
    <>
      <div className="Btcs-form PlayerSettingsForm">
        <div className="Form-input-field">
          <h6>ETH Address</h6>
          <div className={'Form-input ' + ethSelector}>
            {/* <div className='Form-input'> */}
            <div className="Form-icon">
              <img src="/profile/eth.svg" />
            </div>
            <input
              type="text"
              placeholder="ETH Address..."
              value={ethAddress}
              onChange={(e) => {
                if (e.target.value.length === 0) {
                  setEthValidity(0);
                }
                setEthAddress(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="Form-input-field">
          <h6>BTC Address</h6>
          <div className={'Form-input ' + btcSelector}>
            {/* <div className='Form-input'> */}
            <div className="Form-icon">
              <img src="/navigation/btc.svg" />
            </div>
            <input
              type="text"
              placeholder="BTC Address..."
              value={btcAddress}
              onChange={(e) => {
                if (e.target.value.length === 0) {
                  setBtcValidity(0);
                }
                setBtcAddress(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="Form-input-field">
          <h6>Shuffle Username</h6>
          <div className={'Form-input ' + shuffleSelector}>
            {/* <div className='Form-input'> */}
            <div className="Form-icon">
              <img src="/profile/shuffle.png" />
            </div>
            <input
              type="text"
              placeholder="Shuffle Username..."
              value={shuffleUsername}
              onChange={(e) => {
                if (e.target.value.length === 0) {
                  setShuffleValidity(0);
                }
                setShuffleUsername(e.target.value);
              }}
            />
          </div>
        </div>

        <div className="Form-input-field">
          <h6>CSGOBig User ID</h6>
          <div className={'Form-input ' + csgoBigSelector}>
            <div className="Form-icon">
              <img src="/profile/csgobig.png" />
            </div>

            <input
              type="text"
              placeholder="CSGOBig User ID..."
              value={csgoBigUserId}
              onChange={(e) => setCsgoBigUserId(e.target.value)}
            />
          </div>
        </div>

        <div className="Form-input-field">
          <h6>Empire Drop ID</h6>
          <div className={'Form-input ' + empireDropSelector}>
            <div className="Form-icon">
              <img src="/profile/empiredrop.png" />
            </div>

            <input
              type="text"
              placeholder="Empire Drop ID..."
              value={empireDropUserId}
              onChange={(e) => setEmpireDropUserId(e.target.value)}
            />
          </div>
        </div>

        <div className="Form-submission">
          <Button borderRadius="6px" onClick={onUpdateProfile}>
            {updatingDetails ? 'Updating...' : 'Update Profile'}
          </Button>
        </div>
      </div>

      <SuccsessUpdateProfileModal
        open={isModalOpen('success-update-profile')}
        onClose={() => closeModal('success-update-profile')}
      />
    </>
  );
};

const ConnectAccounts = ({
  discordConnected,
  kickConnected,
  openKick,
  setOpenKick,
}: any) => {
  return (
    <div className="Account-integrations">
      <div className="Integrations-list">
        {discordConnected ? (
          <div className="Integration-option">
            <div className="Integration Connected">
              <img src="/profile/discord.svg" />
              <div className="Title">
                <span>Discord</span>
              </div>
              <CheckIcon />
            </div>
            <div className="Integration-heading">
              <span>Connected</span>
            </div>
          </div>
        ) : (
          <div
            className="Integration-option"
            onClick={() => {
              localStorage.setItem(
                'discord_integration',
                `${new Date().getTime() + 60_000}`,
              );

              const encodedRedirectUri =
                process.env.REACT_APP_TWITCH_REDIRECT_URI;
              window.location.href = `https://discord.com/oauth2/authorize?client_id=1231647510033072219&redirect_uri=${encodedRedirectUri}&response_type=code&scope=identify`;
            }}
          >
            <div className="Integration">
              <img src="/profile/discord.svg" />
              <div className="Title">
                <span>Discord</span>
              </div>
            </div>
            <div className="Integration-heading">
              <span>Not Connected</span>
            </div>
          </div>
        )}
        {kickConnected ? (
          <div className="Integration-option">
            <div className="Integration Connected">
              <img src="/profile/kick.svg" />
              <div className="Title">
                <span>Kick</span>
                <CheckIcon />
              </div>
            </div>
            <div className="Integration-heading">
              <span>Connected</span>
            </div>
          </div>
        ) : (
          <div className="Integration-option" onClick={() => setOpenKick(true)}>
            <div className="Integration">
              <img src="/profile/kick.svg" />
              <div className="Title">
                <span>Kick</span>
              </div>
            </div>
            <div className="Integration-heading">
              <span>Not Connected</span>
            </div>
          </div>
        )}
      </div>
      <ConnectKickModal
        key={'integrate-kick'}
        open={openKick}
        onClose={() => setOpenKick(false)}
      />
    </div>
  );
};
