import React, { useEffect, useMemo, useState } from 'react';
import { Dialog } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import { Link, useLocation, useNavigate } from 'react-router';

import { ISponsor } from 'src/@types/leaderboard';
import EndCounter from 'src/components/leaderboard/EndCounter';
import Filters from 'src/components/leaderboard/Filters';
import SponsorTabs from 'src/components/leaderboard/SponsorTabs';
import TopLeaderboard from 'src/components/leaderboard/TopLeaderboard';
import SpinnerLoading from 'src/components/loadings/SpinnerLoading';
import { WagerLB } from 'src/components/WagerLB';
import { DEFAULT_TIME_ZONE } from 'src/constants/time';
import useTimeToEnd, { TimeToEnd } from 'src/hooks/useTimeToEnd';
import { dialogStyles } from 'src/layouts/AdminLayout';
import { routes } from 'src/routes';
import publicConroller from 'src/services/leaderboard/publicController';
import publicController from 'src/services/leaderboard/publicController';
import Button from 'src/ui/Button';
import { getFirstDayOfPreviousMonth } from 'src/utils/date';

import 'src/assets/CSS/Leaderboard.scss';
import s from './style.module.scss';

export default function Leaderboard() {
  const nextMonth = DateTime.now().setZone(DEFAULT_TIME_ZONE).endOf('month');
  const timeToNextMonth = useTimeToEnd(nextMonth);
  const navigate = useNavigate();
  const location = useLocation();

  const [tab, setTab] = useState<number>(0);
  const [open, setRulesOpen] = useState<boolean>(false);
  const [endOfMonth, setEndOfMonth] = useState<null | TimeToEnd>(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setEndOfMonth(timeToNextMonth);
    }, 1000);
    return () => clearInterval(interval);
  }, [timeToNextMonth]);

  const { data: sponsorsData = [], isLoading: isFetchingSponsors } = useQuery<
    ISponsor[]
  >({
    queryKey: ['sponsors'],
    queryFn: () => publicController.getSponsors(),
  });

  const currentSponsor = location.state?.sponsor;
  const sponsorId = location.state?.sponsor?.id;
  const isCanFetchSponsorData = sponsorsData.length !== 0 && !!sponsorId;

  const { data: sponsorUsersData = [], isLoading: isFetchingSponsorUsers } =
    useQuery<ISponsor[]>({
      queryKey: ['sponsors-users', sponsorId],
      queryFn: () => publicConroller.getSponsorVipUsers({ id: sponsorId }),
      enabled: isCanFetchSponsorData,
      staleTime: 1000 * 60,
    });

  const leaderboardTopData = useMemo(() => {
    if (sponsorUsersData.length > 0) {
      return sponsorUsersData.slice(0, 3);
    }
    return [];
  }, [sponsorUsersData]);

  const {
    data: sponsorSnapshot = [],
    isLoading: isFetchingSponsorSnapshot,
    isFetched: isFetchedSponsorStapshot,
  } = useQuery<ISponsor[]>({
    queryKey: ['sponsors-snapshot', sponsorId],
    queryFn: () =>
      publicConroller.getSponsorSnapshot({
        id: sponsorId,
        //TODO: change to previous month
        date: getFirstDayOfPreviousMonth(),
      }),
    enabled: isCanFetchSponsorData,
    staleTime: 1000 * 60,
  });

  const snapshotTop = useMemo(() => {
    if (sponsorSnapshot.length > 0) {
      return sponsorSnapshot.slice(0, 3);
    }
    return [];
  }, [sponsorSnapshot]);

  const isSponsorSnapshotEmpty =
    sponsorSnapshot.length === 0 &&
    isFetchedSponsorStapshot &&
    !isFetchingSponsorSnapshot;

  const onClickSponsor = (sponsor: ISponsor) => {
    const urlSponsorName = sponsor.name.replace(/\s+/g, '');

    navigate(routes.leaderboardSponsor.replace(':id', urlSponsorName), {
      state: { sponsor },
    });
  };

  return (
    <>
      <div className={s.page}>
        {isFetchingSponsors ? (
          <div>Loading...</div>
        ) : (
          <SponsorTabs
            sponsorId={sponsorId}
            sponsors={sponsorsData}
            onClick={onClickSponsor}
          />
        )}

        <div className={s.coins}>
          <div className={s.coins__gold}>
            <img src="/coin/goldcoin.png" />
          </div>
          <div className={s.coins__silver}>
            <img src="/coin/silvercoin2.png" />
          </div>
        </div>

        <div className="Landing-heading Heading-Leaderboard">
          <h2
            className="Main-heading"
            style={{ fontSize: '2.9vw', margin: '0', padding: '0' }}
          >
            <span style={{ fontFamily: 'Helvetica', color: 'white' }}>
              {currentSponsor?.currency || '$'}
            </span>
            {currentSponsor?.commonPrize}
          </h2>
          <h2 className="Main-heading" style={{ margin: '0', padding: '0' }}>
            {currentSponsor?.name}
          </h2>
          <h2 className="Main-heading" style={{ margin: '0', padding: '0' }}>
            Leaderboard
          </h2>
          <div className="Heading-divider" />
          <div className="Leaderboard-btn ">
            <div
              style={{
                display: 'flex',
                gap: 20,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Button
                onClick={() => setRulesOpen(!open)}
                borderRadius="6px"
                className=""
                customStyle={{ width: 'auto' }}
              >
                <img width={15} src="https://btcs.gg/coin/rules.svg" />
                Rules
              </Button>
              <Link
                style={{ display: 'block', width: 'auto' }}
                to={routes.challenges}
              >
                {currentSponsor?.name === 'Shuffle' && (
                  <Button borderRadius="6px">Exclusive Challenges</Button>
                )}
              </Link>
            </div>
          </div>
        </div>

        <Filters
          tab={tab}
          setTab={(value) => setTab(value)}
          isShowPreviousMounthTab={
            (sponsorUsersData.length !== 0 && !isSponsorSnapshotEmpty) ||
            (sponsorUsersData.length === 0 && !isSponsorSnapshotEmpty)
          }
        />

        <>
          <TopLeaderboard
            topData={tab === 0 ? leaderboardTopData : snapshotTop}
            currency={currentSponsor?.currency || '$'}
          />

          {tab === 0 && <EndCounter time={endOfMonth} />}
        </>

        {isFetchingSponsorUsers || isFetchingSponsorSnapshot ? (
          <SpinnerLoading />
        ) : (
          <WagerLB
            rankings={tab === 1 ? sponsorSnapshot : sponsorUsersData}
            currency={currentSponsor?.currency || '$'}
          />
        )}

        <RulesModal
          text={currentSponsor?.rules ?? ''}
          open={open}
          onClose={() => setRulesOpen(!open)}
        />
      </div>
    </>
  );
}

function RulesModal({
  text,
  onClose,
  open,
}: {
  text: string;
  open: boolean;
  onClose: () => void;
}) {
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      PaperProps={{ style: dialogStyles }}
    >
      <h2>Leaderboard Rules</h2>
      <br />

      <div className="rules">
        <p style={{ whiteSpace: 'pre-wrap' }}>{text}</p>
      </div>
    </Dialog>
  );
}
