import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import HomeIcon from '@mui/icons-material/Home';
import cn from 'classnames';
import { Link } from 'react-router';

import { routes } from 'src/routes';
import authService from 'src/services/authService';
import useAuthStore from 'src/store/authStore';
import Button from 'src/ui/Button';
import { isUserAdmin } from 'src/utils/permissions';

import { Login } from '../Header';

interface IMenuBurgerProps {
  isOpen: boolean;
  onClose: () => void;
}

const MenuOptions = [
  {
    name: 'Home',
    icon: <HomeIcon />,
    link: routes.home,
  },
  {
    name: 'Wager Rewards',
    icon: <img width={25} src="/navigation/icons/vip-09 copy.svg" />,
    link: routes.vip,
  },
  {
    name: 'Casino',
    icon: <img width={25} src="/navigation/icons/casino copy.svg" />,
    link: routes.casino,
  },
  {
    name: 'Slot Battles',
    icon: <img width={25} src="/navigation/icons/vs copy.svg" />,
    link: routes.battles,
  },
  {
    name: 'Challenges',
    icon: <img width={25} src="/navigation/icons/challenges copy.svg" />,
    link: routes.challenges,
  },
  {
    name: 'Hunts',
    icon: <img width={25} src="/navigation/icons/hunts copy.svg" />,
    link: routes.hunts,
  },
  {
    name: 'Store',
    icon: <img width={25} src="/navigation/icons/cart copy.svg" />,
    link: routes.store,
  },
  {
    name: 'Highlights',
    icon: <img width={25} src="/navigation/icons/highlights copy.svg" />,
    link: routes.highlights,
  },
  {
    name: 'Raffles',
    icon: <img width={25} src="/navigation/icons/raffles.svg" />,
    link: routes.raffles,
  },
  {
    name: 'Giveaways',
    icon: <img width={25} src="/navigation/icons/giveaway copy.svg" />,
    link: routes.giveaways,
  },
  {
    name: 'Leaderboard',
    icon: <img width={25} src="/navigation/icons/leaderboard copy.svg" />,
    link: routes.leaderboard,
  },
  {
    name: 'Profile',
    icon: <img width={25} src="/navigation/icons/profile.svg" />,
    link: routes.profile,
    protected: true, // This item is visible when user is logged in
  },
  {
    name: 'Settings',
    icon: <img width={25} src="/navigation/icons/setting.svg" />,
    link: routes.settings,
    protected: true, // This item is visible when user is logged in
  },
  {
    name: 'Admin',
    icon: <img width={25} src="/navigation/icons/admin.svg" />,
    link: routes.adminDashboard,
    protected: true, // This item is visible only for admins
    adminOnly: true,
  },
];

const MenuBurger = ({ isOpen, onClose }: IMenuBurgerProps) => {
  const { user, setUser } = useAuthStore();
  const { url } = Login();
  const isAdmin = isUserAdmin(user);

  const onLogout = async () => {
    await authService.handleLogout();
    setUser(null);
  };

  return (
    <div
      className={cn(`Mobile-navigation`, !isOpen && 'Wagers-toggle-hidden')}
      onClick={onClose}
    >
      <div className="Mobile-Close">
        <div className="Action">
          <CloseIcon />
        </div>
        <div className="Text">
          <p>Menu</p>
        </div>
      </div>
      <div className="Mobile-Balance">
        <div className="Balance">
          <img src="/currency.svg" />
          <span>{user?.points.toLocaleString('en-US') ?? 0}</span>
        </div>
      </div>
      <div className="Mobile-Profile">
        <div className="Profile-image">
          <img src={user?.profilePicture ?? '/def.png'} />
        </div>
        <div className="Profile-name">
          {user ? (
            <span>{user.username}</span>
          ) : (
            <Link to={url}>
              <Button customStyle={{ borderRadius: '6px' }}>Login</Button>
            </Link>
          )}
        </div>
        <div className="Profile-rank">
          {user ? <span>{user.authority}</span> : <></>}
        </div>
      </div>

      <div className="Mobile-Menu">
        <ul>
          {MenuOptions.map((option, index) => {
            if (option.protected && !user) return null;
            if (option.adminOnly && !isAdmin) return null;

            return (
              <li key={index}>
                <Link to={option.link}>
                  <div className="Menu-icon">{option.icon}</div>
                  <div className="Menu-option">
                    <span>{option.name}</span>
                  </div>
                </Link>
              </li>
            );
          })}

          <li>
            <Link to={routes.home} onClick={onLogout}>
              <div className="Menu-icon">
                <img width={25} src="/navigation/icons/logout-13.svg" />
              </div>
              <div className="Menu-option">
                <span>Logout</span>
              </div>
            </Link>
          </li>
        </ul>
      </div>

      <div className="Mobile-Tos">
        <div className="Tos">
          <Link className="Tos__link" to={routes.privacyPolicy}>
            Privacy Policy
          </Link>
          <Link className="Tos__link" to={routes.tos}>
            Terms of Service
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MenuBurger;
