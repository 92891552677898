export enum SponsorStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export interface ISponsorImage {
  id: string;
  mediaType: string;
  addedOn: string;
  url: string;
}

export interface ISponsor {
  id: string;
  name: string;
  logo?: {
    id: string;
    mediaType: string;
    addedOn: string;
    url: string;
  };
  syncUrl: string;
  syncUrlToken: string;
  rules: string;
  commonPrize: string;
  numberOfVisibleUsers: number;
  currency: string;
  status?: SponsorStatus;
  token: string;
}

export enum SponsorDefinitionStatus {
  COMPLETED = 'COMPLETED',
  PENDING = 'PENDING',
  CANCELLED = 'CANCELLED',
}

export interface ISponsorDefinition {
  id?: number;
  name: string;
  description: string;
  image: string;
  expired: boolean;
  sponsorId: string;
  value: number;
  status?: SponsorDefinitionStatus | null;
}

export interface ISponsorUser {
  username: string;
  totalWagered?: number;
  wagered?: number;
  prize: string;
  image: string;
}

export interface ISponsorPrize {
  id?: string;
  sponsorId?: string;
  prize: number;
  addedTime?: string;
}

export interface ISponsorBlacklistItem {
  id?: string;
  sponsorId?: string;
  username: string;
  addedTime?: string;
}
