import * as Yup from 'yup';

export const INITIAL_VALUES = {
  name: '',
  channelId: '',
  numberOfItemsToFetch: 0,
};

export const FORM_VALIDATION_SCHEMA = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  channelId: Yup.string().required('Channel Id is required'),
  numberOfItemsToFetch: Yup.number().required(
    'Number of videos field is required',
  ),
});
