import React from 'react';
import MUICheckbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

interface CheckboxProps {
  name: string;
  label?: string;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

const Checkbox: React.FC<CheckboxProps> = ({
  name,
  label,
  checked,
  onChange,
  disabled,
}) => {
  return (
    <FormControlLabel
      control={
        <MUICheckbox
          sx={{
            '&.MuiCheckbox-root': {
              color: 'white',
              '&:hover': {
                backgroundColor: 'transparent',
              },
            },
            '& .MuiCheckbox-root.Mui-checked': {
              color: 'white',
            },
          }}
          name={name}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
        />
      }
      label={label}
      sx={{
        alignItems: 'start',

        '& .MuiCheckbox-root': {
          paddingTop: '0px',
        },

        '& .MuiFormControlLabel-label': {
          fontSize: '18px',
          fontWeight: 500,
          lineHeight: '24.55px',
          textAlign: 'left',
          textDecoration: 'none',
        },
      }}
    />
  );
};

export default Checkbox;
