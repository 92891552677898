import React, { useEffect, useState } from 'react';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router';

import { Streamer } from '../types';
import { axiosGet } from '../utils/httpClient';

import '../assets/CSS/App.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      // margin: theme.spacing(1),
      width: '25ch',
    },
  },
  gridList: {
    width: '100%',
    height: 'auto',
  },
  card: {
    height: '120px',
    background: '#1b1d27 !important',
    // height: "100%",
    // backgroundColor: 'transparent !important',
    paddingTop: '15px',
    borderRadius: '15px !important',
    border: 'none !important',
    boxShadow: 'none !important',
    position: 'relative',
    transition: '.3s',
  },
  cardMedia: {
    // paddingTop: '81.25%',
    borderRadius: '50%',
    width: '80px !important',
    height: '80px',
    top: '30px',
    left: '20px',
    position: 'absolute',
    // margin: '28px',
    boxShadow:
      '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 4px 4px 0px rgba(0, 0, 0, 0.14), 0px 4px 7px 0px rgba(0, 0, 0, 0.12) !important',
    // boxShadow: 'none',
    border: 'none !important',
    zIndex: 100,
    transition: '.3s',
  },
  cardContent: {
    height: '80px',
    paddingLeft: '120px !important',
    paddingTop: '15px !important',
    // borderTopLeftRadius: '5px',
    // borderTopRightRadius: '5px',
    // boxShadow: '#2a2e38 1rem 1rem 1rem',
    border: 'none !important',
    borderRadius: '15px',
    color: 'white',
    textAlign: 'left',
  },
}));

const getStreamersList = async (): Promise<Streamer[]> =>
  axiosGet(`/general/streamers`);

export default function Streamers() {
  const [data, setData] = useState<Streamer[]>([]);

  useEffect(() => {
    getStreamersList().then((response) => {
      setData(response);
    });
  }, []);

  const classes = useStyles();
  const ordered = ['BTCs', 'Lance', 'Hunterowner', 'GrayGray', 'd3-rs'];

  return (
    <>
      {data && data.length > 0 ? (
        ordered.map((key) => {
          const tile = data.find((tile) => tile.channelName == key)!;
          return tile ? (
            <Card className={classes.card + ' CardCardHover'}>
              <CardMedia
                component="img"
                alt={tile.channelName}
                className={classes.cardMedia + ' CardMedia'}
                image={tile.channelImage}
              />
              <Link to={tile.channelUrl}>
                <CardContent className={classes.cardContent + ' CardHover'}>
                  <Typography gutterBottom variant="h6" component="div">
                    {tile.channelName}
                  </Typography>
                  <Typography variant="body2">
                    <span
                      className={`StatusSymbol ${
                        tile.live ? 'online' : 'offline'
                      }`}
                    >
                      <FontAwesomeIcon
                        className={`StatusSymbol ${
                          !tile.live ? 'offline' : 'online'
                        }`}
                        icon={faCircle}
                      />
                      {!tile.live ? 'Offline' : 'Live'}
                    </span>
                  </Typography>
                </CardContent>
              </Link>
            </Card>
          ) : null;
        })
      ) : (
        <></>
      )}
    </>
  );
}
