import React from 'react';

import { ISponsor, ISponsorDefinition } from 'src/@types/leaderboard';
import {
  getCompletionPerCentage,
  getFirstWord,
  getLastAndNextReward,
} from 'src/components/vip/utils';
import { PlayerDetails } from 'src/types';

import 'src/assets/CSS/VIP.scss';

const CurrentTierProgress = ({
  user,
  sponsorDefinitions,
  currentSponsor,
}: {
  user: PlayerDetails | undefined;
  sponsorDefinitions: ISponsorDefinition[];
  currentSponsor: ISponsor | undefined | null;
}) => {
  if (!user) {
    return (
      <div className="Tier-Progress-container">
        <div className="Tier-Progression">
          <div className="Progression-Current-Tier">
            <ul>
              <li>
                <img src="https://shuffle.com/images/vip/wood.svg" />
              </li>
              <li>
                <span>Wood tier</span>
              </li>
            </ul>
          </div>
          <div className="Progression-Progress">
            <div className="Progression-Progress-bar">
              <progress value="0" max="100" />
            </div>
            <div className="Progression-Progress-value">
              <span>0%</span>
            </div>
          </div>
          <div className="Progression-Next-Tier">
            <ul>
              <li>
                <img src="https://shuffle.com/images/vip/bronze.svg" />
              </li>
              <li>
                <span>Bronze Tier</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }

  const wageredAmount = currentSponsor?.name
    ? (user.shuffleWagered?.[currentSponsor.name] ?? 0)
    : 0;

  console.log('wageredAmount', wageredAmount);

  return (
    <div className="Tier-Progress-container">
      <div className="Tier-Progression">
        <div className="Progression-Current-Tier">
          <ul>
            <li>
              <img
                src={
                  getLastAndNextReward(wageredAmount, sponsorDefinitions)?.last
                    ?.image
                }
              />
            </li>
            <li>
              <span>
                {getFirstWord(
                  getLastAndNextReward(wageredAmount, sponsorDefinitions)?.last
                    ?.name,
                )}
              </span>
            </li>
          </ul>
        </div>
        <div className="Progression-Progress">
          <div className="Progression-Progress-bar">
            <progress
              value={wageredAmount}
              max={
                getLastAndNextReward(wageredAmount, sponsorDefinitions)?.next
                  ?.value ?? 0
              }
            />
          </div>
          <div className="Progression-Progress-value">
            <span>{getCompletionPerCentage(wageredAmount).toFixed(2)}%</span>
          </div>
        </div>
        <div className="Progression-Next-Tier">
          <ul>
            <li>
              <img
                src={
                  getLastAndNextReward(wageredAmount, sponsorDefinitions)?.next
                    ?.image
                }
              />
            </li>
            <li>
              <span>
                {getFirstWord(
                  getLastAndNextReward(wageredAmount, sponsorDefinitions)?.next
                    ?.name,
                )}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CurrentTierProgress;
