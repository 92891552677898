import React from 'react';

import TopLeaderboardSlider from '../TopLeaderboardSlider';
import TopUserCard from '../TopUserCard';

import s from './style.module.scss';

interface ITopLeaderboardProps {
  topData: any[];
  currency?: string;
}

const TopLeaderboard = ({ topData, currency = '$' }: ITopLeaderboardProps) => {
  if (topData.length === 0) {
    return null;
  }

  return (
    <div>
      <div className={s.root__desktop}>
        <div className={s.pedestal}>
          <div className={s.pedestal__content}>
            <div className={s.pedestal__rankings}>
              <TopUserCard
                type="second"
                user={topData[1]}
                currency={currency}
              />
              <TopUserCard type="first" user={topData[0]} currency={currency} />
              <TopUserCard type="third" user={topData[2]} currency={currency} />
            </div>
          </div>
        </div>
      </div>

      <div className={s.root__mobile}>
        <TopLeaderboardSlider data={topData} />
      </div>
    </div>
  );
};

export default TopLeaderboard;
