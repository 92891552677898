import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Link, useSearchParams } from 'react-router';
import { useLocation } from 'react-router';

import { IRaffle, RaffleType } from 'src/@types/raffles';
import TableHeader from 'src/components/Admin/leaderboard/table/TableHeader';
import CreatedSuccessModal from 'src/components/Admin/modals/CreatedSuccessModal';
import DeleteRaffleModal from 'src/components/Admin/modals/DeleteRaffleModal';
import GiveawayModal from 'src/components/Admin/modals/GiveawayModal/GiveawayModal';
import RaffleModal from 'src/components/Admin/modals/RaffleModal/RaffleModal';
import RaffleItem from 'src/components/Admin/RaffleItem';
import { REFETCHING_TIME } from 'src/constants/fetching';
import moderateRafflesService from 'src/services/moderateRafflesService';
import Button from 'src/ui/Button';
import Modal from 'src/ui/Modal';
import Pagination from 'src/ui/Pagination';

import { GIVEAWAY_HEADER_LIST, RAFFLE_HEADER_LIST } from './constant';

import './style.scss';

import { ReactComponent as PlusIcon } from 'src/assets/icons/plus.svg';

const RafflesAdmin = () => {
  const location = useLocation();
  const currentUrl = `${window.location.origin}${location.pathname}${location.search}`;

  const [searchParams, setSearchParams] = useSearchParams();
  const [isCreateRaffle, setCreateRaffle] = useState(false);
  const [isCreateGiveaway, setCreateGiveaway] = useState(false);
  const [isDeleteRaffleModal, setDeleteRaffleModal] = useState<IRaffle | null>(
    null,
  );
  const [successCreated, setSuccessCreated] = useState<IRaffle | null>(null);
  const [editingRaffle, setEditingRaffle] = useState<IRaffle | null>(null);
  const itemsPerPage = 10;

  const page = Number(searchParams.get('page'));
  const tab = searchParams.get('tab');
  const isRaffleTab = tab === 'raffles';
  const isGiveawayTab = tab === 'giveaways';

  const sortColumn = searchParams.get('sortColumn');
  const sortOrder = searchParams.get('sortOrder') || 'asc';

  const {
    data: giveaways = { data: [], total: 0 },
    isLoading: isFetchingGiveaways,
    refetch: giveawaysRefetch,
  } = useQuery({
    queryKey: ['giveaways-admin', { page, sortColumn, sortOrder }],
    queryFn: () =>
      moderateRafflesService.getRaffles({
        type: RaffleType.GIVEAWAY,
        page: page - 1,
        size: itemsPerPage,
        sortColumn,
        sortOrder,
      }),
    placeholderData: (previousData) => previousData,
    refetchInterval: REFETCHING_TIME,
  });

  const {
    data: raffles,
    isLoading: isFetchingRaffles,
    refetch: rafflesRefetch,
  } = useQuery({
    queryKey: ['raffles-admin'],
    queryFn: () =>
      moderateRafflesService.getRaffles({
        type: RaffleType.RAFFLE,
        page: page - 1,
        size: itemsPerPage,
        sortColumn,
        sortOrder,
      }),
    placeholderData: (previousData) => previousData,
    refetchInterval: REFETCHING_TIME,
  });

  const handleCloseRaffle = () => {
    setCreateRaffle(false);
    setEditingRaffle(null);
  };

  const handleCloseGiveaway = () => {
    setCreateGiveaway(false);
    setEditingRaffle(null);
  };

  const handleChangePage = (value: number) => {
    setSearchParams((prev) => {
      prev.set('page', value.toString());
      return prev;
    });
  };

  const handleChangeTab = (value: string) => {
    setSearchParams((prev) => {
      prev.set('tab', value);
      prev.set('page', '1');
      return prev;
    });
  };

  useEffect(() => {
    if (!searchParams.has('tab')) {
      setSearchParams((prev) => {
        prev.set('tab', 'giveaways');
        return prev;
      });
    }

    if (!searchParams.has('page')) {
      setSearchParams((prev) => {
        prev.set('page', '1');
        return prev;
      });
    }
  }, []);

  useEffect(() => {
    giveawaysRefetch();
    rafflesRefetch();
  }, [page, sortColumn, sortOrder]);

  return (
    <>
      <div className="raffles">
        <div className="raffles__row">
          <Button
            onClick={() => handleChangeTab('giveaways')}
            isOutlinedWhite={isRaffleTab}
          >
            Giveaways
          </Button>
          <Button
            onClick={() => handleChangeTab('raffles')}
            isOutlinedWhite={isGiveawayTab}
          >
            Raffles
          </Button>
        </div>

        {isGiveawayTab && (
          <div className="">
            <div className="raffles__head">
              <h4 className="raffles__heading">
                Active Giveaways <span>({giveaways?.total})</span>
              </h4>
              <Button
                className="iconBtn"
                borderRadius="6px"
                onClick={() => setCreateGiveaway(true)}
              >
                <PlusIcon /> Add Giveaway
              </Button>
            </div>

            <div className="raffles__table">
              <div className="Btcs-table">
                <TableHeader columns={GIVEAWAY_HEADER_LIST} />

                {isFetchingGiveaways && <div>Loading...</div>}

                <div className="raffles__list">
                  {giveaways &&
                    !isFetchingGiveaways &&
                    giveaways.data.map((item) => (
                      <Link
                        className="raffles__item"
                        to={`/admin/raffles/${item.id}`}
                        state={{
                          from: currentUrl,
                        }}
                        key={item.id}
                      >
                        <RaffleItem
                          key={item.id}
                          item={item}
                          onEdit={() => {
                            setEditingRaffle(item);
                            setCreateGiveaway(true);
                          }}
                          onDelete={() => setDeleteRaffleModal(item)}
                        />
                      </Link>
                    ))}
                </div>
              </div>

              <div>
                {giveaways && giveaways.total > itemsPerPage && (
                  <Pagination
                    count={Math.ceil(giveaways.total / itemsPerPage)}
                    itemsPerPage={itemsPerPage}
                    page={page}
                    onChangePage={(e, value) => handleChangePage(value)}
                  />
                )}
              </div>
            </div>

            <GiveawayModal
              open={isCreateGiveaway}
              key={editingRaffle ? editingRaffle.id : 'no-id-giveaway'}
              onClose={handleCloseGiveaway}
              onSuccessCreated={(raffle) => setSuccessCreated(raffle)}
              editingItem={editingRaffle}
            />
          </div>
        )}

        {isRaffleTab && (
          <div className="">
            <div className="raffles__head">
              <h4 className="raffles__heading">
                Active Raffles <span>({raffles?.total})</span>
              </h4>
              <Button
                className="iconBtn"
                borderRadius="6px"
                onClick={() => setCreateRaffle(true)}
              >
                <PlusIcon /> Add Raffle
              </Button>
            </div>

            <div className="raffles__table">
              <div className="Btcs-table">
                <TableHeader columns={RAFFLE_HEADER_LIST} />

                {isFetchingRaffles && <div>Loading...</div>}

                <div className="raffles__list">
                  {raffles &&
                    !isFetchingRaffles &&
                    raffles.data.map((item) => (
                      <Link
                        className="raffles__item"
                        state={{
                          from: currentUrl,
                        }}
                        to={`/admin/raffles/${item.id}`}
                        key={item.id}
                      >
                        <RaffleItem
                          key={item.id}
                          item={item}
                          getTypeOption={(item) => item.joinRequirementsType}
                          onEdit={() => {
                            setEditingRaffle(item);
                            setCreateRaffle(true);
                          }}
                          onDelete={() => setDeleteRaffleModal(item)}
                        />
                      </Link>
                    ))}
                </div>
              </div>
              <div>
                {raffles && raffles.total > itemsPerPage && (
                  <Pagination
                    count={Math.ceil(raffles.total / itemsPerPage)}
                    itemsPerPage={itemsPerPage}
                    page={page}
                    onChangePage={(e, value) => handleChangePage(value)}
                  />
                )}
              </div>
            </div>

            <RaffleModal
              open={isCreateRaffle}
              key={editingRaffle ? editingRaffle.id : 'no-id-raffle'}
              onClose={handleCloseRaffle}
              onSuccessCreated={(raffle) => setSuccessCreated(raffle)}
              editingRaffle={editingRaffle}
            />
          </div>
        )}
      </div>

      <Modal
        maxWidth="sm"
        open={!!successCreated}
        handleClose={() => setSuccessCreated(null)}
      >
        <CreatedSuccessModal
          //@ts-ignore
          itemId={successCreated?.id || ''}
          type={successCreated?.type || ''}
        />
      </Modal>

      <Modal
        maxWidth="xs"
        open={!!isDeleteRaffleModal}
        handleClose={() => setDeleteRaffleModal(null)}
      >
        <DeleteRaffleModal
          //@ts-ignore
          onDelete={
            isDeleteRaffleModal?.type === RaffleType.RAFFLE
              ? rafflesRefetch
              : giveawaysRefetch
          }
          item={isDeleteRaffleModal}
          onClose={() => setDeleteRaffleModal(null)}
        />
      </Modal>
    </>
  );
};

export default RafflesAdmin;
