import React from 'react';
import { Link } from 'react-router';
import { Link as LinkScroll } from 'react-scroll';

import { ISponsor, ISponsorDefinition } from 'src/@types/leaderboard';
import { Login } from 'src/components/Header';
import { getFirstWord, getLastAndNextReward } from 'src/components/vip/utils';
import useModalManager from 'src/hooks/useModalManager';
import { PlayerDetails } from 'src/types';
import Button from 'src/ui/Button';

import 'src/assets/CSS/VIP.scss';

const CurrentTier = ({
  user,
  sponsorDefinitions,
  currentSponsor,
}: {
  user: PlayerDetails | undefined;
  sponsorDefinitions: ISponsorDefinition[];
  currentSponsor: ISponsor | undefined | null;
}) => {
  const { openModal } = useModalManager();

  if (!user) {
    const { url } = Login();

    return (
      <div className="Current-Tier">
        <div className="Current-Tier-Info">
          <div className="Current-Tier-Image">
            <img src="https://shuffle.com/images/vip/diamond.svg" />
          </div>
          <div className="Current-Tier-Content">
            <div className="Current-Tier-Title">
              <h4>Sign In</h4>
            </div>
            <div className="Current-Tier-Description">
              <p>
                Please <b>Sign In</b> to get started. To learn more about
                rewards scroll down.
              </p>
            </div>
          </div>
        </div>
        <div className="Current-Tier-Claim">
          <Link to={url}>
            <Button borderRadius="6px">Login</Button>
          </Link>
        </div>
      </div>
    );
  }

  const wageredAmount = currentSponsor?.name
    ? (user.shuffleWagered?.[currentSponsor.name] ?? 0)
    : 0;

  //TODO: OLD CODE, NEED TO BE REMOVED
  // if (wageredAmount == -1) {
  //   return (
  //     <div className='Current-Tier'>
  //       <div className='Current-Tier-Info'>
  //         <div className='Current-Tier-Image'>
  //           <img src='https://shuffle.com/images/vip/diamond.svg' />
  //         </div>
  //         <div className='Current-Tier-Content'>
  //           <div className='Current-Tier-Title'>
  //             <h4>Connect Shuffle</h4>
  //           </div>
  //           <div className='Current-Tier-Description'>
  //             <p>
  //               You must <b>verify your Shuffle account</b> to get started. You
  //               can configure this in account settings.
  //             </p>
  //           </div>
  //         </div>
  //       </div>

  //       <div className='Current-Tier-Claim'>
  //         <button onClick={() => openModal('settings')}>Verify</button>
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <div className="Current-Tier">
      <div className="Current-Tier-Info">
        <div className="Current-Tier-Image">
          <img
            src={
              getLastAndNextReward(wageredAmount, sponsorDefinitions)?.last
                ?.image
            }
          />
        </div>
        <div className="Current-Tier-Content">
          <div className="Current-Tier-Title">
            <h4>
              {' '}
              {getFirstWord(
                getLastAndNextReward(wageredAmount, sponsorDefinitions)?.last
                  ?.name,
              )}{' '}
              Tier
            </h4>
          </div>
          <div className="Current-Tier-Description">
            <p>
              Your current tier is{' '}
              <b>
                {getFirstWord(
                  getLastAndNextReward(wageredAmount, sponsorDefinitions)?.last
                    ?.name,
                )}
              </b>
              . This is based on your wagers with us. To learn more about your
              rewards scroll down.
            </p>
          </div>
        </div>
      </div>
      <div className="Current-Tier-Claim">
        <LinkScroll
          activeClass="active"
          to="tiers"
          spy={true}
          smooth={true}
          offset={-20}
          duration={500}
        >
          <Button borderRadius="6px">Claim Rewards</Button>
        </LinkScroll>
      </div>
    </div>
  );
};

export default CurrentTier;
