import React from 'react';
import { useSearchParams } from 'react-router';

import s from './style.module.scss';

import { ReactComponent as SortIcon } from 'src/assets/icons/sort.svg';

interface Column {
  id: number;
  title: string;
  column?: string;
  isSortable?: boolean;
  onClick?: (column?: string) => void;
}

interface TableHeaderProps {
  columns: Column[];
  sortColKey?: string;
  sortColOrder?: string;
  styles?: any;
}

const TableHeader: React.FC<TableHeaderProps> = ({
  columns,
  sortColKey,
  sortColOrder,
  styles,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleSortChange = (column: string) => {
    const columnKey = sortColKey ?? 'sortColumn';
    const orderKey = sortColOrder ?? 'sortOrder';

    const currentSortColumn = searchParams.get(columnKey);
    const currentSortOrder = searchParams.get(orderKey);

    if (currentSortColumn === column) {
      const newSortOrder =
        currentSortOrder === 'asc'
          ? 'desc'
          : currentSortOrder === 'desc'
            ? ''
            : 'asc';
      if (newSortOrder === '') {
        searchParams.delete(columnKey);
        searchParams.delete(orderKey);
      } else {
        searchParams.set(columnKey, column);
        searchParams.set(orderKey, newSortOrder);
      }
    } else {
      searchParams.set(columnKey, column);
      searchParams.set(orderKey, 'asc');
    }

    setSearchParams(searchParams);
  };

  return (
    <ul className={s.header}>
      {columns.map(({ id, title, column, isSortable, onClick }) => (
        <li
          key={id}
          className={s.header__title}
          style={{ cursor: isSortable ? 'pointer' : 'default' }}
          onClick={() => {
            column && isSortable && handleSortChange(column);
            if (onClick) onClick(column);
          }}
        >
          {isSortable && <SortIcon />} {title}
        </li>
      ))}
    </ul>
  );
};

export default TableHeader;
