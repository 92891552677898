import * as Yup from 'yup';

//import { WagerRequirementType } from 'src/@types/raffles';

export const CATEGORIES = [
  {
    id: 0,
    value: 'NONE',
  },
  {
    id: 1,
    value: 'SHUFFLE',
  },
  {
    id: 2,
    value: 'RUNESCAPE',
  },
];

export const CONDITION_TYPES = [
  {
    label: 'Watching points',
    value: 'watchingPoints',
    id: 'BET',
  },
  {
    label: 'Subscription',
    value: 'subscription',
    id: 'SUBSCRIPTION',
  },
  {
    label: 'Wager',
    value: 'wager',
    id: 'WAGER',
  },
];

export const WAGER_REQUIREMENT_TYPES = [
  {
    label: 'Monthly Wager Requirement',
    value: 'monthly',
    id: 'MONTHLY',
  },
  {
    label: 'Overall Total Wager',
    value: 'total',
    id: 'TOTAL',
  },
];

export const INITIAL_VALUES = {
  name: '',
  description: '',
  maxPlayers: 0,
  maxWinners: 0,
  activationDate: '',
  startDate: '',
  endDate: '',
  rewardAmount: '0',
  joinRequirementsType: '' as string,
  betAmount: 0,
  wagerRequirement: 0,
  requiredSponsorId: '',
  wagerRequirementType: '' as string,
};

export const FORM_VALIDATION_SCHEMA = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  description: Yup.string().required('Description is required'),
  maxPlayers: Yup.number().required('Max players is required'),
  maxWinners: Yup.number().required('Max winners is required'),
  activationDate: Yup.date().required('Activation date is required'),
  startDate: Yup.date().required('Start date is required'),
  endDate: Yup.date().required('End date is required'),
  rewardAmount: Yup.string().required('Reward amount is required'),
  joinRequirementsType: Yup.string(),
  betAmount: Yup.number().when(
    'joinRequirementsType',
    (joinRequirementsType, schema) => {
      return joinRequirementsType[0] === 'watchingPoints'
        ? schema.required('Bet amount is required')
        : schema;
    },
  ),
  wagerRequirement: Yup.number().when(
    'joinRequirementsType',
    (joinRequirementsType, schema) => {
      return joinRequirementsType[0] === 'wager'
        ? schema.required('Wager requirement is required')
        : schema;
    },
  ),
  requiredSponsorId: Yup.string().when(
    'joinRequirementsType',
    (joinRequirementsType, schema) => {
      return joinRequirementsType[0] === 'wager'
        ? schema.required('Sponsor is required')
        : schema;
    },
  ),
  wagerRequirementType: Yup.string(),
});
