import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Form, FormikProvider, useFormik } from 'formik';
import { toast } from 'react-toastify';

import { ISponsor } from 'src/@types/leaderboard';
import { ModalProps } from 'src/layouts/AdminLayout';
import sponsorConroller from 'src/services/leaderboard/sponsorConroller';
import Button from 'src/ui/Button';
import Input from 'src/ui/Input';
import Modal from 'src/ui/Modal';

import { FORM_VALIDATION_SCHEMA, INITIAL_VALUES } from './constants';

import s from './style.module.scss';

import LogoImage from 'src/assets/images/btcs-logo.png';

export type GiveawayModalProps = {
  sponsorId: string;
  editingItem?: any | null;
  onSuccessCreated?: (raffle: ISponsor) => void;
} & ModalProps;

interface IFormState {
  wagered: number;
}

const clearFormState = {
  wagered: 1,
};

function AbuseSponsorWagered({
  editingItem,
  sponsorId,
  onSuccessCreated,
  onClose,
  open,
}: GiveawayModalProps) {
  const queryClient = useQueryClient();
  const isEdit = !!editingItem;
  const [error, setError] = useState<string | null>(null);

  const [formState, setFormState] = useState<IFormState>(clearFormState);

  const formikProps = {
    initialValues: INITIAL_VALUES,
    validationSchema: FORM_VALIDATION_SCHEMA,
    onSubmit: (values) => {
      onCreate();
    },
  };

  const formik = useFormik(formikProps);

  const { mutate: addAbuse, isPending: isLoadingAddAbuse } = useMutation({
    mutationFn: (body: any) =>
      sponsorConroller.abuseAmountUser({ id: sponsorId, body }),
    onSuccess: (data) => {
      if (onSuccessCreated) onSuccessCreated(data);
      queryClient.invalidateQueries({
        queryKey: ['sponsor-users', sponsorId],
      });
      handleClose();
      setFormState(clearFormState);
    },
    onError: (error: any) => {
      const errorMessage =
        error?.response?.data?.message || 'Error adding abuse amount';
      setError(errorMessage);
      toast.error(errorMessage);
      console.error('Abuse amount error:', error);
    },
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };

  const handleClose = () => {
    setError(null);
    onClose();
  };

  const formBody = () => {
    const { wagered } = formState;

    const body = {
      username: editingItem.username,
      userId: editingItem.userId,
      abuseCoefficient: Number(wagered),
      modifyWagered: editingItem.modifyWagered,
    };

    return body;
  };

  const onCreate = () => {
    const body = formBody();

    addAbuse(body);
  };

  const onCancel = () => {
    onClose();
    setFormState(clearFormState);
  };

  useEffect(() => {
    if (isEdit) {
      setFormState({
        wagered: editingItem.abuseCoefficient,
      });

      formik.setValues({
        wagered: editingItem.abuseCoefficient,
      });
    }
  }, [editingItem]);

  return (
    <Modal maxWidth="sm" open={open} handleClose={handleClose} isCloseIcon>
      <FormikProvider value={formik}>
        <Form>
          <div className={s.modal__header}>
            <img src={LogoImage} alt="logo" />

            <h2>
              {`Add `}
              Abuse Amount
            </h2>
          </div>

          <div className={s.modal__form}>
            <Input
              label="Abuse amount"
              name="wagered"
              type="number"
              placeholder="Type here"
              min={1}
              step="0.5"
              value={formState.wagered}
              onChange={(e) => {
                formik.setFieldValue(e.target.name, e.target.value);
                handleChange(e);
              }}
              error={formik.errors.wagered}
            />
          </div>

          <div className={s.modal__footer}>
            {error && <div className={s.modal__error}>{error}</div>}

            <div className={s.modal__row}>
              <Button
                onClick={onCancel}
                className={s.modal__btn}
                isOutlinedWhite
              >
                Cancel
              </Button>
              <Button className={s.modal__btn} type="submit">
                {isLoadingAddAbuse ? 'Submitting...' : `Add`}
              </Button>
            </div>
          </div>
        </Form>
      </FormikProvider>
    </Modal>
  );
}

export default AbuseSponsorWagered;
