import React, { useEffect, useState } from 'react';
import { CopyAll } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import Dialog from '@mui/material/Dialog';
import { useQuery } from '@tanstack/react-query';

import useModalManager from 'src/hooks/useModalManager';
import { dialogStyles, ModalProps } from 'src/layouts/AdminLayout';
import playerService from 'src/services/playerService';
import userService from 'src/services/userService';
import Button from 'src/ui/Button';
import { formatSecondsToTime } from 'src/utils/time';

import { PlayerDetails, PlayerSettingsApi } from '../../types';
import { axiosGet, axiosPost } from '../../utils/httpClient';
import SuccsessUpdateProfileModal from './SuccsessUpdateProfileModal';

import 'src/assets/CSS/Settings.scss';
import s from './style.module.scss';

type ConnectModalProps = {} & ModalProps;

export const ConnectKickModal = (props: ConnectModalProps) => {
  const { onClose, open } = props;
  const [copied, setCopied] = useState(false);

  const {
    data: kickVerify,
    isFetching: isFetchingKickVerify,
    isFetched: isFetchedKickVerefication,
  } = useQuery({
    queryKey: ['kick-verification'],
    queryFn: playerService.getKickVerify,
    enabled: open,
  });

  const handleClose = () => {
    onClose();
  };

  const onCopyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy text:', err);
    }
  };

  useEffect(() => {
    if (kickVerify?.verified) {
      handleClose();
    }
  });

  if (isFetchingKickVerify) {
    return (
      <Dialog
        onClose={handleClose}
        open={open}
        PaperProps={{ style: dialogStyles }}
      >
        <div className="ConnectModal">
          <h2>Loading...</h2>
        </div>
      </Dialog>
    );
  }

  if (!kickVerify && isFetchedKickVerefication) {
    return (
      <Dialog
        onClose={handleClose}
        open={open}
        PaperProps={{ style: dialogStyles }}
      >
        <div className="ConnectModal">
          <h2>Error loading Kick verification</h2>
        </div>
      </Dialog>
    );
  }

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      PaperProps={{ style: dialogStyles }}
    >
      <div className="ConnectModal">
        <h2>
          <img src="/profile/kick.svg" />
          Connect Kick Account
        </h2>
        <br />
        <br />

        <span>Your Verification Code:</span>
        <br />
        <div className={s.copy}>
          <input type="text" defaultValue={kickVerify?.code} disabled={true} />
          {copied ? (
            <div>Copied!</div>
          ) : (
            <Button
              onClick={() => onCopyToClipboard(`!verify ${kickVerify?.code}`)}
              className={s.copy__icon}
              isClear
            >
              <CopyAll />
            </Button>
          )}
        </div>

        <ol>
          <li>
            Go to our verification{' '}
            <a href="https://kick.com/btcs" target="_action">
              <span className="Highlight">Kick Channel</span>
            </a>
          </li>
          <li>
            Type in the following command:{' '}
            <span className="Highlight">!verify {kickVerify?.code}</span>
          </li>
        </ol>
        <span>Your Verification Code becomes invalid in 5 minutes.</span>
        <br />
        <br />
        <br />
        <br />
        <Button borderRadius="6px" onClick={handleClose}>
          Close
        </Button>
      </div>
    </Dialog>
  );
};

const redirectUri = process.env.REACT_APP_TWITCH_REDIRECT_URI;

export type SettingsModalProps = {
  details?: PlayerDetails;
} & ModalProps;

const getProfileSettings = async (): Promise<PlayerSettingsApi> =>
  axiosGet(`/player/settings`);
const setProfileSettings = async (
  request: PlayerSettingsApi,
): Promise<PlayerSettingsApi> => axiosPost(`/player/settings`, request);

export default function SettingsModal(props: SettingsModalProps) {
  const { data } = useQuery({
    queryKey: ['user-settings'],
    queryFn: userService.getUserSettings,
    // retry: 0,
  });

  const { isModalOpen, openModal, closeModal } = useModalManager();

  const { onClose, open, details } = props;

  const [currentTab, setCurrentTab] = useState<number>(0);

  const [profile, setProfile] = useState<PlayerSettingsApi | undefined>(
    undefined,
  );

  /** General Settings */
  const [steamTradeUrl, setSteamTradeUrl] = useState<string | undefined>(
    undefined,
  );
  const [ethAddress, setEthAddress] = useState<string | undefined>(undefined);
  const [btcAddress, setBtcAddress] = useState<string | undefined>(undefined);
  const [shuffleUsername, setShuffleUsername] = useState<string | undefined>(
    undefined,
  );
  const [casesGgUsername, setCasesGgUsername] = useState<string | undefined>(
    undefined,
  );
  const [csgoBigUserId, setCsgoBigUserId] = useState<string | undefined>(
    undefined,
  );
  const [empireDropUserId, setEmpireDropUserId] = useState<string | undefined>(
    undefined,
  );
  const [updatingDetails, setUpdatingDetails] = useState<boolean>(false);

  /** Connected Accounts */
  const [discordConnected, setDiscordConnected] = useState<boolean>(false);
  const [kickConnected, setKickConnected] = useState<boolean>(false);
  const [openKick, setOpenKick] = React.useState(false);

  /** Validations */
  const [ethValidity, setEthValidity] = useState<number>(0);
  const [btcValidity, setBtcValidity] = useState<number>(0);
  const [shuffleValidity, setShuffleValidity] = useState<number>(0);
  const [casesGgValidity, setCasesGgValidity] = useState<number>(0);

  const [csgoBigValidity, setCsgoBigValidity] = useState<number>(0);
  const [empireDropValidity, setEmpireDropValidity] = useState<number>(0);

  const dialogStyles = {
    backgroundColor: '#1a1d23',
    color: 'white',
    padding: '25px',
    boxShadow: 'none',
    width: '600px',
    overflow: 'hidden',
  };

  const handleClose = () => {
    onClose();
  };

  const validateEth = () => {
    const regex = new RegExp(/^(0x)?[0-9a-fA-F]{40}$/);
    let valide = false;

    if (ethAddress == null) {
      setEthValidity(2);
      return false;
    }
    if (regex.test(ethAddress!) == true) {
      setEthValidity(1);
      valide = true;
    } else {
      setEthValidity(2);
      valide = false;
    }

    return valide;
  };

  const validateBtc = () => {
    if (!btcAddress) setBtcValidity(0);

    const regex = new RegExp(
      /^(bc1[a-z0-9]{8,90}|[13][a-km-zA-HJ-NP-Z1-9]{25,34}|tb1[a-z0-9]{8,90})$/,
    );

    let valide = false;

    if (btcAddress == null) {
      setBtcValidity(2);
      valide = false;
    }
    if (regex.test(btcAddress!) == true) {
      setBtcValidity(1);
      valide = true;
    } else {
      setBtcValidity(2);
      valide = false;
    }

    return valide;
  };

  const validateShuffle = () => {
    let valide = false;

    if (!shuffleUsername || shuffleUsername.length < 3) {
      setShuffleValidity(2);
      valide = false;
      return;
    } else {
      valide = true;
      setShuffleValidity(1);
    }

    return valide;
  };

  const validateCasesGg = () => {
    let valide = false;

    if (!casesGgUsername || casesGgUsername.length < 3) {
      setCasesGgValidity(2);
      valide = false;
      return false;
    } else {
      valide = true;
      setCasesGgValidity(1);
    }

    return valide;
  };

  const validateEmpireDrop = () => {
    let valide = false;

    if (!empireDropUserId || empireDropUserId.length < 3) {
      setEmpireDropValidity(2);
      valide = false;
      return false;
    } else {
      valide = true;
      setEmpireDropValidity(1);
    }

    return valide;
  };

  const validateCsgoBig = () => {
    let valide = false;

    if (!csgoBigUserId || csgoBigUserId.length < 3) {
      setCsgoBigValidity(2);
      valide = false;
      return false;
    } else {
      valide = true;
      setCsgoBigValidity(1);
    }

    return valide;
  };

  const onUpdateProfile = () => {
    if (updatingDetails) {
      return;
    }

    const newSteamTradeUrl = steamTradeUrl ?? '';
    const newEthAddress = ethAddress ?? '';
    const newBtcAddress = btcAddress ?? '';
    const newShuffleUsername = shuffleUsername ?? '';
    const newCasesGgUsername = casesGgUsername ?? '';
    const newEmpireDropUserId = empireDropUserId ?? '';
    const newCsgoBigUserId = csgoBigUserId ?? '';

    const isBtcValid = newBtcAddress ? validateBtc() : true;
    const isEthValid = newEthAddress ? validateEth() : true;
    const isShuffleValid = validateShuffle();
    const isCasesGgValid = validateCasesGg();
    const isEmpireDropValid = validateEmpireDrop();
    const isCsgoBigValid = validateCsgoBig();

    if (!newShuffleUsername || !isShuffleValid) {
      return;
    }

    if ((newBtcAddress && !isBtcValid) || (newEthAddress && !isEthValid)) {
      return;
    }

    // if (!newCsgoBigUserId || !isCsgoBigValid) {
    //   return;
    // }

    // if (!newEmpireDropUserId || !isEmpireDropValid) {
    //   return;
    // }

    const body: PlayerSettingsApi = {
      steamTradeUrl: newSteamTradeUrl,
      shuffleUsername: newShuffleUsername,
      casesGgUsername: newCasesGgUsername || '',
      csgoBigUserId: newCsgoBigUserId,
      empireDropUserId: newEmpireDropUserId,
    };

    if (newBtcAddress && isBtcValid) {
      body.btcWalletAddress = newBtcAddress;
    }

    if (newEthAddress && isEthValid) {
      body.ethWalletAddress = newEthAddress;
    }

    setUpdatingDetails(true);
    setProfileSettings(body)
      .then((response) => {
        setProfile(response);
        openModal('success-update-profile');
      })
      .finally(() => {
        setUpdatingDetails(false);
      });
  };

  const getCurrentTabContents = () => {
    switch (currentTab) {
      default:
        return <></>;
      case 0: {
        const joinDate = 'Joined: ' + (profile?.joinDate ?? '-');
        const email = 'Email: ' + (profile?.emailAddress ?? 'N/A');
        const discord = 'Discord: ' + (profile?.discordName ?? 'N/A');
        const kick = 'Kick: ' + (profile?.kickName ?? 'N/A');

        const ethSelector = ethValidity === 2 ? 'Invalid-field' : '';
        const btcSelector = btcValidity === 2 ? 'Invalid-field' : '';
        const shuffleSelector = shuffleValidity === 2 ? 'Invalid-field' : '';
        const casesGgSelector = casesGgValidity === 2 ? 'Invalid-field' : '';

        const csgoBigSelector = csgoBigValidity === 2 ? 'Invalid-field' : '';
        const empireDropSelector =
          empireDropValidity === 2 ? 'Invalid-field' : '';
        return (
          <>
            <SuccsessUpdateProfileModal
              open={isModalOpen('success-update-profile')}
              onClose={() => closeModal('success-update-profile')}
            />
            <div className="Profile-Username">
              <h3>
                {details?.username} <span>({profile?.id})</span>
              </h3>
            </div>
            <div className="Profile-Details">
              <div className="Profile-image">
                <img src={details?.profilePicture} />
              </div>
              <div className="Profile-values">
                <ul>
                  <li>
                    <span>{joinDate}</span>
                  </li>
                  <li>
                    <span>{email}</span>
                  </li>
                  <li>
                    <span>{discord}</span>
                  </li>
                  <li>
                    <span>{kick}</span>
                  </li>
                </ul>
              </div>
              <div className="Profile-points">
                <div className="Points-container">
                  <span>Points</span>
                  <img src="https://btcs.gg/currency.svg" />
                  <span>{profile?.currentPoints!.toLocaleString('en-US')}</span>
                </div>
              </div>
            </div>

            <div className="Profile-raffles-and-points">
              <div className="Profile-settings-metric">
                <div className="Icon">
                  <img
                    style={{ marginTop: '10px' }}
                    src="https://btcs.gg/vip-img/april-raffle-ticket.png"
                  />
                </div>
                <div className="Value">
                  <span>Raffles Won</span>
                  <span>{profile?.rafflesWon ?? '-'}</span>
                </div>
              </div>

              <div className="Profile-settings-metric">
                <div className="Icon">
                  <img src="https://btcs.gg/currency.svg" />
                </div>

                <div className="Value">
                  <span>Watch Time</span>
                  <span>{formatSecondsToTime(profile?.watchTime ?? 0)}</span>
                </div>
              </div>
            </div>

            <h3 className="Field-title">Shuffle Username</h3>

            <div className="Form-input-field">
              <div className={'Form-input ' + shuffleSelector}>
                <div className="Form-icon">
                  <img src="/profile/shuffle.png" />
                </div>
                <input
                  type="text"
                  placeholder="Shuffle Username..."
                  value={shuffleUsername}
                  onChange={(e) => {
                    setShuffleUsername(e.target.value);
                  }}
                />
              </div>
            </div>

            <h3 className="Field-title">CSGOBig User ID</h3>

            <div className="Form-input-field">
              <div className={'Form-input ' + csgoBigSelector}>
                <div className="Form-icon">
                  <img src="/profile/csgobig.png" />
                </div>
                <input
                  type="text"
                  placeholder="CSGOBig User ID..."
                  value={csgoBigUserId}
                  onChange={(e) => {
                    setCsgoBigUserId(e.target.value);
                  }}
                />
              </div>
            </div>

            <h3 className="Field-title">EmpireDrop User ID</h3>

            <div className="Form-input-field">
              <div className={'Form-input ' + empireDropSelector}>
                <div className="Form-icon">
                  <img src="/profile/empiredrop.png" />
                </div>
                <input
                  type="text"
                  placeholder="EmpireDrop User ID..."
                  value={empireDropUserId}
                  onChange={(e) => {
                    setEmpireDropUserId(e.target.value);
                  }}
                />
              </div>
            </div>

            {/* <h3 className="Field-title">Cases.gg Username</h3>

            <div className="Form-input-field">
              <div className={'Form-input ' + casesGgSelector}>
                <div className="Form-icon">
                  <img src="/profile/casesgg.png" />
                </div>
                <input
                  type="text"
                  placeholder="Cases.gg Username..."
                  value={casesGgUsername}
                  onChange={(e) => {
                    setCasesGgUsername(e.target.value);
                  }}
                />
              </div>
            </div> */}
            <div className="Form-submission">
              <Button borderRadius="6px" onClick={() => onUpdateProfile()}>
                {updatingDetails ? 'Updating...' : 'Update Profile'}
              </Button>
            </div>

            <br />
          </>
        );
      }
      case 2: {
        return (
          <>
            <div
              className="Account-settings-container"
              style={{ width: 'calc(100% - 20px)' }}
            >
              <div className="Account-integrations">
                <div className="Integrations-list">
                  {discordConnected ? (
                    <div className="Integration-option">
                      <div className="Integration Connected">
                        <img src="/profile/discord.svg" />
                        <div className="Title">
                          <span>Discord</span>
                        </div>
                        <CheckIcon />
                      </div>
                      <div className="Integration-heading">
                        <span>Connected</span>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="Integration-option"
                      onClick={() => {
                        localStorage.setItem(
                          'discord_integration',
                          `${new Date().getTime() + 60_000}`,
                        );

                        const encodedRedirectUri =
                          process.env.REACT_APP_TWITCH_REDIRECT_URI;
                        window.location.href = `https://discord.com/oauth2/authorize?client_id=1231647510033072219&redirect_uri=${encodedRedirectUri}&response_type=code&scope=identify`;
                      }}
                    >
                      <div className="Integration">
                        <img src="/profile/discord.svg" />
                        <div className="Title">
                          <span>Discord</span>
                        </div>
                      </div>
                      <div className="Integration-heading">
                        <span>Not Connected</span>
                      </div>
                    </div>
                  )}
                  {kickConnected ? (
                    <div className="Integration-option">
                      <div className="Integration Connected">
                        <img src="/profile/kick.svg" />
                        <div className="Title">
                          <span>Kick</span>
                          <CheckIcon />
                        </div>
                      </div>
                      <div className="Integration-heading">
                        <span>Connected</span>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="Integration-option"
                      onClick={() => setOpenKick(true)}
                    >
                      <div className="Integration">
                        <img src="/profile/kick.svg" />
                        <div className="Title">
                          <span>Kick</span>
                        </div>
                      </div>
                      <div className="Integration-heading">
                        <span>Not Connected</span>
                      </div>
                    </div>
                  )}
                </div>
                <ConnectKickModal
                  key={'integrate-kick'}
                  open={openKick}
                  onClose={() => setOpenKick(false)}
                />
              </div>
            </div>
          </>
        );
      }
    }
  };

  useEffect(() => {
    if (data) {
      setProfile(data);
      setSteamTradeUrl(data.steamTradeUrl);
      setEthAddress(data.ethWalletAddress);
      setBtcAddress(data.btcWalletAddress);
      setShuffleUsername(data.shuffleUsername);
      setCasesGgUsername(data.casesGgUsername);
      setCsgoBigUserId(data.csgoBigUserId);
      setEmpireDropUserId(data.empireDropUserId);
      setKickConnected(data.kickConnected);
      setDiscordConnected(data.discordConnected);
    }
  }, [data]);

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      PaperProps={{ style: dialogStyles }}
    >
      <div className="Btcs-form Settings-Modal">
        <h2>Settings</h2>
        <br />
        <ul className="Tabbing">
          <li
            className={currentTab == 0 ? 'Active' : ''}
            onClick={() => setCurrentTab(0)}
          >
            Main
          </li>
          <li
            className={currentTab == 1 ? 'Active' : ''}
            onClick={() => setCurrentTab(1)}
          >
            Raffles
          </li>
          <li
            className={currentTab == 2 ? 'Active' : ''}
            onClick={() => setCurrentTab(2)}
          >
            Connections
          </li>
        </ul>
        <br />
        {getCurrentTabContents()}
      </div>
    </Dialog>
  );
}
