import React from 'react';

import { TimeToEnd } from 'src/hooks/useTimeToEnd';

import s from './style.module.scss';

const EndCounter = ({ time }: { time: TimeToEnd | null }) => {
  return (
    <div className={s.timer}>
      <p className={s.timer__text}>
        <span>New</span> leaderboard starts in
      </p>

      {time ? (
        <p className={s.timer__text}>
          <span>{time?.days} days</span> {time?.hours}:{time?.minutes}:
          {time?.seconds}
        </p>
      ) : (
        <div className={s.timer__text}>Loading...</div>
      )}
    </div>
  );
};

export default EndCounter;
